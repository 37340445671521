import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Button } from "components/base";
import { Input, Switcher } from "components/Form";
import { Alert } from "components/Toast";
import { validateEmail } from "utils/string";
import { nanoid } from "nanoid";
import { parseError } from "apollo";
import CharlesButton from "components/charles/base";
import { useNavigate } from "react-router-dom";
import { RETAILER_CREATE_AGENT } from "../graphql";

function AgentForm({ hide }) {
  const [user, setUser] = useState({
    email: process.env.NODE_ENV === "development" ? "test_" + nanoid().slice(-4) + "@waboba.com" : "",
    password: nanoid().slice(-16),
    firstName: process.env.NODE_ENV === "development" ? "Test First Name" : "",
    lastName: process.env.NODE_ENV === "development" ? "Test Last Name" : "",
  });
  const navigate = useNavigate();

  const [retailerCreateAgent, { loading }] = useMutation(RETAILER_CREATE_AGENT, {
    variables: {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      password: user.password,
    },
    onCompleted: (res) => {
      Alert("success", "User saved.");
      navigate(`/retailer-store/agents/${res.retailerCreateAgent.user.id}`);
      if (hide) hide();
    },
    refetchQueries: ["FETCH_RETAILER_AGENTS"],
    onError: (error) => {
      Alert("error", parseError(error));
    },
  });

  function randomPassword() {
    setUser((prev) => ({ ...prev, password: nanoid().slice(-16) }));
  }

  function saveHandler() {
    if (user.email === "" || !validateEmail(user.email)) {
      Alert("error", "Invalid email address.");
      return;
    }

    retailerCreateAgent();
  }

  return (
    <div>
      <div>
        <div className="p-4 lg:p-6 bg-white dark:bg-gray-900 rounded-xl">
          <div className="flex items-center">
            <label htmlFor="" className="w-1/4 inline-block">
              Email:
            </label>
            <Input
              type="email"
              validated={user.email === "" || validateEmail(user.email)}
              className="ml-4 flex-1"
              value={user.email}
              onChange={(e) => setUser({ ...user, email: e.target.value })}
            />
          </div>
          <div className="mt-4 flex items-center">
            <label htmlFor="" className="w-1/4 inline-block">
              First Name:
            </label>
            <Input className="ml-4 flex-1" value={user.firstName} onChange={(e) => setUser({ ...user, firstName: e.target.value })} />
          </div>
          <div className="mt-4 flex items-center">
            <label htmlFor="" className="w-1/4 inline-block">
              Last Name:
            </label>
            <Input className="ml-4 flex-1" value={user.lastName} onChange={(e) => setUser({ ...user, lastName: e.target.value })} />
          </div>
        </div>
      </div>

      <div className="mt-6">
        <div className="px-4 py-2 lg:p-6 lg:py-4 bg-white dark:bg-gray-900 rounded-xl ">
          <div className="flex items-center">
            <label htmlFor="" className="w-1/4 inline-block">
              Password
            </label>
            <Input className="ml-4 flex-1" value={user.password} onChange={(e) => setUser({ ...user, password: e.target.value })} />
            <Button title="Generate" className="ml-4" onClick={randomPassword} />
          </div>
        </div>
      </div>

      <div className="mt-6">
        <CharlesButton primary loading={loading} onClick={saveHandler}>
          Save
        </CharlesButton>
      </div>
    </div>
  );
}

export default AgentForm;
