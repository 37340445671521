import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import gql from "graphql-tag";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Page from "components/Page";
import { Button, Tag } from "components/base";
import { Input, Select, Switcher, Text } from "components/Form";
import { useQuery, useMutation } from "@apollo/client";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { Alert } from "components/Toast";
import { useFetchMe } from "hooks/user.js";
import UserForm from "pages/manage/accounts/UserForm";
import { BsTrash } from "react-icons/bs";
import { useModals } from "ModalProvider";
import CharlesButton from "components/charles/base";

const FETCH_CUSTOMERS = gql`
  query FETCH_CUSTOMERS {
    customers {
      id
      name
      user {
        id
        email
      }
    }
    users: allUsers {
      id
      email
    }
    areas: allProductAreas {
      id
      name
    }
  }
`;

const CREATE_CUSTOMER = gql`
  mutation(
    $name: String!
    $userId: ID
    $customerType: String
    $country: String
    $region: String
    $city: String
    $street: String
    $notes: String
    $areas: [ID!]
    $password: String
    $isEmailEnabled: Boolean
  ) {
    createCustomer(
      name: $name
      userId: $userId
      customerType: $customerType
      country: $country
      region: $region
      city: $city
      street: $street
      notes: $notes
      areas: $areas
      password: $password
      isEmailEnabled: $isEmailEnabled
    ) {
      customer {
        id
        name
      }
    }
  }
`;

function CreateCustomer() {
  const [name, setName] = useState("");
  const [userId, setUserId] = useState(0);
  const [customerType, setCustomerType] = useState("");
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [notes, setNotes] = useState("");
  const [areas, setAreas] = useState([]);
  const {
    data: { me },
  } = useFetchMe();

  const { present, hide } = useModals();
  const emptyUser = {
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    isActive: true,
  };
  const [userFormInfos, setUserFormInfos] = useState(null);
  const handleUserFormInfos = (Infos) => {
    setUserFormInfos(Infos);
    setUserId(Infos.id);
  };
  const [isEmailEnabled, setEmailEnabled] = useState(true);

  const navigate = useNavigate();
  const { loading, error, data } = useQuery(FETCH_CUSTOMERS, {
    cachePolicy: "cache-and-network",
  });
  const [createCustomer, createCustomerStatus] = useMutation(CREATE_CUSTOMER, {
    onCompleted: (data) => {
      Alert("success", "Customer is created.");
      if (isEmailEnabled) {
        Alert("success", "Email is sent to customer: " + name);
      }
      navigate(`/customers/${data.createCustomer.customer.id}`);
    },
    onError: (error) => {
      Alert("error", error.message);
    },
    refetchQueries: [{ query: FETCH_CUSTOMERS }],
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const sortedAreas = [...data.areas].sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  return (
    <Page title="Create Customer" backTo="/customers">
      <div className="m-4 lg:m-6">
        <Description />

        <div className="mt-4 space-y-4 card">
          <h5>Customer Info</h5>

          <InputWithLabel labelText="Customer Name" value={name} onChange={(e) => setName(e.target.value)}></InputWithLabel>

          <InputWithLabel labelText="Primary User Account" value={name} onChange={(e) => setName(e.target.value)}>
            <div className="flex items-center">
              {userFormInfos ? (
                <div className="flex items-center">
                  <b className="text-sm">{userFormInfos.email}</b>
                  <BsTrash
                    className="ml-4 cursor-pointer text-red-500"
                    onClick={(_) => {
                      setUserFormInfos(null);
                    }}
                  />
                </div>
              ) : (
                <div className="flex">
                  <Select onChange={(e) => setUserId(e.target.value)} value={userId}>
                    <option value="0" disabled>
                      Select User Account
                    </option>
                    {[...data.users]
                      .sort((a, b) => {
                        if (a.email < b.email) {
                          return -1;
                        }
                        if (a.email > b.email) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((u) => (
                        <option key={u.id} value={u.id}>
                          {u.email}
                        </option>
                      ))}
                  </Select>
                  {me.isSuperuser || me.isStaff ? (
                    <Button
                      className="ml-4"
                      size="sm"
                      title="+ New Account"
                      onClick={() => {
                        present({
                          title: "Create Account",
                          subtitle: "Account to login our systems: wis and biz.",
                          center: true,
                          children: <UserForm initialUser={emptyUser} onClose={hide} onValuesChange={handleUserFormInfos} />,
                        });
                      }}
                    />
                  ) : null}
                </div>
              )}
            </div>
          </InputWithLabel>
          <InputWithLabel labelText="Product Groups" className="flex items-baseline">
            <div className="w-full flex flex-wrap">
              {sortedAreas.map((i) => (
                <Tag
                  selected={areas.includes(i.id)}
                  onClick={() =>
                    setAreas((prev) => {
                      if (prev.includes(i.id)) {
                        return prev.filter((v) => v !== i.id);
                      } else {
                        return [...prev, i.id];
                      }
                    })
                  }
                  className="mr-4 mb-2"
                  key={i.id}
                  title={i.name}
                ></Tag>
              ))}
            </div>
          </InputWithLabel>
          <InputWithLabel labelText="Type">
            <Select onChange={(e) => setCustomerType(e.target.value)} value={customerType}>
              <option value="Retailer">Retailer</option>
              <option value="Distributor">Distributor</option>
              <option value="Sales rep">Sales rep</option>
              <option value="Marketting Specialist">Marketting Specialist</option>
            </Select>
          </InputWithLabel>
          <InputWithLabel labelText="Country">
            <CountryDropdown
              className="text-sm border border-gray-200 dark:border-gray-700 bg-white dark:bg-opacity-0 rounded-lg appearance-none px-4 py-1 focus:border-blue-600"
              value={country}
              onChange={(value) => setCountry(value)}
            />
          </InputWithLabel>
          <InputWithLabel labelText="Region">
            <RegionDropdown
              blankOptionLabel="Select Region"
              className="text-sm border border-gray-200 dark:border-gray-700 bg-white dark:bg-opacity-0 rounded-lg appearance-none px-4 py-1 focus:border-blue-600"
              value={region}
              country={country}
              onChange={(value) => setRegion(value)}
            />
          </InputWithLabel>
          <InputWithLabel labelText="City" value={city} onChange={(e) => setCity(e.target.value)}></InputWithLabel>
          <InputWithLabel labelText="Street" value={street} onChange={(e) => setStreet(e.target.value)}></InputWithLabel>
          <InputWithLabel labelText="Notes" className="flex items-baseline">
            <div className="flex flex-1">
              <Text className="flex-1" value={notes} onChange={(e) => setNotes(e.target.value)} />
            </div>
          </InputWithLabel>
          <InputWithLabel labelText="Send email">
            <Switcher isOn={isEmailEnabled} onChange={() => setEmailEnabled(!isEmailEnabled)} />
          </InputWithLabel>

          <hr />

          <div className="flex mt-8">
            <CharlesButton
              primary
              loading={createCustomerStatus.loading}
              onClick={() => {
                createCustomer({
                  variables: {
                    name,
                    userId: userId === 0 ? null : userId,
                    customerType,
                    country,
                    region,
                    city,
                    street,
                    notes,
                    areas,
                    password: userFormInfos ? userFormInfos.password : null,
                    isEmailEnabled: isEmailEnabled,
                  },
                });
              }}
            >
              Create
            </CharlesButton>
          </div>
        </div>
      </div>
    </Page>
  );
}

const InputWithLabel = ({ className = "flex w-full items-center relative", labelText = "label", children, ...rest }) => (
  <div className={className}>
    <label htmlFor={labelText} className="w-1/5">
      {labelText}:
    </label>
    <div className="w-4/5">
      <div className="ml-4">{children ? children : <Input {...rest} className="border border-gray-200 dark:border-gray-700"></Input>}</div>
    </div>
  </div>
);

const Description = () => (
  <div className="text-gray-600">
    <p>
      In most case a customer is a company. It representes a <b>retailer</b> or <b>disctributor</b>.
    </p>
    <br />
    <p>
      Each customer has a primary user account. People has this account could login biz.waboba.com, and he/she could check all the related shipments/orders
      created by us.
    </p>
    <p>
      Customer also has subaccounts, just like a company has staffs. Sub accounts could also login biz.waboba.com and check the shipmets/orders under the same
      company.
    </p>
    <p>One primary user account might has many companies. If he/she does, he/she could check all the shipments/orders releated to all his/her companies.</p>
    <p>Same as sub accounts, if people's account is under many companies, he/she will be able to see all the data under those companies.</p>
    <br />
    <p>
      For example, Dale is has a Waboba account(dale@waboba.com) and he has 3 companies: A, B and C. For sure Dale will be able to access all the data related
      to A, B and C.
    </p>
    <p>
      Say that company A has sub accounts: Charlie(charlie@waboba.com), Sharon(sharon@waboba.com), while Charlie also is a sub account of company B. So Charlie
      will be able to access A and B. Sharon is a sub account of company C. So Sharon will be able to access company A and C.
    </p>
    <p>
      It could be more complex like Charlie might be also has his own company D, Sharon has her own companies E, F, G. All these companies has its own sub
      accounts. But they all has the same access rules just like Dale's companies.
    </p>
    <br />
    <p>
      <b>Make sure this is clear when you setup customers.</b>
    </p>
  </div>
);

export default CreateCustomer;
