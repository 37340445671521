import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import Image from "components/Image";
import SearchBar from "components/SearchBar";
import { searchByProp } from "utils/search";
import CostChart from "./CostChart";
import { DatePicker } from "components/Form";
import { Button } from "components/base";
import { AppContext } from "App";
import { Alert } from "components/Toast";
import { formatDate } from "react-day-picker/moment";
import { useModals } from "ModalProvider";
import ProductDetail from "./ProductDetail";
import { byName } from "utils/sort";
import { SAVE_PRODUCT } from "pages/manage/products/All/graphql";

const FETCH_COST_TRENDS = gql`
  query FETCH_COST_TRENDS {
    allProductlines(activeForWis: true) {
      id
      name
      salesPriceNotes
    }
    allProducts(productType: "normal", isActive: true) {
      id
      odooId
      name
      number
      defaultQty
      productLine {
        id
      }
      costData
      isActive
    }
    priceStartFromDate
  }
`;

const TrendsContext = createContext({});

const Trends = () => {
  const { loading, error, data } = useQuery(FETCH_COST_TRENDS);
  const [searchText, setSearchText] = useState(process.env.NODE_ENV === "development" ? "302C01_A" : "");
  const [startDate, setStartDate] = useState(
    localStorage.getItem("costTrends:startDate") ? new Date(localStorage.getItem("costTrends:startDate")) : new Date().addDays(-30 * 6)
  );
  const [selectedProductLine, setSelectedProductLine] = useState(null);
  const modal = useModals();

  useEffect(() => {
    if (startDate) localStorage.setItem("costTrends:startDate", formatDate(startDate, "YYYY-MM-DD"));
  }, [startDate]);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const getlineProducts = (lineId) => data.allProducts.filter((i) => i.isActive && i.productLine && i.productLine.id === lineId && i.odooId !== null);

  const difference = (a, b) => {
    if (a > 0 && b > 0) {
      return ((b - a) * 100) / a;
    }
    if (a === 0 && b === 0) return 0;
    if (a === 0) return 999.99;
    return 0;
  };

  const productLines = data.allProductlines
    .map((line) => {
      const lineProducts = getlineProducts(line.id);
      const stringForSearch = lineProducts.map((p) => `${p.name} ${p.number}`).join(" ");
      return { ...line, stringForSearch, products: lineProducts };
    })
    .filter((i) => i.products.length > 0)
    .filter((i) => searchByProp(i, ["name", "stringForSearch"], searchText))
    .map((line) => ({
      ...line,
      products: line.products.map((variant) => {
        let chartData = [];
        let simpleData = [];
        let startDateValue = 0;
        let endDateValue = 0;
        const data = variant.costData ? JSON.parse(variant.costData) : null;
        if (data) {
          for (const [key, value] of Object.entries(data)) {
            if (startDate && new Date(key) >= startDate) {
              chartData.push({ date: key, value });
              if (key.match(/-01$/g)) {
                simpleData.push({ date: key, value });
              }
            }
          }
          if (chartData.length > 0) {
            startDateValue = chartData[0].value;
            endDateValue = chartData[chartData.length - 1].value;
          }
        }

        return {
          ...variant,
          chartData,
          simpleData,
          startDateValue,
          endDateValue,
          difference: difference(startDateValue, endDateValue).toFixed(2),
        };
      }),
    }))
    .sort(byName);

  function showProductDetail(product) {
    modal.present({
      title: `[${product.number}] ${product.name}`,
      maxWidth: "max-w-6xl",
      children: <ProductDetail startDate={startDate} product={product} />,
    });
  }

  return (
    <TrendsContext.Provider value={{ startDate, selectedProductLine, setSelectedProductLine }}>
      <div className="">
        <div className="p-6 flex items-center space-x-8">
          <div className="flex items-center">
            <label className="pr-3">Start Date: </label>
            <DatePicker
              value={startDate}
              onDayChange={(selectedDay) => {
                if (selectedDay) setStartDate(selectedDay);
              }}
            />
          </div>

          <div className="flex-1">
            <SearchBar autoFocus placeholder="Search by name or item number" value={searchText} onChange={setSearchText} />
          </div>
        </div>

        <div className="flex-1 overflow-auto">
          <div className="h-full p-6 pt-0 overflow-auto">
            {productLines.map((line) => (
              <ProductLineDetail key={line.id} line={line} products={line.products} showProductDetail={showProductDetail} />
            ))}
          </div>
        </div>
      </div>
    </TrendsContext.Provider>
  );
};

const ProductLineDetail = ({ line, products, showProductDetail }) => {
  const { selectedProductLine, setSelectedProductLine } = useContext(TrendsContext);

  return (
    <div key={line.id} className="mb-8 bg-white dark:bg-gray-800 border border-gray-100 dark:border-gray-800 rounded-xl p-4 shadow-sm">
      <div className="flex text-right items-end space-x-8 px-4 py-1 font-bold ">
        <div className="w-1/2 ">
          <div>
            <div
              className={`
                     my-1 flex items-end cursor-pointer
                     ${selectedProductLine && selectedProductLine.id === line.id ? "text-blue-600" : "hover:text-blue-600"}
                     `}
              onClick={() => setSelectedProductLine((prev) => (prev && prev.id === line.id ? null : line))}
            >
              <h2 className="pr-4">{line.name}</h2>
              <Image src={line.mainImage} size="w-8 h-8" />
            </div>
            {line.salesPriceNotes ? <div className="text-left text-red-600">{line.salesPriceNotes}</div> : null}
          </div>
        </div>
        <div className="uppercase w-32 flex-grow"></div>
        <div className="uppercase w-32">Latest Cost</div>
      </div>

      <div>
        {products.map((variant) => (
          <ProductVariant key={variant.id} variant={variant} showProductDetail={() => showProductDetail(variant)} />
        ))}
      </div>
    </div>
  );
};

const emailsHasEditPermissions = ["charles@waboba.com", "stella@waboba.com"];

const ProductVariant = ({ variant, showProductDetail }) => {
  const { user } = useContext(AppContext);
  const { startDate } = useContext(TrendsContext);
  const [saveProduct, saveProductRes] = useMutation(SAVE_PRODUCT, {
    onError(error) {
      Alert("error", error.message);
    },
  });

  return useMemo(
    () => (
      <div className="border-t border-gray-100 dark:border-gray-700 py-1 px-4">
        <div className="flex space-x-8 items-center">
          <div className="w-1/2 flex-grow flex items-center space-x-3">
            <div>
              [ {variant.number} ] {variant.name}
            </div>

            <Button title="more" onClick={showProductDetail} />

            {emailsHasEditPermissions.includes(user.email) ? (
              <Button
                title="hide"
                color="red"
                disabled={saveProductRes.loading}
                loading={saveProductRes.loading}
                onClick={() => {
                  if (window.confirm("Are you sure to deactivate this product?"))
                    saveProduct({
                      variables: {
                        id: variant.id,
                        simpleFields: {
                          isActive: false,
                        },
                      },
                    });
                }}
              />
            ) : null}
          </div>

          <div className="uppercase w-32 flex-grow flex justify-end">
            <CostChart data={variant.simpleData} up={variant.endDateValue >= variant.startDateValue} />
          </div>

          <div className="w-32 text-right flex flex-col items-end space-y-1 py-1">
            <div className="whitespace-nowrap">
              {variant.startDateValue} → ${variant.endDateValue}
            </div>
            {variant.startDateValue > 0 ? (
              <div
                className={`px-4 rounded-full text-white whitespace-nowrap text-xs ${
                  variant.endDateValue > variant.startDateValue
                    ? "bg-red-500"
                    : variant.endDateValue === variant.startDateValue
                    ? "bg-gray-500"
                    : "bg-green-500"
                }`}
              >
                {variant.endDateValue > variant.startDateValue ? "+ " : ""}
                {variant.difference}%
              </div>
            ) : null}
          </div>
        </div>
      </div>
    ),
    [startDate]
  );
};

export default Trends;
