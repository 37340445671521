import { useContext } from "react";
import { ProductContext } from "./ProductForm";
import { InlineSpinner } from "components/Spinner";
import CharlesButton from "components/charles/base";
import { Switcher } from "components/Form";
import Syncer from "../odoo/Syncer";

const BottomBar = () => {
  const {
    id,
    simpleFields,
    duplicate,
    onSave,
    onSaveAndClose,
    variables,
    saveProductRes,
    saveProduct,
    validInput,
    odooSyncerModal,
    setSendNotification,
    sendNotification,
    duplicateHandler,
  } = useContext(ProductContext);

  return (
    <div className="sticky bottom-0 py-4 backdrop-filter backdrop-blur-lg bg-gray-100 dark:bg-gray-800 bg-opacity-50 -mx-10 px-10 border-t border-gray-200 dark:border-gray-700">
      <div className="flex space-x-8 items-center">
        {saveProductRes.loading ? (
          <div className="relative flex items-center space-x-2">
            <InlineSpinner size={18} text={null} />
            <b className="opacity-70">Saving...</b>
          </div>
        ) : (
          <>
            <CharlesButton
              loading={saveProductRes.loading}
              onClick={() => {
                if (validInput())
                  saveProduct({ variables }).then((res) => {
                    if (onSave) onSave(res.data.saveProduct.product);
                  });
              }}
            >
              Save
            </CharlesButton>
            <CharlesButton
              loading={saveProductRes.loading}
              onClick={() => {
                if (validInput()) saveProduct({ variables }).then(onSaveAndClose);
              }}
            >
              Save and Close
            </CharlesButton>
          </>
        )}

        {id && duplicate ? <CharlesButton onClick={duplicateHandler}>Duplicate Product</CharlesButton> : null}

        {id && !simpleFields.odooId ? (
          <CharlesButton
            onClick={() => {
              odooSyncerModal.present({
                title: "Sync Product to Odoo",
                maxWidth: "max-w-5xl",
                children: <Syncer id={id} onSave={odooSyncerModal.hide} />,
              });
            }}
          >
            Sync to Odoo
          </CharlesButton>
        ) : null}

        <div className="flex items-center space-x-2">
          <label>Send notification when product is saved? </label>
          <Switcher isOn={sendNotification} onChange={() => setSendNotification((prev) => !prev)} />
        </div>
      </div>
    </div>
  );
};

export default BottomBar;
