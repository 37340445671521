import { useQuery, useLazyQuery } from '@apollo/client';
import { FETCH_ME } from '../query.js';

export function isStaff(user) {
   if (!user) return false;
   return user.isSuperuser || user.isStaff;
}

export function isInternalUser(user) {
   if (!user) return false;
   return (
      user.isSuperuser ||
      user.isStaff ||
      user.groups.map((g) => g.name.toLowerCase()).includes('internal')
   );
}

export function useFetchMe(options = {}) {
   return useQuery(FETCH_ME, { ...options });
}

export function useLazyFetchMe() {
   return useLazyQuery(FETCH_ME, { fetchPolicy: 'network-only' });
}
