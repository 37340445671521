import { useMutation, useQuery } from "@apollo/client";
import Page from "components/Page";
import { useParams } from "react-router-dom";
import { CREATE_SAMPLE, DELETE_SAMPLE, UPDATE_SAMPLE, FETCH_PACKAGE_INFO, FETCH_ALL_CHECK_ITEMS_BY_SAMPLE_ID } from "./graphql";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { useModals } from "ModalProvider";
import { useEffect, useState } from "react";
import { Input } from "components/Form";
import { Alert } from "components/Toast";
import { formatDate } from 'react-day-picker/moment';
import { PackageStatusChain } from "components/Status";
import CharlesButton from "components/charles/base";

import PackageImageList from "./PackageImage";
import MessageList, { MessageFormCreate } from "./MessageList";
import CcList from "./CcList";
import { IoIosHelpCircleOutline } from "react-icons/io";
import { FaAsterisk } from "react-icons/fa";


const SampleForm = ({ packageId, internalUsers, isEditable, originalSample, onSaveDone }) => {
  const { present, hide } = useModals();

  const isCreatingNewSample = originalSample ? false : true;
  const [sampleId, setSampleId] = useState(originalSample ? originalSample.id : null);
  const [sampleName, setSampleName] = useState(originalSample ? originalSample.name : '');
  const [qty, setQty] = useState(originalSample ? originalSample.quantity : 1);
  const [unit, setUnit] = useState(originalSample ? originalSample.unit : '');
  const [messages, setMessages] = useState(originalSample ? originalSample.checkItems.map(msg => {
    return {
      id: msg.id,
      senders: msg.senders,
      receivers: msg.receivers,
      content: msg.content,
    };
  }) : []);

  useEffect(() => {
    if (originalSample) {
      setMessages(originalSample.checkItems.map(msg => {
        return {
          id: msg.id,
          senders: msg.senders,
          receivers: msg.receivers,
          content: msg.content,
        };
      }));
    }
  }, [originalSample]);


  const [isEditing, setIsEditing] = useState(isCreatingNewSample);  // focus on new sample
  const [isDeleting, setIsDeleting] = useState(false);
  const [isAddingMsg, setIsAddingMsg] = useState(false);

  const [createSample] = useMutation(CREATE_SAMPLE);
  const [updateSample] = useMutation(UPDATE_SAMPLE);
  const [deleteSample] = useMutation(DELETE_SAMPLE);

  const handleSample = (action) => {
    if (!sampleName) {
      Alert("error", "Name is required");
      return;
    }

    const messagesOnlyUserIds = messages.map(msg => {
      return {
        id: msg.id,
        senders: msg.senders.map(sender => sender.id),
        receivers: msg.receivers.map(receiver => receiver.id),
        content: msg.content,
      };
    });

    if (originalSample) {
      // The reason for not using the isDeleting is beacuse the isDeleting is not updated in time
      if (action === "delete") {
        deleteSample({
          variables: {
            sampleId: sampleId
          },
          refetchQueries: [{ query: FETCH_PACKAGE_INFO, variables: { id: Number(packageId) } }],
          onCompleted: () => {
            Alert("success", "Sample deleted successfully");
          }
        });
        hide();
        setIsDeleting(false);

      } else {

        updateSample({
          variables: {
            sampleId: sampleId,
            name: sampleName,
            quantity: Number(qty),
            unit: unit,
            messages: messagesOnlyUserIds
          },
          refetchQueries: [{ query: FETCH_PACKAGE_INFO, variables: { id: Number(packageId) } }],
          onCompleted: () => {
            Alert("success", "Sample updated successfully");
          }
        });
      }

    } else {

      createSample({
        variables: {
          name: sampleName,
          quantity: Number(qty),
          packageId: Number(packageId),
          unit: unit,
          messages: messagesOnlyUserIds
        },
        refetchQueries: [{ query: FETCH_PACKAGE_INFO, variables: { id: Number(packageId) } }],
        onCompleted: (data) => {
          setSampleId(data.createSample.sample.id);
          Alert("success", "Sample added successfully");
        }
      });

    }

    onSaveDone();
    setIsAddingMsg(false);
    setIsEditing(false);
  };

  return (
    <div
      onFocus={() => {
        if (!isEditable) return;

        setIsEditing(true);
      }}
      onBlur={(event) => {
        // Check if the new focused element is still within this div
        if (event.currentTarget.contains(event.relatedTarget)) {
          // If it is, don't exit editing mode
          return;
        }

        if (isEditing && !isDeleting) {
          Alert("error", "Please save your changes!");
        }
      }}
      tabIndex={0} // Make this div focusable
      className={`${isEditable ? "cursor-pointer" : ""} ${isCreatingNewSample ? "border border-dashed dark:border-gray-700" : ""} rounded-lg bg-gray-100 p-6 hover:bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-900 transition-colors duration-300 ease-in-out`}
      data-testid="sample-form"
    >

      <div className="space-y-2">
        {!isEditing ?
          <div className="">
            {/* display mode */}
            <div className="space-y-2">
              <div>
                <p className="text-lg font-bold ">{sampleName}</p>
              </div>

              <div className="flex items-center" >
                <label className="w-24">QTY: </label>
                {qty}
                <span className="ml-2">{unit}</span>
              </div>

              <div className="flex">
                <label className="w-24">Message:</label>
              </div>

              <MessageList isEditing={false} messages={messages} />
            </div>
          </div>
          :
          <>
            {/* Editing Mode */}
            <div className="flex items-center" >
              <label className="w-24">Name: </label>
              <Input placeholder="Sample Name" value={sampleName} onChange={e => setSampleName(e.target.value)} className="w-1/2 dark:bg-gray-700 dark:bg-opacity-20" />
              <FaAsterisk className="text-red-500 text-xxs ml-1" />
            </div>

            <div className="flex items-center" >
              <label className="w-24">QTY: </label>
              <Input type="number" min={0} value={qty} onChange={e => setQty(e.target.value)} className=" w-14 dark:bg-gray-700 dark:bg-opacity-20" />

              <Input placeholder="Unit" value={unit} onChange={e => setUnit(e.target.value)} className="w-14 ml-6 dark:bg-gray-700 dark:bg-opacity-20" />
              <span className=" text-gray-500 text-opacity-50 ml-2">e.g. pair, piece</span>
            </div>

            {isCreatingNewSample ?
              <>
                <label className="w-24">Message:</label>
                <MessageFormCreate internalUsers={internalUsers} setMessages={setMessages} />
              </>
              :
              <>
                {/* Updating existing sample */}
                {isAddingMsg ?
                  <MessageFormCreate internalUsers={internalUsers} setMessages={setMessages} />
                  :
                  <div className="flex">
                    <label className="w-24">Message:</label>
                    <CharlesButton onClick={() => setIsAddingMsg(true)}>+ Add Message</CharlesButton>
                  </div>
                }

                <MessageList isEditing={true} messages={messages} internalUsers={internalUsers} setMessages={setMessages} />
              </>
            }

            <div className="my-4 flex items-center space-x-2">
              <CharlesButton
                onClick={handleSample}
                className="h-6 border border-sky-300 text-sky-500 hover:text-sky-800 hover:bg-sky-500 hover:border-sky-500 active:border-sky-700 text-xs px-2 py-1 rounded-md"
              >
                Save
              </CharlesButton>

              {!isCreatingNewSample && (
                <CharlesButton
                  onClick={() => {
                    setIsDeleting(true);

                    present({
                      title: "Delete Sample",
                      center: true,
                      children: (
                        <div>
                          <p className="py-6">Delete this sample?</p>
                          <div className="flex items-center space-x-4">
                            <CharlesButton
                              danger
                              onClick={() => { handleSample("delete"); }}>Delete</CharlesButton>

                            <CharlesButton
                              className=" text-gray-500 hover:text-gray-800"
                              onClick={() => {
                                hide();
                                setIsDeleting(false);
                                setIsEditing(false);
                              }}>Cancel</CharlesButton>
                          </div>
                        </div>
                      ),
                    });
                  }}
                  danger={true}
                  className="h-6 border border-red-500 hover:bg-red-500 hover:border-red-500 active:border-red-700 text-xs px-2 py-1 rounded-md"
                >
                  Delete
                </CharlesButton>
              )}
            </div>
          </>
        }
      </div>
    </div>
  );
};


const SampleList = ({ samples, packageId, internalUsers, isEditable }) => {
  const [isCreating, setIsCreating] = useState(false);

  return (
    <div className="text-sm space-y-4">
      {isEditable && !isCreating && (
        <div
          data-testid="package-add-sample"
          onClick={() => setIsCreating(true)}
          className="cursor-pointer text-center p-6 border border-dashed rounded-lg text-sky-500 border-gray-300 hover:text-sky-800 hover:bg-gray-300 dark:border-gray-700 dark:hover:bg-gray-900"
        >
          + Add Sample
        </div>
      )}

      {isCreating && (
        <SampleForm
          packageId={packageId}
          internalUsers={internalUsers}
          isEditable={isEditable}
          onSaveDone={() => { setIsCreating(false); }}
        />
      )}

      <div data-testid="package-samples" className=" space-y-4">
        {samples.length > 0 ? samples.map((sample) => (
          <SampleForm
            key={sample.id}
            originalSample={sample}
            packageId={packageId}
            internalUsers={internalUsers}
            isEditable={isEditable}
            onSaveDone={() => { setIsCreating(false); }}
          />
        )) :
          <p className="opacity-70 text-xs">No sample added yet.</p>
        }
      </div>
    </div>
  );
};


const PackageInfo = () => {
  const { packageId } = useParams();
  const { loading, error, data } = useQuery(FETCH_PACKAGE_INFO, {
    variables: { id: Number(packageId) }
  });
  const [showHelp, setShowHelp] = useState(true);

  if (loading) return <Spinner />;
  if (error) return <Errors errors={error} />;

  const isEditable = data.packageInfo.status !== "DELIVERED";

  return (
    <Page
      title={`#${packageId} Package Samples`}
      backTo={`/tools/package`}
    >
      <div className="p-6 grid grid-cols-1 lg:grid-cols-5 gap-x-6 gap-y-6">
        <div data-testid="package-flow-status" className="lg:col-span-5 text-xs space-y-2 ">
          <div className="flex items-center">
            <p className="w-24">Status:</p>
            <PackageStatusChain status={data.packageInfo.status} />
          </div>
          <div className="flex items-center">
            <p className="w-24">Destination:</p>
            <p className="font-bold">{data.packageInfo.destination.shortName}</p>
          </div>
          <div className="flex items-center">
            <p className="w-24">Shipment Date:</p>
            <p className="font-bold">{formatDate(data.packageInfo.createdAt, 'YYYY-MM-DD')}</p>
          </div>
          <div className="flex items-center">
            <p className="w-24">Cc:</p>
            <CcList internalUsers={data.allInternalUsers} samples={data.allSamples} parcel={data.packageInfo} isEditable={isEditable} />
            <CharlesButton
              onClick={() => setShowHelp(!showHelp)}
            >
              <IoIosHelpCircleOutline className="text-gray-500 text-opacity-50 ml-2 text-lg cursor-pointer " />
            </CharlesButton>
          </div>
          {showHelp && (
            <>
              <p className="ml-24 opacity-50">All senders, receivers, cc will receive an email notification of package receipt.</p>
              <p className="ml-24 opacity-50">You could notify the receiver of your sample by leaving a Message under the sample name.</p>
              <p className="ml-24 opacity-50">If you want to mention someone else, you could cc them here.</p>
            </>
          )}
        </div>

        <div className="card lg:col-span-3 self-start">
          <h3 className="mb-2">Samples</h3>
          <SampleList samples={data.allSamples} packageId={packageId} internalUsers={data.allInternalUsers} isEditable={isEditable} />
        </div>

        <div className="card lg:col-span-2 self-start">
          <h3 className="mb-2">Samples' Images</h3>
          <PackageImageList packageId={packageId} packageImages={data.allPackageImagesByPkgId} isEditable={isEditable} />
        </div>
      </div>
    </Page>
  );
};

export default PackageInfo;
