import { gql, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import Page from "components/Page";
import Spinner from "components/Spinner";
import CharlesButton from "components/charles/base";
import { useParams } from "react-router-dom";

const FETCH_AGENT_BY_ID = gql`
  query FETCH_AGENT_BY_ID($id: ID!) {
    user(id: $id) {
      id
      email
      agentCustomers {
        id
        email
      }
    }
  }
`;

const AgentDetailPage = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FETCH_AGENT_BY_ID, {
    variables: { id },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <Page title={`Retailer Agent: ${data.user.email}`} backTo="./..">
      <div className="m-6 grid grid-cols-12 gap-6">
        <div className=" col-span-8 space-y-6">
          <div className="card">
            <h5>Basic Info</h5>
            <div className="mt-4">...</div>
          </div>

          <div className="card">
            <h5>Retailers</h5>
            <div className="mt-2 -mx-2">
              <table>
                <tbody>
                  {data.user.agentCustomers.map((customer) => (
                    <tr className="py-2 border-y dark:border-gray-700" key={customer.id}>
                      <td>{customer.email}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="mt-2">...</div>
          </div>

          <div className="card">
            <h5>Recent Orders from Retailer</h5>
            <div className="mt-4">...</div>
          </div>
        </div>
        <div className=" col-span-4 space-y-6 flex flex-col">
          <div className="card">
            <h4>Total Sales</h4>
            <div className="mt-4">...</div>
          </div>

          <div className="flex-1"></div>

          <div>
            <CharlesButton danger>Delete Agent</CharlesButton>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default AgentDetailPage;
