import { Route, Routes } from "react-router-dom";
import RetailerList from "./RetailerList";
import RetailerDetailPage from "./RetailerDetailPage";

const RetailerPages = () => {
  return (
    <Routes>
      <Route index element={<RetailerList />} />
      <Route path=":id" element={<RetailerDetailPage />} />
    </Routes>
  );
};

export default RetailerPages;
