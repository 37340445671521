import { gql } from "@apollo/client";

export const FETCH_RETAILER_ORDERS = gql`
  query FETCH_RETAILER_ORDERS {
    retailerOrders {
      id
      user {
        id
        email
        retailer {
          id
          name
        }
      }
      total
      createdAt
      state
    }
  }
`;

export const ORDER_FRAGMENT = gql`
  fragment orderFragment on RetailerOrderType {
    id
    state
    odooId
    odooName
    items {
      id
      product {
        id
        productLine {
          name
        }
        number
        name
      }
      subtotal
      qty
    }
    latestPayment {
      id
      state
    }
    billingAddressName
    billingAddress
    shippingAddressName
    shippingAddress
    remark
    total
  }
`;

export const FETCH_RETAILER_ORDER = gql`
  query FETCH_RETAILER_ORDER($id: ID!) {
    retailerOrder(id: $id) {
      ...orderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;
