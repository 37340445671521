import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { FETCH_PRODUCT_LIST_DETAIL } from "./graphql";
import { ALLOWED_USERS } from "./index";
import Form from "./Form";

function Detail() {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FETCH_PRODUCT_LIST_DETAIL, {
    variables: { id },
    fetchPolicy: "network-only",
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <Form
      productListId={id}
      originalProductList={data.productList}
      allMaterials={data.allMaterials}
      allFactories={data.allFactories.filter((f) => f.status === 1)}
      allLabTestStandards={data.allLabTestStandards}
      allUsers={data.allUsers ? data.allUsers.filter((u) => ALLOWED_USERS.includes(u.email)) : []}
      allProductWarnings={data.allProductWarnings}
      allPriceItems={data.allPriceItems}
    />
  );
}

export default Detail;
