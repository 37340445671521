import { useMutation, useQuery } from "@apollo/client";
import { FETCH_SUPPLIER_STOCK_RECORD, UPDATE_SUPPLIER_STOCK_RECORD } from "./graphql";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { useEffect, useState } from "react";
import CharlesButton from "components/charles/base";
import SupplierSelector from "pages/subSupplier/orders/SupplierSelector";
import { Input, NativeDatePicker } from "components/Form";
import moment from "moment";
import { Alert } from "components/Toast";
import { parseError } from "apollo";

const UpdateSupplierStockRecordView = ({ id, hide }) => {
  const { loading, error, data } = useQuery(FETCH_SUPPLIER_STOCK_RECORD, {
    variables: { id },
  });

  useEffect(() => {
    if (data) {
      setVariables({
        qty: data.supplierStockRecord.qty,
        supplierId: data.supplierStockRecord.supplier.id,
        readyDate: moment(data.supplierStockRecord.readyDate).format("YYYY-MM-DD"),
        remark: data.supplierStockRecord.remark,
      });
    }
  }, [data]);

  const [variables, setVariables] = useState({ qty: 0, supplierId: "", productId: "", readyDate: "", remark: "" });

  const [saveSupplierStockRecord, saveSupplierStockRecordRes] = useMutation(UPDATE_SUPPLIER_STOCK_RECORD, {
    variables: { id, ...variables },
    onCompleted() {
      Alert("success", "Supplier Stock Record Updated");
      if (hide) hide();
    },
    onError(error) {
      Alert("error", parseError(error));
    },
    refetchQueries: ["FETCH_CHINA_PRODUCT_STOCKS"],
    awaitRefetchQueries: true,
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <div>
      <div>
        <h4>
          [{data.supplierStockRecord.product.number}] {data.supplierStockRecord.product.name}
        </h4>
        <div className="-mx-2 mt-4 whitespace-nowrap">
          <table>
            <tbody>
              <tr>
                <td>Supplier</td>
                <td className=" w-full">
                  <SupplierSelector value={variables.supplierId} onChange={(supplier) => setVariables({ ...variables, supplierId: supplier.id })} activeOnly />
                </td>
              </tr>
              <tr>
                <td>Ready Date</td>
                <td>
                  <NativeDatePicker value={variables.readyDate} onChange={(e) => setVariables({ ...variables, readyDate: e.target.value })} />
                </td>
              </tr>
              <tr>
                <td>QTY</td>
                <td>
                  <Input className="text-center" value={variables.qty} onChange={(e) => setVariables({ ...variables, qty: e.target.value })} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <hr />

      <div>
        <CharlesButton onClick={saveSupplierStockRecord} loading={saveSupplierStockRecordRes.loading}>
          Save
        </CharlesButton>
      </div>
    </div>
  );
};

export default UpdateSupplierStockRecordView;
