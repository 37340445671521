const { useQuery, gql } = require('@apollo/client');
const { default: Errors } = require('components/Errors');
const { default: Spinner } = require('components/Spinner');

const FETCH_ALL_PRODUCTS_WITH_IMAGES = gql`
   query FETCH_ALL_PRODUCTS_WITH_IMAGES {
      allProducts(isActive: true, isSalable: true, hasProductLine: true) {
         id
         name
         productLine {
            id
            name
            newImages(stage: "wis", imageType: "item") {
               id
               url(size: "300x300")
            }
         }
      }
   }
`;

const ProductBeautyshots = () => {
   const { loading, error, data } = useQuery(FETCH_ALL_PRODUCTS_WITH_IMAGES);

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   return (
      <div className="p-6">
         <div>
            {data.allProducts.map((product) => (
               <div
                  key={product.id}
                  className="py-2 border-b dark:border-gray-700"
               >
                  <div>{product.name}</div>

                  {product.productLine &&
                  product.productLine.newImages.length > 0 ? (
                     <div className="mt-2">
                        <label htmlFor="">New Images:</label>
                        <div className="flex items-end overflow-auto mt-2">
                           {product.productLine.newImages.map((i) => (
                              <div key={i.id}>
                                 <img src={i.url} className="h-16" />
                              </div>
                           ))}
                        </div>
                     </div>
                  ) : null}
               </div>
            ))}
         </div>
      </div>
   );
};

export default ProductBeautyshots;
