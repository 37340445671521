import React, { useContext } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import Icon, { WisLogo } from "./Icon";
import { getUserRoutes } from "../UserRoutes.js";
import { Alert } from "./Toast";
import { isInternalUser } from "../hooks/user";
import version from "utils/version";
import { AppContext } from "App";
import { IoIosLock } from "react-icons/io";
import { OdooContext } from "OdooProvider";
import { BsGear } from "react-icons/bs";
import { FiCircle } from "react-icons/fi";

export const LOGOUT = gql`
  mutation LOGOUT {
    logout {
      isLogout
    }
  }
`;

const LOGIN_AS_USER = gql`
  mutation LOGIN_AS_USER($email: String!) {
    loginAsUser(userEmail: $email) {
      user {
        id
        email
        firstName
        lastName
        isSuperuser
        isStaff
        groups {
          id
          name
        }
      }
    }
  }
`;

const Header = () => {
  const { showSmallMenu, setShowSmallMenu } = useContext(AppContext);
  const { odooUrl } = useContext(OdooContext);
  const navigate = useNavigate();
  const { settings, setSettings, user, setUser } = useContext(AppContext);

  const [loginAsUser, { loginAsUserStatus }] = useMutation(LOGIN_AS_USER, {
    onCompleted: (res) => {
      setUser(res.loginAsUser.user);
      Alert("success", `You are now logged in as ${res.loginAsUser.user.email}`);
    },
    onError(error) {
      Alert("error", error.message);
    },
  });

  const [logout] = useMutation(LOGOUT, {
    onCompleted: () => {
      // setUser(null);
      window.location.href = process.env.REACT_APP_AUTH_SERVER + "?redirectTo=" + window.location.origin;
    },
    onError(err) {
      Alert("error", err.message);
    },
  });

  const routes = getUserRoutes(user);

  function switchMetric() {
    setSettings((prev) => ({ ...prev, metric: !settings.metric }));
  }

  function toggleMenu() {
    setShowSmallMenu((prev) => !prev);
  }

  function promoLoginAsUser() {
    const email = window.prompt("The user's email you want to login?");
    if (email) {
      loginAsUser({ variables: { email } });
    }
  }

  return (
    <nav
      className={`overflow-auto flex flex-col bg-gray-900 text-gray-200 dark:text-gray-400 z-30 animate text-xs
            md:fixed md:w-52
            ${showSmallMenu ? "left-0 h-screen" : "-left-52"}
            md:left-0 md:h-screen
            border-r border-gray-700
         `}
    >
      <div>
        <div className="sticky top-0">
          <div className={`flex justify-between backdrop-filter backdrop-blur-lg z-10`}>
            <div className="w-12 h-12 flex justify-center items-center cursor-pointer md:hidden" onClick={toggleMenu}>
              <Icon icon={showSmallMenu ? "close" : "menu"} />
            </div>
            <div
              className="flex md:items-end py-1 text-white dark:text-gray-400 md:ml-4 md:py-3 cursor-pointer"
              onClick={(_) => {
                navigate("/");
                setShowSmallMenu(false);
              }}
            >
              <WisLogo />

              <div className="text-gray-600 ml-1 hidden lg:block" style={{ fontSize: 11 }}>
                v{version}
              </div>
            </div>

            <div className="w-12 h-12" />
          </div>

          {!location.href.includes(".waboba.com") ? <div className=" bg-pink-600 text-white px-4 py-2 tracking-widest font-bold">TEST SITE</div> : null}
        </div>

        <div className={`pb-8 ${showSmallMenu ? "" : "hidden"} md:block`}>
          {routes.map((cat, catIndex) => (
            <div key={catIndex}>
              <div className="p-2 text-gray-500 bg-gray-900 block text-xs font-normal px-4 uppercase">{cat.text}</div>
              <div className="border-t border-b border-gray-700">
                {cat.path.map((p, pIndex) => (
                  <div key={pIndex} className="nav-item tracking-wide" onClick={toggleMenu}>
                    {p.blank ? (
                      <Link className="bg-gray-800 text-gray-100 font-light" to={p.path} target="blank">
                        <span className={p.color}>{typeof p.icon === "object" ? p.icon : <Icon icon={p.icon} size={14} />}</span>
                        <div>{p.text}</div>
                      </Link>
                    ) : (
                      <NavLink className="bg-gray-800 text-gray-100 font-light" target={p.target || ""} to={p.path}>
                        <span className={p.color}>{typeof p.icon === "object" ? p.icon : <Icon icon={p.icon} size={14} />}</span>

                        <div>{p.text}</div>
                      </NavLink>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}

          <div>
            <div className="text-gray-500 block text-xs font-normal px-4 py-2 uppercase">{user.email}</div>
            <div className="border-t border-b border-gray-700">
              {isInternalUser(user) && (
                <div className="nav-item text-purple-500 bg-gray-800" onClick={switchMetric}>
                  <span>
                    <Icon icon="cog" size={14} />
                    <div>{settings.metric ? "Metric" : "Imperial"}</div>
                  </span>
                </div>
              )}

              {user.isSuperuser && (
                <>
                  <div className="nav-item text-pink-500 bg-gray-800" onClick={promoLoginAsUser}>
                    <span>
                      <Icon icon="swap" size={14} />
                      <div>{loginAsUserStatus && loginAsUserStatus.loading ? "Logging in..." : "Impersonate"}</div>
                    </span>
                  </div>
                  {odooUrl ? (
                    <div className="nav-item text-yellow-500 bg-gray-800">
                      <a className="bg-gray-800 text-gray-100" href={odooUrl} target="_blank">
                        <span className="text-purple-500">
                          <FiCircle size={14} />
                        </span>
                        <div>Odoo</div>
                      </a>
                    </div>
                  ) : null}
                </>
              )}

              <div className="nav-item text-yellow-500 bg-gray-800">
                <a className="bg-gray-800 text-gray-100" href="https://cdn.waboba.com/public/Policy.html" target="_blank">
                  <span className="text-yellow-500">
                    <IoIosLock size={14} />
                  </span>
                  <div>Data Protection</div>
                </a>
              </div>

              <div className="nav-item text-teal-500 tracking-wide bg-gray-800">
                <NavLink className="bg-gray-800 text-gray-100 font-light" to="/settings">
                  <span>
                    <BsGear />
                  </span>
                  <div>Settings</div>
                </NavLink>
              </div>

              <div className="text-red-600 bg-gray-800">
                <div className="nav-item" onClick={logout}>
                  <span>
                    <Icon icon="stand-by" size={14} />
                    <div>Logout</div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
