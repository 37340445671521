import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import version from 'utils/version';

import Supplier from './supplier';
import WabobaLogo from './WabobaLogo';

const SharedRoutes = () => {
   console.log('SharedRoutes');
   return (
      <Routes>
         <Route element={<Layout />}>
            <Route path="supplier/*" element={<Supplier />} />
         </Route>
      </Routes>
   );
};

const Layout = () => (
   <div className="bg-gray-100 dark:bg-gray-900 flex flex-col flex-1 min-h-screen relative">
      <Outlet />

      <div className="text-gray-600 p-2 text-center" style={{ fontSize: 12 }}>
         v{version}
      </div>
   </div>
);

export default SharedRoutes;
