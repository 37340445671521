import { useState } from "react";
import { Input, Select } from "components/Form";
import CharlesButton from "components/charles/base";
import { useMutation } from "@apollo/client";
import { Alert } from "components/Toast";
import { parseError } from "apollo";
import { CONFIRM_PRODUCT_LIST_DOCUMENT } from "./graphql";
import { GENERATE_DOCUMENT } from "graphql/mutations";
import http from "utils/http";
import { STANDARD_17, STANDARD_23 } from "./CPCStandards";

const CPCForm = ({ originalValues }) => {
  const [productList, setProductList] = useState(originalValues);

  const status = originalValues.status ?? "PENDING";

  const [confirmDoc, confirmDocRes] = useMutation(CONFIRM_PRODUCT_LIST_DOCUMENT, {
    onCompleted() {
      Alert("success", "Document is confirmed.");
    },
    onError(error) {
      Alert(error, parseError(error));
    },
  });
  const [generateDocument] = useMutation(GENERATE_DOCUMENT);
  const [exportingPDF, setExportingPDF] = useState(false);

  function confirmHandler() {
    if (window.confirm("Are you sure to confirm this document?")) {
      const cpcData = JSON.stringify({ ...productList, status: "CONFIRMED", standards }, "", 4);
      confirmDoc({ variables: { id: originalValues.id, cpcData } });
    }
  }

  function cancelHandler() {
    if (window.confirm("Are you sure to cancel this document?")) {
      const cpcData = JSON.stringify({ ...productList, status: "PENDING" });
      confirmDoc({ variables: { id: originalValues.id, cpcData } });
    }
  }

  function generatePdfHandler() {
    const name = `${productList.name} - DOC`;
    const variables = {
      docType: "cpc",
      name,
      data: JSON.stringify(productList, "", 4),
    };

    setExportingPDF(true);
    generateDocument({ variables })
      .then((res) =>
        http.get(`${process.env.REACT_APP_SERVER_BASE_URL}generated-document/${res.data.generateDocument.document.id}/?pdf=1`, {
          responseType: "blob",
          withCredentials: true,
        })
      )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name + ".pdf");
        document.body.appendChild(link);
        link.click();
        setExportingPDF(false);
      })
      .catch((e) => {
        Alert("error", e.message);
        setExportingPDF(false);
      });
  }

  const standards = productList.standard === "17" ? STANDARD_17 : STANDARD_23;

  return (
    <div className="grid grid-cols-12 gap-6 p-6 text-sm">
      <div className="col-span-9">
        <div className="space-y-6 card p-16">
          <div className="flex justify-between">
            <h3>
              CHILDREN’S PRODUCT CERTIFICATE
              <br />
              FOR
              <br />
              CONSUMER PRODUCT SAFETY IMPROVEMENT ACT
            </h3>

            <div>
              <img className="h-10" src="https://cdn.waboba.com/public/waboba-logo-w-waboba.png" alt="Waboba Logo" />
            </div>
          </div>

          <hr />

          <div className="flex space-x-16">
            <div className="flex-1">
              <h5>US IMPORTER/DOMESTIC MANUFACTURER</h5>
              <div className="mt-4">
                <p>Pete Widmann</p>
                <p className="mt-4">WABOBA INC</p>
                <p>3850 Holcomb Bridge Road</p>
                <p>Suite 270</p>
                <p>Peachtree Corners GA 30092 </p>
                <p>United States</p>
                <p className="mt-2">Tel No.: 678-232-0967</p>
              </div>
            </div>

            <div className="flex-1">
              <h5>PERSON MAINTAINING RECORDS</h5>
              <div className="mt-4">
                <p>Stella Huang</p>
                <p className="mt-4">WABOBA INC</p>
                <p>3850 Holcomb Bridge Road</p>
                <p>Suite 270</p>
                <p>Peachtree Corners GA 30092 </p>
                <p>United States</p>
                <p className="mt-2">Tel No.: 678-232-0967</p>
              </div>
            </div>
          </div>

          <div className="-mx-2">
            <table>
              <tbody>
                <tr>
                  <td>
                    <label htmlFor="">Sample Description: </label>
                  </td>
                  <td className="w-full" colSpan={3}>
                    {
                      <Input
                        className="w-full"
                        value={productList.name}
                        onChange={(e) => setProductList({ ...productList, name: e.target.value })}
                        disabled={status === "CONFIRMED"}
                      />
                    }
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="">Manufacturer: </label>
                  </td>
                  <td className="w-1/2">
                    <span>Waboba AB</span>
                  </td>
                  <td>
                    <label htmlFor="">Style No(s): </label>
                  </td>
                  <td className="w-1/2">
                    <Input
                      className="w-full"
                      value={productList.number}
                      onChange={(e) => setProductList({ ...productList, number: e.target.value })}
                      disabled={status === "CONFIRMED"}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="">Place of Manufacturer: </label>
                  </td>
                  <td className="w-1/2">
                    <Input
                      className="w-full"
                      value={productList.placeOfManufacture}
                      onChange={(e) => setProductList({ ...productList, placeOfManufacture: e.target.value })}
                      disabled={status === "CONFIRMED"}
                    />
                  </td>
                  <td>
                    <label htmlFor="">Country of Origin: </label>
                  </td>
                  <td className="w-1/2">
                    <Input
                      className="w-full"
                      value={productList.countryOfOrigin}
                      onChange={(e) => setProductList({ ...productList, countryOfOrigin: e.target.value })}
                      disabled={status === "CONFIRMED"}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="">Date of Manufacturer: </label>
                  </td>
                  <td className="w-1/2">
                    <input
                      className=" border dark:bg-gray-800 px-4 py-1 rounded-xl"
                      type="date"
                      value={productList.dateOfManufacture}
                      onChange={(e) => setProductList({ ...productList, dateOfManufacture: e.target.value })}
                      disabled={status === "CONFIRMED"}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="align-baseline">
                    <label htmlFor="">Place of Testing: </label>
                  </td>
                  <td colSpan={3}>
                    <div className=" whitespace-pre-wrap">{productList.placeOfTesting}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="">Reference No.: </label>
                  </td>
                  <td>
                    <Input
                      className="w-full"
                      value={productList.refNo}
                      onChange={(e) => setProductList({ ...productList, refNo: e.target.value })}
                      disabled={status === "CONFIRMED"}
                    />
                  </td>
                  <td>
                    <label htmlFor="">Date of Testing: </label>
                  </td>
                  <td>
                    <input
                      className="dark:bg-gray-800 px-4 py-1 rounded-xl border"
                      type="date"
                      value={productList.dataOfTesting}
                      onChange={(e) => setProductList({ ...productList, dataOfTesting: e.target.value })}
                      disabled={status === "CONFIRMED"}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            I (we) hereby certify that the component / product contained within this shipment complies with the following applicable rules, bans, regulations
            and standards enforced by the CPSC based on testing.
          </div>

          <div>The following rules, bans, standards and regulations were tested for this product:</div>

          <div>
            <label className="pr-3">Standard: </label>
            <Select value={productList.standard} onChange={(e) => setProductList({ ...productList, standard: e.target.value })}>
              <option value="17">17</option>
              <option value="23">23</option>
            </Select>
          </div>

          <table>
            <thead>
              <tr>
                <th className="border">Products</th>
                <th className="border whitespace-nowrap">Law / Act</th>
                <th className="border">Federal Regulations Title 16 CFR Part No. / Section No.</th>
              </tr>
            </thead>
            <tbody>
              {standards.map((row, index) => (
                <tr key={index}>
                  <td className="border">{row.products}</td>
                  <td className="border">{row.law}</td>
                  <td className="border">{row.section}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <div>
            <label htmlFor="">Note:</label>

            <div className="mt-2">
              <p>CPSIA – Consumer Product Safety Improvement Act</p>
              <p>FHSA – Federal Hazardous Substances Act</p>
              <p>CPSA – Consumer Product Safety Act</p>
              <p>FFA – Flammable Fabrics Act</p>
              <p>RSA – Refrigerator Safety Act</p>
              <p>P.L. – Public Law</p>
              <p>CGBPA – Children’s Gasoline Burn Prevention Act</p>
              <p>DSA – Drywall Safety Act</p>
              <p>PPPA – Poison Prevention Packaging Act</p>
            </div>
          </div>
        </div>
      </div>

      <div className=" col-span-3 flex flex-col space-y-6">
        <div className="card">
          <h5>Document Status</h5>
          <div className="mt-2">
            <div
              className={`font-black text-2xl
            ${status === "PENDING" ? "text-orange-600" : status === "CONFIRMED" ? "text-green-600" : "text-red-500"}
            `}
            >
              {status}
            </div>
          </div>
          {status === "PENDING" && <div className="mt-2">This document is pending confirmation, be careful for the information in this document.</div>}
          {status === "PENDING" && (
            <CharlesButton className="mt-4" onClick={confirmHandler} loading={confirmDocRes.loading}>
              Confirm Document
            </CharlesButton>
          )}
          {status === "CONFIRMED" && (
            <CharlesButton className="mt-4" danger onClick={cancelHandler}>
              Reset to Pending
            </CharlesButton>
          )}
        </div>

        <div className="card">
          <CharlesButton onClick={generatePdfHandler} loading={exportingPDF}>
            Generate PDF
          </CharlesButton>
        </div>
      </div>
    </div>
  );
};

export default CPCForm;
