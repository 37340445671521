import { priceCalculateMethodNames } from "../../../../components/ForwarderRoute";

export const transportationOptions = ["SEA", "SHIP", "AIR", "TRAIN", "COURIER", "SEA & AIR"];

export const priceCalculateMethod = {
  [priceCalculateMethodNames.PER_BILL]: "Per Bill",
  [priceCalculateMethodNames.PER_M3]: "Per CBM",
  [priceCalculateMethodNames.PER_KG]: "Per KG",
  [priceCalculateMethodNames.GP20]: "20GP",
  [priceCalculateMethodNames.GP40]: "40GP",
  [priceCalculateMethodNames.HQ40]: "40HQ",
  [priceCalculateMethodNames.HQ45]: "45HQ",
};

export const priceCategories = [
  {
    name: "起运港项目",
    defaultCurrency: "RMB",
    currencyOptions: ["RMB"],
    currencyTips: "1 usd = 多少rmb",
  },
  {
    name: "海运费项目",
    defaultCurrency: "USD",
    currencyOptions: ["USD", "EUR"],
    currencyTips: "1 eur = 多少usd",
  },
  {
    name: "目的港项目",
    defaultCurrency: "USD",
    currencyOptions: ["USD", "EUR"],
    currencyTips: "1 eur = 多少usd",
  },
];

export const initialPriceList = [
  {
    name: "起运港项目",
    currency: "RMB",
    rateToUsd: 6.88,
    items: [
      {
        name: "报关费",
        calculateMethod: priceCalculateMethodNames.PER_BILL,
        unitPrice: 0,
        priceInUsd: 0,
      },
      {
        name: "文件费",
        calculateMethod: priceCalculateMethodNames.PER_BILL,
        unitPrice: 0,
        priceInUsd: 0,
      },
      {
        name: "电放费",
        calculateMethod: priceCalculateMethodNames.PER_BILL,
        unitPrice: 0,
        priceInUsd: 0,
      },
      {
        name: "操作费",
        calculateMethod: priceCalculateMethodNames.PER_BILL,
        unitPrice: 0,
        priceInUsd: 0,
      },
      {
        name: "港建",
        calculateMethod: priceCalculateMethodNames.PER_M3,
        unitPrice: 0,
        priceInUsd: 0,
      },
      {
        name: "拼箱费",
        calculateMethod: priceCalculateMethodNames.PER_M3,
        unitPrice: 0,
        priceInUsd: 0,
      },
      {
        name: "VGM",
        calculateMethod: priceCalculateMethodNames.PER_M3,
        unitPrice: 0,
        priceInUsd: 0,
      },
    ],
  },
  {
    name: "海运费项目",
    currency: "USD",
    rateToUsd: 1.18,
    items: [
      {
        name: "海运费",
        calculateMethod: priceCalculateMethodNames.PER_M3,
        unitPrice: 0,
        priceInUsd: 0,
      },
    ],
  },
  {
    name: "目的港项目",
    currency: "USD",
    rateToUsd: 1.18,
    items: [
      {
        name: "进口清关费",
        calculateMethod: priceCalculateMethodNames.PER_BILL,
        unitPrice: 0,
        priceInUsd: 0,
      },
      {
        name: "DTHC",
        calculateMethod: priceCalculateMethodNames.PER_M3,
        unitPrice: 0,
        priceInUsd: 0,
      },
      {
        name: "DDOC",
        calculateMethod: priceCalculateMethodNames.PER_BILL,
        unitPrice: 0,
        priceInUsd: 0,
      },
      {
        name: "送货费",
        calculateMethod: priceCalculateMethodNames.PER_M3,
        unitPrice: 0,
        priceInUsd: 0,
      },
    ],
  },
];
