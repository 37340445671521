import { gql, useMutation } from "@apollo/client";
import { parseError } from "apollo";
import { Alert } from "components/Toast";
import CharlesButton from "components/charles/base";
import { BsArrowRight } from "react-icons/bs";

const SALES_PRICE_BATCH_UPDATE = gql`
  mutation SALES_PRICE_BATCH_UPDATE($priceListId: ID!, $salesPrices: [SalesPriceInputType]!) {
    salesPriceBatchUpdate(priceListId: $priceListId, salesPrices: $salesPrices) {
      priceList {
        id
      }
    }
  }
`;

const BatchUpdatePriceView = ({ priceListId, rows, hide }) => {
  const [updatePrices, updatePricesRes] = useMutation(SALES_PRICE_BATCH_UPDATE, {
    variables: {
      priceListId,
      salesPrices: rows.map((row) => ({
        name: row.name,
        number: row.number,
        price: row.newPrice,
        discountPercentage: row.newDiscount,
      })),
    },
    onCompleted() {
      Alert("success", "Prices updated successfully");
      hide();
    },
    onError(error) {
      Alert("error", parseError(error));
    },
    refetchQueries: ["FETCH_PRICE_LIST"],
    awaitRefetchQueries: true,
  });
  return (
    <div>
      <div className="-mx-2 overflow-auto max-h-96">
        <table>
          <thead>
            <tr className=" sticky top-0 z-10 bg-gray-100 whitespace-nowrap">
              <th>Product</th>
              <th className="text-right">Price</th>
              <th className="text-right">Discount</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index} className="border-y dark:border-gray-700">
                <td className="w-full">
                  [{row.number}] {row.name}
                </td>
                <td>
                  <div className="flex justify-end items-center space-x-2">
                    <span>{row.originalPrice.toFixed(2)}</span>
                    <BsArrowRight />
                    <span className={row.newPrice > row.originalPrice ? "text-red-500" : row.newPrice < row.originalPrice ? "text-green-500" : ""}>
                      {row.newPrice.toFixed(2)}
                    </span>
                  </div>
                </td>
                <td className="text-center px-0">
                  <div className="flex justify-end items-center space-x-2">
                    <span>{row.originalDiscount.toFixed(2)}</span>
                    <BsArrowRight />
                    <span className={row.newDiscount > row.originalDiscount ? "text-red-500" : row.newDiscount < row.originalDiscount ? "text-green-500" : ""}>
                      {row.newDiscount.toFixed(2)}
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-6">You are about to update {rows.length} prices. Are you sure?</div>

      <div className="flex items-center mt-4 space-x-6">
        <CharlesButton primary onClick={updatePrices} loading={updatePricesRes.loading}>
          Yes, Update Now
        </CharlesButton>
        <CharlesButton onClick={hide}>No, Take me Back</CharlesButton>
      </div>
    </div>
  );
};

export default BatchUpdatePriceView;
