import React from 'react';

export default () => (
   <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-362 270.3 125.7 55"
      fill="#00bbd7"
   >
      <path d="M-319.6 303.8c-.2-2.4-3-3.7-6-3.1-4.3.8-11 6.2-11.7 10.2-.3 1.5.1 2.5.6 3.3 3.6 4.8 9.9-2 12.6-3.7.2 4.6 3.1 6.1 3.4 6 .2-.1 0-.4 0-.5-2.2-2.9 1.3-9.5 1.1-12.2m-10.1 7c-.8.6-2.9 1.5-3.4.3-.8-1.9 2.8-4.2 3.7-4.8 1.8-1.2 6.1-2.7 6.4-2.2.3.6-3.6 4.9-6.7 6.7M-245.9 303.8c-.2-2.4-3-3.7-6-3.1-4.3.8-11 6.2-11.7 10.2-.3 1.5.1 2.5.6 3.3 3.6 4.8 9.9-2 12.6-3.7.2 4.6 3.1 6.1 3.4 6 .2-.1 0-.4 0-.5-2.2-2.9 1.3-9.5 1.1-12.2m-10.2 7c-.8.6-2.9 1.5-3.4.3-.8-1.9 2.8-4.2 3.7-4.8 1.8-1.2 6.1-2.7 6.4-2.2.4.6-3.6 4.9-6.7 6.7M-289.3 302.4c-3.5.3-8.5 3.5-10 6.3-2.7 4.9 1.1 8.4 6.1 7.6 2.2-.4 5.1-2.1 6.3-3.1 1.4-1 3.2-3.1 3.4-5.6.3-3.7-2.4-5.5-5.8-5.2m-.6 8.6c-1.1.7-5.1 2.8-5.5.5-.5-2.6 4.1-5.1 5.9-5.4.8-.2 2.4-.1 2.7 1.2.4 1.7-2 3-3.1 3.7M-266.8 302.4c-4.8-1.4-10.1 3.8-11.4 4.4 1.5-6.4 6.6-17.6 6.2-18.1-.4-.5-4.3 3-4.7 3.9-.6 1.3-1.1 3.1-1.7 4.7-2 5.1-5.2 14.4-4.4 16.9.6 1.4 2.6 1.8 4.3 1.8 5.5-.1 12.3-3.1 14.1-6.8 1.2-2.8-.2-6.1-2.4-6.8m-5.5 8.2c-2.6 1-5.9 1.7-6 1.3-.2-.6 2.9-2.8 3.8-3.5 1-.7 3.3-2.5 5.2-2.6.9 0 1.7.3 1.9.9.6 1.9-3.1 3.3-4.9 3.9M-303.2 302.4c-4.8-1.4-10.1 3.8-11.4 4.4 1.5-6.4 6.6-17.6 6.2-18.1s-4.3 3-4.7 3.9c-.6 1.3-1.1 3.1-1.7 4.7-2 5.1-5.2 14.4-4.4 16.9.6 1.4 2.6 1.8 4.3 1.8 5.5-.1 12.3-3.1 14.1-6.8 1.3-2.8-.1-6.1-2.4-6.8m-5.4 8.2c-2.6 1-5.9 1.7-6 1.3-.2-.6 2.9-2.8 3.8-3.5 1-.7 3.2-2.5 5.2-2.6.9 0 1.7.3 1.9.9.6 1.9-3.1 3.3-4.9 3.9" />
      <path d="M-318.6 274.5c-8.3 8-19 21.2-24.9 37.6-.5 1.5-1.3 4.4-1.5 4.5-.1.2-.8-2.8-.9-4.1-.2-3.1.6-5.8 2-7.8 1.1-1.6 3.7-3.6.1-3.9-5.3-.4-11.1 5.7-11.8 6.5-.4.5-.9 1.2-1 1.2s.1-.9.1-1.4c.7-7.5 4.1-17.7 3.5-18.1-.6-.4-4.7 4.2-5.2 5.4-.6 1.2-2.4 10.7-2.7 16.8-.1 1.7-.5 5.2 1 5.4 1.1.1 2.2-2.2 2.8-3.1 4.8-7.4 9.2-9.3 9.4-9.1.1.2-.2-.2-.7 2.4-.8 4.9 0 10.8 1.9 14.8.6 1.2 2 3.7 3.5 3.3 1.1-.3 1.3-2.6 1.5-3.6.9-4.7 2-8.6 3.4-12.2 3.9-10.2 8.8-18.7 14.8-26.9 1.8-2.5 3.7-4.8 5.6-7.2 1.2-1.6 3.7-4.6 3.6-4.6-.3.1-3 2.6-4.5 4.1" />
      <g>
         <path d="M-239.4 298.7c-.1-.1-.3-.1-.6-.1h-.4v1h.4c.2 0 .4 0 .5-.1.2-.1.3-.2.3-.4s0-.3-.2-.4m-.5-.4c.3 0 .6 0 .7.1.3.1.4.3.4.7 0 .2-.1.4-.3.5-.1.1-.2.1-.4.1.2 0 .4.1.5.3.1.1.1.3.1.4v.5h-.5V300.6c0-.3-.1-.5-.3-.6-.1-.1-.3-.1-.5-.1h-.4v1.1h-.5v-2.7h1.2zm-1.4-.1c-.4.4-.6.9-.6 1.5s.2 1.1.6 1.5c.4.4.9.6 1.5.6s1.1-.2 1.5-.6c.4-.4.6-.9.6-1.5s-.2-1.1-.6-1.5c-.4-.4-.9-.6-1.5-.6-.6-.1-1.1.2-1.5.6m3.2 3.2c-.5.5-1.1.7-1.8.7s-1.3-.2-1.7-.7c-.5-.5-.7-1.1-.7-1.8s.2-1.3.7-1.7c.5-.5 1.1-.7 1.7-.7.7 0 1.3.2 1.8.7.5.5.7 1.1.7 1.7.1.8-.2 1.3-.7 1.8" />
      </g>
   </svg>
);
