import React, { useState, forwardRef, useRef } from "react";
import TextareaAutosize from "react-autosize-textarea";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { Tag } from "./base";
import { uuidv4 } from "../utils/uuid.js";
import { InlineSpinner } from "./Spinner";

const height = 30;

export const Input = forwardRef(
  (
    {
      className = "",
      border = "border border-gray-200 dark:border-gray-800",
      bgColor = "dark:bg-gray-800",
      validated = true,
      label,
      value,
      disabled = false,
      ...rest
    },
    ref
  ) => {
    return (
      <input
        className={`px-2 rounded-lg appearance-none placeholder-gray-300 dark:placeholder-gray-700 dark:text-gray-200 disabled:cursor-not-allowed max-w-full
              ${border}
              ${validated ? "focus:border-sky-600 dark:focus:border-sky-700" : "border-red-500 dark:border-red-800"}
              ${bgColor}
              ${className}
              `}
        style={{ height }}
        value={value === null || value === undefined ? "" : value}
        disabled={disabled}
        ref={ref}
        {...rest}
      />
    );
  }
);

export const Switcher = ({ className = "", isOn, onChange, disabled, loading }) => (
  <div
    className={`
          flex items-center rounded-full bg-white duration-200 relative
          ${disabled ? "cursor-not-allowed opacity-50" : "cursor-pointer "}
          ${isOn ? "bg-green-500" : "bg-gray-300 dark:bg-gray-700"}
          ${className}
          `}
    style={{ width: 42, height: 24 }}
    onClick={disabled ? null : onChange}
  >
    <div style={{ width: 20, height: 20, left: isOn ? 20 : 2 }} className="rounded-full duration-200 absolute bg-white dark:bg-gray-100"></div>
    {loading ? (
      <div className="relative pl-14">
        <InlineSpinner size={18} text={null} />
      </div>
    ) : null}
  </div>
);

export const TapSelector = ({ className = "", options, value, onChange }) => {
  const [selected, setSelected] = useState(value);
  return (
    <div className={`flex items-center rounded-full bg-white cursor-pointer relative ${className}`}>
      <div className="flex">
        {options.map((i, index) => (
          <Tag
            key={index}
            selected={selected === i}
            className={`animate-ease-2 mr-4`}
            title={i}
            onClick={() => {
              if (!onChange) return;
              setSelected(i);
              onChange(i);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export const MultipleTapSelector = ({ className = "", options, value, onChange }) => {
  const [selected, setSelected] = useState(value);
  return (
    <div className={`flex items-center rounded-full bg-white relative ${className}`}>
      <div className="flex flex-wrap">
        {options.map((i, index) => (
          <Tag
            key={index}
            selected={selected.indexOf(i) > -1}
            className={`animate-ease-2 mr-4 mb-2`}
            title={i}
            onClick={() => {
              if (!onChange) return;
              let res = [...selected];
              if (selected.indexOf(i) > -1) {
                res = selected.filter((prevItem) => prevItem !== i);
              } else {
                res = [...selected, i];
              }
              setSelected(res);
              onChange(res);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export const Select = ({ className = "", textAlignLast = "", plain = false, children, ...rest }) => (
  <select
    className={`${className} cursor-pointer max-w-full overflow-auto
         ${
           plain ? "" : "border border-gray-200 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-200"
         } rounded-lg px-4 focus:border-sky-600 disabled:opacity-50 disabled:cursor-not-allowed max-w-full`}
    style={{ height, textAlignLast }}
    {...rest}
  >
    {children}
  </select>
);

export const Text = ({ className = "", value, bgColor = "dark:bg-gray-800", ...rest }) => (
  <TextareaAutosize
    rows={3}
    className={`${className} ${bgColor} w-full px-3 py-2 text-sm rounded-xl appearance-none border border-gray-200 focus:border-sky-600 dark:border-gray-700 dark:text-gray-200 placeholder:dark:opacity-50`}
    value={value || ""}
    {...rest}
  />
);

const CustomDateInput = (props, ref) => <Input className="text-center" {...props} />;

export const DatePicker = ({ className = "", dayPickerProps = {}, ...rest }) => (
  <div className="relative rounded-lg overflow-visible">
    <DayPickerInput
      classNames={{
        container: `${className} relative`,
        overlay: "leading-none shadow-lg rounded-lg absolute bg-white dark:bg-gray-900 z-30",
      }}
      component={forwardRef(CustomDateInput)}
      dayPickerProps={dayPickerProps}
      {...rest}
    />
    {rest.disabled ? <div className="absolute inset-0 cursor-not-allowed bg-gray-400 bg-opacity-10"></div> : null}
  </div>
);

export const NativeDatePicker = ({ className = "", ...rest }) => (
  <input type="date" className={` text-center border rounded-lg px-2 h-[30px] ${className}`} {...rest} />
);

export const DateInput = ({ className = "", ...rest }) => <Input type="date" className={` ${className}`} {...rest} />;

export const FileSelector = ({ className = "", title = "add files", bold = false, bgColor = "", onChange, uploading, ...rest }) => {
  const [mouseIn, setMouseIn] = useState(false);
  const [uid] = useState(uuidv4());
  const borderStyle = "text-sky-600";
  const hoverBorderStyle = "text-sky-700";
  return (
    <div className={`${className} overflow-hidden relative`} onMouseEnter={() => setMouseIn(true)} onMouseLeave={() => setMouseIn(false)}>
      <label
        className={`relative font-semibold opacity-100
               ${rest.disabled ? "cursor-not-allowed opacity-50" : "cursor-pointer"}
               ${borderStyle}
               ${mouseIn ? hoverBorderStyle : ""}
               ${bgColor}
               `}
        htmlFor={uid}
      >
        {uploading ? "uploading..." : title}
      </label>

      <input id={uid} className="hidden" type="file" multiple required onChange={onChange} disabled={uploading || rest.disabled} {...rest} />
    </div>
  );
};

export const Field = ({ label, value, labelWidth = "w-auto", onChange, suffix, tips, placeholder = "", ...rest }) => (
  <div className="flex space-x-4 items-baseline">
    <label className={`capitalize ${labelWidth}`}>{label}: </label>

    <div className="flex-1">
      <div className="flex space-x-4 items-center">
        <Input placeholder={placeholder} className={suffix ? "" : "flex-1"} value={value || ""} onChange={(e) => onChange(e.target.value)} {...rest} />
        {suffix ? <div>{suffix}</div> : null}
      </div>
      {tips ? <div className="mt-1 ml-2 text-xs text-gray-500">{tips}</div> : null}
    </div>
  </div>
);
