import { createContext, useContext, useEffect, useState } from "react";
import { v4 } from "uuid";

export const ModalContext = createContext({});

export const useModals = (modal = {}) => {
  const { prepare, present, hide, clear } = useContext(ModalContext);
  const [modalId, setModalId] = useState(null);

  useEffect(() => {
    const id = prepare(modal);
    setModalId(id);

    return () => clear(id);
  }, []);

  return {
    present: (props) => present({ id: modalId, ...props }),
    hide: () => hide(modalId),
  };
};

const ModalProvider = ({ children }) => {
  const [modals, setModals] = useState([]);

  useEffect(() => {
    function keyboardHandler(e) {
      const showingModals = modals.filter((i) => i.show);
      if (showingModals.length === 0) return;
      const modal = showingModals[showingModals.length - 1];
      if (e.key === "Escape" && !modal.isBeingPresented) {
        hide(modal.id);
      }
    }

    document.addEventListener("keyup", keyboardHandler);

    return () => {
      document.removeEventListener("keyup", keyboardHandler);
    };
  }, [modals]);

  useEffect(() => {
    if (modals.filter((i) => i.show).length > 0) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [modals]);

  function prepare(modal) {
    const id = v4();
    setModals((prev) => [...prev, { ...modal, id, show: false }]);
    return id;
  }

  function present({ id, ...rest }) {
    const newModals = modals.map((i) => (i.id === id ? { ...i, ...rest, show: true } : i));
    setModals(newModals);
  }

  function hide(id) {
    const modal = modals.find((i) => i.id === id);
    console.log("hide modal", modal);
    if (modal && modal.onDismiss) modal.onDismiss();
    setModals((prev) => prev.map((i) => (i.id === id ? { ...i, show: false } : i)));
  }

  function clear(id) {
    setModals((prev) => prev.filter((i) => i.id !== id));
  }

  function scale(id) {
    const showingModals = modals.filter((i) => i.show);
    const showingModalsCount = showingModals.length;
    if (showingModalsCount <= 1) return 1;
    const index = showingModals.findIndex((i) => i.id === id) + 1;
    const x = 1 - (showingModalsCount - index) / showingModalsCount / 20;
    return x;
  }

  return (
    <ModalContext.Provider value={{ prepare, present, hide, clear }}>
      {children}

      {modals.map((modal) => {
        return (
          <div
            key={modal.id}
            className={`fixed inset-0 bg-gray-200 dark:bg-gray-900 z-50 animate-ease-5 flex flex-col lg:items-center md:left-52
                     ${modal.fullscreen ? "" : "overflow-auto sm:px-8"}
                     ${modal.isBeingPresented ? "bg-opacity-100" : "bg-opacity-90 dark:bg-opacity-90"}
                     ${modal.center ? "md:justify-center" : ""}
                     ${modal.show ? "opacity-100 visiable" : "opacity-0 invisible"}
                     `}
            onClick={() => {
              if (modal.isBeingPresented) return;
              hide(modal.id);
            }}
          >
            <div
              className={`w-full flex flex-col flex-1 relative
                        ${
                          modal.fullscreen
                            ? `overflow-auto bg-gray-100 dark:bg-gray-900 dark:bg-opacity-70
                              ${modal.show ? `opacity-1 mt-0` : `opacity-0 mt-12`}
                              `
                            : `
                              bg-gray-50 dark:bg-gray-800
                                ${modal.maxWidth || "max-w-4xl"}
                                ${modal.center ? "md:flex-none" : ""}
                                ${
                                  modal.show
                                    ? `opacity-1 ${modal.center ? "lg:-mt-10 mt-24" : "mt-12 xl:mt-24"}`
                                    : `opacity-0 mt-36 ${modal.center ? "lg:mt-0" : ""}`
                                }
                                ${modal.center ? "rounded-t-xl md:rounded-3xl" : "rounded-t-3xl"}
                                `
                        }
                        `}
              style={{
                top: modal.fullscreen ? 0 : `${(1 - scale(modal.id)) * 10})px`,
                transform: modal.fullscreen ? null : `scale(${scale(modal.id)})`,
                transition: `
                margin ${modal.center ? "0.4s" : "0.5s"} cubic-bezier(0.3, 2.1, 0.55, 1) 0s, opacity ${modal.center ? "0.4s" : "0.5s"} ease, transform ${
                  modal.center ? "0.4s" : "0.5s"
                } cubic-bezier(0.3, 2.1, 0.55, 1) 0s
                `,
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="absolute top-0 right-0 px-6 py-4 z-40 opacity-60 cursor-pointer hover:opacity-100 text-4xl" onClick={() => hide(modal.id)}>
                &times;
              </div>
              {modal.title ? (
                <div className="px-6 py-10 pb-0 md:px-10">
                  <h1>{modal.title}</h1>

                  {modal.subtitle ? <div className="text-lg opacity-60 mt-2">{modal.subtitle}</div> : null}
                </div>
              ) : null}

              <div
                className={`relative flex-1 flex flex-col
                              ${modal.fullscreen ? "" : "p-6 md:p-10 md:pt-6"}
                        `}
                style={{ minHeight: 200 }}
              >
                {modal.show ? modal.children : null}
              </div>
            </div>
          </div>
        );
      })}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
