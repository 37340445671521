import { useModals } from "ModalProvider";
import Page from "components/Page";
import SearchBar from "components/SearchBar";
import CharlesButton from "components/charles/base";
import { gql, useQuery } from "@apollo/client";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { Link } from "react-router-dom";
import RetailerForm from "./RetailerForm";

const FETCH_RETAILER_CUSTOMERS = gql`
  query FETCH_RETAILER_CUSTOMERS {
    retailerCustomers {
      id
      email
    }
  }
`;

const RetailerList = () => {
  const modal = useModals();

  const { loading, error, data } = useQuery(FETCH_RETAILER_CUSTOMERS);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  function showCreateView() {
    modal.present({
      title: "Create Retailer",
      children: <RetailerForm hide={modal.hide} />,
      isBeingPresented: true,
    });
  }

  return (
    <Page title="Retailers">
      <div className="card m-6 p-6">
        <div className="flex items-center justify-between space-x-4">
          <div className="flex-1">
            <SearchBar placeholder="Search by email or username." />
          </div>
          <CharlesButton onClick={showCreateView}>+ Create Retailer</CharlesButton>
        </div>

        <div className="mt-4">
          <table>
            <thead>
              <tr>
                <th>Retailer Email</th>
              </tr>
            </thead>
            <tbody>
              {data.retailerCustomers.map((retailer) => (
                <tr key={retailer.id} className="border-y dark:border-gray-700">
                  <td>
                    <Link to={retailer.id}>{retailer.email}</Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Page>
  );
};

export default RetailerList;
