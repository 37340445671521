import { useQuery } from '@apollo/client';
import Errors from 'components/Errors';
import Page from 'components/Page';
import Spinner from 'components/Spinner';
import { useParams } from 'react-router-dom';
import { FETCH_QC_INLINE_REPORT, metaTypes } from './Form';

const InlineReportPreview = ({}) => {
   const { id } = useParams();
   const { loading, error, data } = useQuery(FETCH_QC_INLINE_REPORT, {
      variables: { id },
   });

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const report = data.qcInlineReport;

   return (
      <Page
         title={`${report.productLine.name} ( ${report.finishedQty} / ${report.batchQty} )`}
         backTo="/qc/inline"
      >
         <div className="container max-w-7xl mx-auto px-6 xl:px-10 py-8 xl:py-12">
            <div className="space-y-4 text-lg">
               <div>
                  <label htmlFor="">Factory 工厂: </label>
                  {report.factory.name}
               </div>

               <div>
                  <label htmlFor="">Date 日期: </label>
                  {report.date}
               </div>

               <div className="flex  space-x-3">
                  <label htmlFor="">Batch Qty 生产数量: </label>
                  <span>{report.batchQty}</span>
               </div>
               <div className="flex  space-x-3">
                  <label htmlFor="">Finish Qty 已完成数量: </label>
                  <span>{report.finishedQty}</span>
               </div>
               <div className="flex  space-x-3">
                  <label htmlFor="">Check Qty 检查数量: </label>
                  <span>{report.checkQty}</span>
               </div>
            </div>

            <div className="pt-8 space-y-8">
               {Object.entries(metaTypes).map(([type, meta]) => (
                  <div key={type} className="">
                     <h4 className="capitalize">{meta.name}</h4>
                     <div className="mt-4 space-y-6">
                        {report.images
                           .filter((i) => i.type === type)
                           .map(({ id, src }) => (
                              <a
                                 key={id}
                                 className="w-full block"
                                 href={src}
                                 target="_blank"
                                 title="Open origin image"
                                 rel="noopener noreferrer"
                              >
                                 <img
                                    className="w-full bg-gray-700"
                                    src={`${src}?imageView2/2/w/1280`}
                                    alt="meta"
                                 />
                              </a>
                           ))}
                     </div>
                  </div>
               ))}
            </div>

            <div className="pt-16">
               <h2>Issues</h2>
               <div className="mt-4 space-y-6">
                  {report.issues.map((issue) => (
                     <div className="border-t py-6">
                        <div className="flex justify-between">
                           <div className="text-lg mb-2">
                              <label htmlFor="">
                                 {issue.defectCode.code}:{' '}
                                 {issue.defectCode.description}
                              </label>
                              <span className="pl-4">
                                 Qty数量: {issue.qty}, 百分比:{' '}
                                 {((issue.qty * 100) / report.checkQty).toFixed(
                                    2
                                 )}
                                 %
                              </span>
                           </div>
                        </div>
                        {issue.images.length > 0 ? (
                           <div className="mt-4 grid grid-cols-1 items-end gap-6 lg:grid-cols-2 2xl:grid-cols-3">
                              {issue.images.map(({ id, src }) => (
                                 <a
                                    key={id}
                                    className="w-full block"
                                    href={src}
                                    target="_blank"
                                    title="Open origin image"
                                    rel="noopener noreferrer"
                                 >
                                    <img
                                       className="w-full bg-gray-700"
                                       src={`${src}?imageView2/2/w/800`}
                                       alt="meta"
                                    />
                                 </a>
                              ))}
                           </div>
                        ) : (
                           <div>
                              <label htmlFor="">
                                 No images for this issue.
                              </label>
                           </div>
                        )}

                        <div className="mt-4">
                           <label htmlFor="">Remark: </label>
                           <div className="whitespace-pre-wrap">
                              {issue.remark}
                           </div>
                        </div>
                     </div>
                  ))}
               </div>
            </div>

            <div className="pt-16 pb-16">
               <h2>Remark</h2>
               <div className="whitespace-pre-wrap mt-3 text-lg">
                  {report.remark}
               </div>
            </div>
         </div>
      </Page>
   );
};

export default InlineReportPreview;
