import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import { DatePicker, Input, Select, Switcher, Text } from "components/Form";
import { Alert } from "components/Toast";
import { useModals } from "ModalProvider";
import { AppContext } from "App";
import React, { createContext, useContext, useState } from "react";
import { BsCheckCircleFill, BsPencil, BsPencilSquare } from "react-icons/bs";
import http from "utils/http";
import { UPDATE_PRODUCTION_PLAN_STATE, SAVE_PRODUCTION_PLAN, COMPUTE_PRODUCTION_PLAN, FETCH_PRICE_LIST_DETAIL } from "./graphql";
import { GENERATE_DOCUMENT } from "graphql/mutations";
import odooIcon from "assets/odoo-icon.svg";
import { hasPermission } from "./permissions";
import CharlesButton, { CharlesButtonWithArrow } from "components/charles/base";
import ChangeStartDateView from "./ChangeStartDateView";
import OdooPickingTypeSelector from "components/OdooPickingTypeSelector";
import OdooStockLocationSelector from "components/OdooStockLocationSelector";
import OdooWarehouseSelector from "components/OdooWarehouseSelector";
import OdooPaymentTermsSelector from "components/OdooPaymentTermsSelector";
import { InlineSpinner } from "components/Spinner";
import Page from "components/Page";
import { ProductionPlanStatusChain } from "components/Status";
import CreateAdditionPlanView from "./CreateAdditionPlanView";
import { Link, useNavigate } from "react-router-dom";
import CancelPlanView from "./CancelPlanView";
import RejectPlanView from "./RejectPlanView";
import SyncPlanToOdooView from "./SyncPlanToOdooView";
import ReduceQtyView from "./ReduceQtyView";
import DuplicatePlanView from "./DuplicatePlanView";
import OdooTaxSelector from "components/OdooTaxSelector";
import OdooDeliveryMethodSelector from "components/OdooDeliveryMethodSelector";
import OdooIncotermsSelector from "components/OdooIncotermsSelector";
import WisPriceListSelector from "components/WisPriceListSelector";
import { OdooContext } from "OdooProvider";
import ContainerizationView from "components/ContainerizationView";
import ProductionsView from "./ProductionsView";
import ProductProductionInfoView from "./ProductProductionInfoView";
import ProductionLines from "./ProductionLines";
import CustomersSelector from "./CustomersSelector";
import AdditionPlansView from "./AdditionPlansView";
import CombinedPlanSummary from "./CombinedPlanSummary";
import TryRecomputeView from "./TryRecomputeView";
import { parseError } from "apollo";
import AboutOSView from "./docs/AboutOSView";

function computeLine(line) {
  console.log("computeLine", line);
  let totalCbm = 0;
  const qty = line.qty - line.reduceQty;
  const salesPrice = parseFloat(line.salesPrice);
  let totalPrice = isNaN(salesPrice) ? 0 : salesPrice * qty - (line.discountPercentage / 100) * salesPrice * qty;
  let validQty = false;
  if (line.product && line.product.quantityPerCarton) {
    if (line.product.quantityPerCarton) {
      validQty = qty % line.product.quantityPerCarton === 0;
      totalCbm = (qty * line.product.cbm) / line.product.quantityPerCarton;
    }
  } else {
    validQty = true;
  }
  return { ...line, validQty, totalCbm, totalPrice };
}

const ProductionContext = createContext({});

const ProductionPlanForm = ({ originalPlan, disabled }) => {
  const { user } = useContext(AppContext);
  const { odooUrl } = useContext(OdooContext);
  const [startDate, setStartDate] = useState(new Date(originalPlan.startDate));
  const [wisCustomerId, setWisCustomerId] = useState(originalPlan.wisCustomer ? originalPlan.wisCustomer.id : null);
  const [orderType, setOrderType] = useState(originalPlan.orderType);
  const [wisPriceListId, setWisPriceListId] = useState(originalPlan.wisPriceList ? originalPlan.wisPriceList.id : null);

  const [odooTransferOperationTypeId, setOdooTransferOperationTypeId] = useState(originalPlan.odooTransferOperationTypeId);
  const [odooTransferFromLocationId, setOdooTransferFromLocationId] = useState(originalPlan.odooTransferFromLocationId);
  const [odooTransferToLocationId, setOdooTransferToLocationId] = useState(originalPlan.odooTransferToLocationId);
  const [odooSalesOrderWarehouseId, setOdooSalesOrderWarehouseId] = useState(originalPlan.odooSalesOrderWarehouseId);
  const [odooSalesOrderPriceListId, setOdooSalesOrderPriceListId] = useState(originalPlan.odooSalesOrderPriceListId);
  const [odooSalesOrderPaymentTermsId, setOdooSalesOrderPaymentTermsId] = useState(originalPlan.odooSalesOrderPaymentTermsId);
  const [odooTaxId, setOdooTaxId] = useState(originalPlan.odooTaxId);
  const [odooXDeliverymethod, setOdooXDeliverymethod] = useState(originalPlan.odooXDeliverymethod);
  const [odooIncoterm, setOdooIncoterm] = useState(originalPlan.odooIncoterm);
  const [odooSalesOrderClientOrderRef, setOdooSalesOrderClientOrderRef] = useState(originalPlan.odooSalesOrderClientOrderRef);
  const [autoSendToOdoo, setAutoSendToOdoo] = useState(originalPlan.autoSendToOdoo);

  const [shippingCost, setShippingCost] = useState(originalPlan.shippingCost);
  const [currency, setCurrency] = useState(originalPlan.currency);
  const [description, setDescription] = useState(originalPlan.description);
  const navigate = useNavigate();

  const [lazyFetchWisPriceList, lazyFetchWisPriceListRes] = useLazyQuery(FETCH_PRICE_LIST_DETAIL);
  const priceListQuery = useQuery(FETCH_PRICE_LIST_DETAIL, {
    variables: { id: wisPriceListId },
    skip: wisPriceListId === null,
  });

  const [needToRecompute, setNeedToRecompute] = useState(false);

  const [lines, setLines] = useState(originalPlan.lines);

  const [computeQuery, computeQueryRes] = useMutation(COMPUTE_PRODUCTION_PLAN, {
    variables: { id: originalPlan.id },
    onError(error) {
      Alert("error", error.message);
    },
  });

  async function compute() {
    return new Promise((onCompleted, onError) => computeQuery({ onCompleted, onError }));
  }

  const prices = lazyFetchWisPriceList.data
    ? lazyFetchWisPriceList.data.salesPrices
    : priceListQuery.data && priceListQuery.data.salesPrices
    ? priceListQuery.data.salesPrices
    : [];

  const isLoadingPriceList = lazyFetchWisPriceListRes.loading || priceListQuery.loading;

  console.log("isLoadingPriceList", isLoadingPriceList);
  console.log("prices", prices);

  const [isComputing, setIsComputing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isRequestingToConfirm, setIsRequestingToConfirm] = useState(false);
  const [isConfirmingTime, setIsConfirmingTime] = useState(false);
  const [isConfirmingCost, setIsConfirmingCost] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const modal = useModals();
  const reComputeModal = useModals();
  const additionalPlanModal = useModals();
  const syncToOdooModal = useModals();
  const reduceQtyModal = useModals();
  const bomModal = useModals();

  async function onChangePriceList(pricelist) {
    setWisPriceListId(pricelist ? pricelist.id : null);
    setLines((prev) => prev.map((i) => ({ ...i, salesPrice: 0 })));
    setCurrency(pricelist ? pricelist.currency : "USD");
    if (!pricelist) return false;
    try {
      const res = await new Promise((onCompleted, onError) =>
        lazyFetchWisPriceList({
          variables: { id: pricelist.id },
          onCompleted,
          onError,
        })
      );

      if (lines.length > 0) {
        if (window.confirm("Do you want to apply the new price list to the current product?")) {
          const newLines = lines.map((line) => {
            const foundPrice = res.salesPrices.find((i) => i.product.id === line.product.id);
            const salesPrice = foundPrice ? foundPrice.price : 0;
            return { ...line, salesPrice };
          });
          setLines(newLines);
        }
      }
    } catch (error) {
      Alert("error", error.message);
    }
  }

  async function computeHandler({ alert = true }) {
    if (validLines.filter((i) => i.qty > 0).length === 0) return Alert("error", "No product selected. Please choose a product and set its qty first.");

    setIsComputing(true);
    setNeedToRecompute(false);
    setShowProductions(false);

    try {
      await savePlan();
      const res = await compute();
      setLines(res.computeProductionPlan.productionPlan.lines);
      if (alert) Alert("success", "Compute finish, auto saved.");
      console.log("compute res", res);
      setIsComputing(false);
    } catch (error) {
      Alert("error", parseError(error));
      setIsComputing(false);
    }
  }

  const [showProductions, setShowProductions] = useState(false);

  const [saveProductionPlan, saveProductionPlanRes] = useMutation(SAVE_PRODUCTION_PLAN);

  const [updatePlan] = useMutation(UPDATE_PRODUCTION_PLAN_STATE, {
    onError(error) {
      Alert("error", error.message);
    },
  });

  const [generateDocument] = useMutation(GENERATE_DOCUMENT, {
    onError: (error) => Alert("error", error.message),
  });

  async function requestToConfirm() {
    if (needToRecompute) {
      Alert("error", 'You have changed products or start date for this plan, please "Compute Ready Date" before request to confirm.');
    } else {
      setIsRequestingToConfirm(true);
      try {
        await savePlan();
        await updatePlan({
          variables: { id: originalPlan.id, action: "REQUEST_CONFIRM" },
        });
        Alert("success", "Request Sent.");
      } catch (error) {
        Alert("error", parseError(error));
      }
      setIsRequestingToConfirm(false);
    }
  }

  async function confirmTimeHandler() {
    setIsConfirmingTime(true);
    try {
      await updatePlan({
        variables: { id: originalPlan.id, action: "CONFIRM_TIME" },
      });
    } catch (error) {
      Alert("error", error.message);
    }
    Alert("success", "Time Confirmed.");
    setIsConfirmingTime(false);
  }

  async function confirmCostHandler() {
    setIsConfirmingCost(true);
    try {
      await updatePlan({
        variables: { id: originalPlan.id, action: "CONFIRM_COST" },
      });
    } catch (error) {
      Alert("error", error.message);
    }
    Alert("success", "Cost Confirmed.");
    setIsConfirmingCost(false);
  }

  async function downoloadPdf() {
    if (needToRecompute) {
      Alert("error", 'You have changed products or start date for this plan, please "Compute Ready Date" before request to confirm.');
      return;
    }
    setDownloading(true);
    try {
      await savePlan();
      let res = await generateDocument({
        variables: {
          docType: "production_plan",
          name: originalPlan.name,
          data: JSON.stringify({ plan_id: originalPlan.id }, "", 4),
        },
      });
      res = await http.get(`${process.env.REACT_APP_SERVER_BASE_URL}generated-document/${res.data.generateDocument.document.id}/?pdf=1`, {
        responseType: "blob",
        withCredentials: true,
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${originalPlan.name}_${new Date().toLocaleString()}.pdf`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      Alert("error", parseError(error));
    }

    setDownloading(false);
  }

  function changePlanStartDate() {
    modal.present({
      title: "Change Start Date",
      center: true,
      children: (
        <ChangeStartDateView
          plan={originalPlan}
          onCompleted={(plan) => {
            modal.hide();
            navigate(`/order-scheduler/plans/${plan.id}`);
          }}
        />
      ),
    });
  }

  function cancelPlan() {
    modal.present({
      title: "Cancel Plan",
      center: true,
      maxWidth: "max-w-4xl",
      children: <CancelPlanView plan={originalPlan} onCompleted={modal.hide} />,
    });
  }

  function rejectPlan() {
    modal.present({
      title: "Reject Plan",
      center: true,
      maxWidth: "max-w-4xl",
      children: <RejectPlanView plan={originalPlan} onCompleted={modal.hide} />,
    });
  }

  async function saveHandler() {
    setIsSaving(true);
    try {
      await savePlan();
      Alert("success", "Plan Saved.");
    } catch (error) {
      Alert("error", parseError(error));
    }
    setIsSaving(false);
  }

  function savePlan(values = {}) {
    const variables = {
      id: originalPlan.id,
      startDate,
      wisCustomerId,
      wisPriceListId,
      shippingCost,
      currency,
      lines: validLines.map((i) => ({
        name: i.product.name,
        productOdooId: i.product.odooId,
        qty: i.qty,
        reduceQty: i.reduceQty,
        salesPrice: i.salesPrice,
        discountPercentage: i.discountPercentage,
      })),
      shippingCost,
      orderType,
      odooTransferOperationTypeId,
      odooTransferFromLocationId,
      odooTransferToLocationId,
      odooSalesOrderPriceListId,
      odooSalesOrderPaymentTermsId,
      odooSalesOrderWarehouseId,
      odooTaxId,
      odooSalesOrderClientOrderRef,
      odooIncoterm,
      odooXDeliverymethod,
      autoSendToOdoo,
      description,
      ...values,
    };
    return new Promise((onCompleted, onError) => saveProductionPlan({ variables, onCompleted, onError }));
  }

  function changeStartDate(day) {
    setStartDate(day);
    setNeedToRecompute(true);
  }

  function tryRecompute() {
    reComputeModal.present({
      title: "Recompute",
      maxWidth: "max-w-4xl",
      center: true,
      children: (
        <TryRecomputeView
          planId={originalPlan.id}
          onCompleted={(res) => {
            navigate(`/order-scheduler/plans/${res.recomputeProductionPlan.plan.id}`);
          }}
        />
      ),
    });
  }

  function onChangeLine(id, field, value) {
    const newLines = lines.map((line) => (line.id === id ? { ...line, [field]: value, totalCost: 0 } : line));
    setLines(newLines);
  }

  function onChangeLineProduct(id, product) {
    const newLines = lines.map((line) => {
      const foundPrice = prices.find((i) => i.product.id === product.id);
      return line.id === id
        ? {
            ...line,
            product,
            salesPrice: foundPrice ? foundPrice.price : 0,
            discountPercentage: foundPrice ? foundPrice.discountPercentage : 0,
          }
        : line;
    });
    setLines(newLines);
  }

  function removeLine(id) {
    const newLines = lines.filter((prevLine) => prevLine.id !== id);
    setLines(newLines);
  }

  function tryAddAdditionalPlan() {
    additionalPlanModal.present({
      title: "Addition Plan",
      center: true,
      maxWidth: "max-w-xl",
      children: (
        <CreateAdditionPlanView
          originalPlan={originalPlan}
          complete={(newPlan) => {
            additionalPlanModal.hide();
            navigate(`/order-scheduler/plans/${newPlan.id}`);
          }}
        />
      ),
    });
  }

  function tryDuplicate() {
    modal.present({
      title: "Duplicate Plan",
      center: true,
      maxWidth: "max-w-2xl",
      children: (
        <DuplicatePlanView
          originalPlan={originalPlan}
          complete={(res) => {
            modal.hide();
            navigate(`/order-scheduler/plans/${res.duplicateProductionPlan.productionPlan.id}`);
          }}
        />
      ),
    });
  }

  function trySyncToOdoo() {
    syncToOdooModal.present({
      title: "Sync to Odoo",
      subtitle: "Products Summary",
      maxWidth: "max-w-6xl",
      children: <SyncPlanToOdooView planId={originalPlan.id} odooObject={originalPlan.odooObject} complete={syncToOdooModal.hide} />,
    });
  }

  function reduceQty() {
    reduceQtyModal.present({
      title: "Reduce QTY",
      maxWidth: "max-w-6xl",
      children: (
        <ReduceQtyView
          complete={(newLines) => {
            setLines(newLines);
            reduceQtyModal.hide();
          }}
          initialLines={computedLines}
          originalPlan={originalPlan}
        />
      ),
    });
  }

  function showBom(product) {
    bomModal.present({
      title: product.name,
      subtitle: "Production Info",
      maxWidth: "max-w-6xl",
      children: <ProductProductionInfoView id={product.id} />,
    });
  }

  function editPlanName() {
    const name = window.prompt("Please enter the new name", originalPlan.name);
    if (name) {
      saveProductionPlan({
        variables: { id: originalPlan.id, name },
        onCompleted() {
          Alert("success", "Name updated.");
        },
        onError(error) {
          Alert("error", error.message);
        },
      });
    }
  }

  let computedLines = [];
  let validLines = [];
  if (originalPlan.state === "DRAFT") {
    computedLines = lines.map(computeLine).map((i) => {
      // check price state
      const foundPrice = prices.find((price) => price.product.id === i.product?.id);
      return { ...i, priceState: foundPrice?.state };
    });
    validLines = computedLines.filter((i) => i.validQty && i.qty > 0 && i.product);
  } else {
    computedLines = originalPlan.lines.map((i) => ({ ...i, qty: i.finalQty }));
    validLines = computedLines.filter((i) => i.qty > 0 && i.product);
  }

  console.log("computedLines", computedLines);

  const totalQty = computedLines.reduce((res, line) => res + line.qty, 0);
  const totalCbm = computedLines.reduce((res, line) => res + line.totalCbm, 0);
  const productCost = computedLines.reduce((res, line) => res + line.totalCost, 0);
  const totalCost = productCost + shippingCost;
  const totalPrice = computedLines.reduce((res, line) => res + line.totalPrice, 0);

  const hasInvalidQty = computedLines.filter((i) => i.validQty === false).length > 0;

  return (
    <ProductionContext.Provider value={{ originalPlan }}>
      <Page
        title={
          <div className="flex items-baseline space-x-2">
            {originalPlan.parent ? (
              <div>
                <Link className="font-semibold" to={`/order-scheduler/plans/${originalPlan.parent.id}`}>
                  {originalPlan.parent.name}
                </Link>{" "}
                / {originalPlan.name}
              </div>
            ) : (
              <span>{originalPlan.name}</span>
            )}
            <CharlesButton className="text-sm" onClick={editPlanName}>
              <BsPencilSquare />
            </CharlesButton>
          </div>
        }
        backTo={`./..`}
        rightButtons={
          <div className="flex space-x-6">
            {user.email === "charles@waboba.com" ? (
              <a href={`${process.env.REACT_APP_SERVER_ADMIN_URL}product/productionplan/${originalPlan.id}`} target="_blank" rel="noreferer">
                Admin
              </a>
            ) : null}
            <CharlesButton disabled={isComputing || validLines.length === 0} loading={downloading} onClick={downoloadPdf}>
              Download PDF
            </CharlesButton>

            <CharlesButton onClick={tryDuplicate} disabled={isComputing || isRequestingToConfirm}>
              Duplicate
            </CharlesButton>

            <CharlesButton onClick={saveHandler} loading={isSaving} disabled={isComputing || isRequestingToConfirm}>
              Save
            </CharlesButton>
          </div>
        }
      >
        <div className="p-6 flex justify-between items-center">
          <ProductionPlanStatusChain status={originalPlan.state} />

          <div>
            {needToRecompute ? (
              <div className="text-xs font-semibold text-orange-600">
                You have changed products or start date for this plan, click the "Compute Ready Date" to get an updated ready date..
              </div>
            ) : null}
          </div>
        </div>
        <div className="grid grid-cols-12 gap-6 p-6 pt-0">
          <div className="col-span-9 space-y-4 card overflow-visible">
            <h5>Base Info</h5>
            <div className="flex space-x-8 items-center">
              <div className="flex space-x-4 items-center">
                <label>Start Date: </label>
                <DatePicker disabled={originalPlan.state !== "DRAFT" || isComputing} value={startDate} onDayChange={changeStartDate} />
                {originalPlan.state === "DRAFT" || originalPlan.state === "CANCELED" ? null : (
                  <CharlesButton onClick={changePlanStartDate} disabled={originalPlan.state === "HANDLED"}>
                    Change Start Date
                  </CharlesButton>
                )}
              </div>
            </div>

            <div className="col-span-12 space-y-4">
              <div className="flex space-x-4 items-center">
                <label>Customer: </label>
                <CustomersSelector
                  disabled={disabled || originalPlan.parent}
                  value={wisCustomerId}
                  onChange={(customer) => {
                    setWisCustomerId(customer.id);
                    setOdooSalesOrderPriceListId(customer.lastProductionPlan ? customer.lastProductionPlan.odooSalesOrderPriceListId : "0");
                    setOdooSalesOrderPaymentTermsId(customer.lastProductionPlan ? customer.lastProductionPlan.odooSalesOrderPaymentTermsId : "0");
                    setOdooTaxId(customer.lastProductionPlan ? customer.lastProductionPlan.odooTaxId : "0");
                    setOdooIncoterm(customer.lastProductionPlan ? customer.lastProductionPlan.odooIncoterm : "0");
                    setOdooXDeliverymethod(customer.lastProductionPlan ? customer.lastProductionPlan.odooXDeliverymethod : "0");
                    setWisPriceListId(
                      customer.lastProductionPlan && customer.lastProductionPlan.wisPriceList ? customer.lastProductionPlan.wisPriceList.id : null
                    );
                    setLines([]);
                  }}
                />
              </div>

              <div className="space-y-4">
                <div className="flex space-x-8">
                  <div>
                    <label className="pr-3">Type: </label>
                    <Select disabled={disabled || originalPlan.parent} value={orderType} onChange={(e) => setOrderType(e.target.value)}>
                      <option value="INTERNAL_TRANSFER">Internal Transfer</option>
                      <option value="FOB_ORDER">FOB Order</option>
                    </Select>
                  </div>
                  {orderType === "INTERNAL_TRANSFER" ? (
                    <div>
                      <label className="pr-3">Operation Type: </label>
                      <OdooPickingTypeSelector
                        disabled={disabled || originalPlan.parent}
                        value={odooTransferOperationTypeId}
                        onSelect={setOdooTransferOperationTypeId}
                      />
                    </div>
                  ) : orderType === "FOB_ORDER" ? (
                    <>
                      <div>
                        <label className="pr-3">Warehouse: </label>
                        <OdooWarehouseSelector
                          disabled={disabled || originalPlan.parent}
                          value={odooSalesOrderWarehouseId}
                          onSelect={setOdooSalesOrderWarehouseId}
                        />
                      </div>
                      <div>
                        <label className="pr-3">Customer Ref: </label>
                        <Input
                          disabled={disabled || originalPlan.parent}
                          value={odooSalesOrderClientOrderRef || ""}
                          onChange={(e) => setOdooSalesOrderClientOrderRef(e.target.value)}
                        />
                      </div>
                    </>
                  ) : null}
                </div>

                <div className="space-y-4">
                  {orderType === "INTERNAL_TRANSFER" ? (
                    <div className="space-x-3">
                      <label htmlFor="">Transfer from</label>
                      <OdooStockLocationSelector
                        disabled={disabled || originalPlan.parent}
                        value={odooTransferFromLocationId}
                        onSelect={setOdooTransferFromLocationId}
                      />
                      <label htmlFor="">to </label>
                      <OdooStockLocationSelector
                        disabled={disabled || originalPlan.parent}
                        value={odooTransferToLocationId}
                        onSelect={setOdooTransferToLocationId}
                      />
                    </div>
                  ) : orderType === "FOB_ORDER" ? (
                    <div className="space-x-8 flex">
                      <div className="flex items-baseline">
                        <label className="pr-3">Price List: </label>
                        <div>
                          <div className="flex space-x-3 items-center">
                            <WisPriceListSelector disabled={disabled || originalPlan.parent} value={wisPriceListId} onChange={onChangePriceList} />
                            {isLoadingPriceList ? <InlineSpinner text={null} size={16} /> : null}
                          </div>
                          <div className="text-xs mx-1 mt-1 opacity-70">
                            If price list is not set, when this plan is created in Odoo, the price list of the customer will be used. And the currency of SO
                            will be the same as the price list from Odoo.
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                {orderType === "FOB_ORDER" ? (
                  <>
                    <div>
                      <label className="pr-3">Payment Terms: </label>
                      <OdooPaymentTermsSelector
                        disabled={disabled || originalPlan.parent}
                        value={odooSalesOrderPaymentTermsId}
                        onSelect={setOdooSalesOrderPaymentTermsId}
                      />
                    </div>
                    <div>
                      <label className="pr-3">Tax: </label>
                      <OdooTaxSelector disabled={disabled || originalPlan.parent} value={odooTaxId} onSelect={setOdooTaxId} />
                    </div>

                    <div className="flex space-x-8 items-center">
                      <div>
                        <label className="pr-3">Delivery Method: </label>
                        <OdooDeliveryMethodSelector disabled={disabled || originalPlan.parent} value={odooXDeliverymethod} onSelect={setOdooXDeliverymethod} />
                      </div>
                      <div>
                        <label className="pr-3">Incoterm: </label>
                        <OdooIncotermsSelector disabled={disabled || originalPlan.parent} value={odooIncoterm} onSelect={setOdooIncoterm} />
                      </div>
                    </div>
                  </>
                ) : null}

                <div className="">
                  <div>
                    <label className="pr-3">Note: </label>
                  </div>
                  <Text className="mt-2" placeholder="Remark" value={description} onChange={(e) => setDescription(e.target.value)} />
                  <div className="opacity-70 ml-1">This note will be added to odoo order.</div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-3 space-y-6 flex flex-col">
            {originalPlan.state === "CANCELED" ? null : (
              <div className="card">
                <h5>Actions</h5>

                {originalPlan.state === "DRAFT" ? (
                  <>
                    <div className="mt-4 text-sm">
                      <CharlesButton
                        onClick={requestToConfirm}
                        loading={isRequestingToConfirm}
                        disabled={isComputing || isSaving || validLines.length === 0 || originalPlan.readyDate === null}
                      >
                        Request to Confirm
                      </CharlesButton>
                    </div>
                  </>
                ) : (
                  <div className="space-y-2 mt-4 text-sm">
                    {hasPermission(user, "CONFIRM_TIME") && ["WAITING_TO_CONFIRM", "PLANNING"].includes(originalPlan.state) ? (
                      <CharlesButton onClick={tryRecompute}>Recompute Plan</CharlesButton>
                    ) : null}
                    {originalPlan.confirmTimeAt ? (
                      <div className="text-green-600 flex space-x-2 items-center">
                        <span>Confirmed Ready Date</span>
                        <BsCheckCircleFill />
                      </div>
                    ) : (
                      <CharlesButton
                        disabled={!hasPermission(user, "CONFIRM_TIME") || isComputing || isSaving || isRequestingToConfirm}
                        loading={isConfirmingTime}
                        onClick={confirmTimeHandler}
                      >
                        Confirm Time
                      </CharlesButton>
                    )}

                    {originalPlan.orderType === "INTERNAL_TRANSFER" ? (
                      <>
                        {originalPlan.confirmCostAt ? (
                          <div className="text-green-600 flex space-x-2 items-center">
                            <span>Confirmed Cost</span>
                            <BsCheckCircleFill />
                          </div>
                        ) : (
                          <CharlesButton
                            disabled={!originalPlan.confirmTimeAt || !hasPermission(user, "CONFIRM_COST") || saveProductionPlanRes.loading}
                            loading={isConfirmingCost}
                            onClick={confirmCostHandler}
                          >
                            Confirm Cost
                          </CharlesButton>
                        )}
                      </>
                    ) : null}

                    <CharlesButton onClick={rejectPlan} disabled={originalPlan.state === "HANDLED"}>
                      Reject
                    </CharlesButton>
                  </div>
                )}

                {originalPlan.state === "CANCELED" ? null : (
                  <div className="mt-2 text-sm">
                    <CharlesButton danger onClick={cancelPlan} disabled={originalPlan.state === "HANDLED"}>
                      Cancel Plan
                    </CharlesButton>
                  </div>
                )}
              </div>
            )}

            <div className="card">
              <h5 className="text-purple-600">Odoo</h5>

              {orderType === "INTERNAL_TRANSFER" && (
                <div className="mt-2 font-semibold text-orange-600">
                  Note this plan is an internal transfer, when this plan is confirmed and auto created in odoo, the `Schdeudle Date` will NOT be set as the
                  `plan ready date`, you might need to set it manually after you `MARK AS TO DO` in Odoo.
                </div>
              )}

              {originalPlan.parent ? (
                <div className="opacity-70 text-xs mt-2">
                  This is an additional plan, if you want to update the Odoo order, please go to the original plan and Sync manually.
                  <br />
                  Note that for additional plan, if the products are in the original plan, the qty will be added to the original plan, the price will be the{" "}
                  <b>same</b> as the original plan when you sync to Odoo. If you want to update the price, please do it in Odoo before or after you sync.
                </div>
              ) : (
                <>
                  <div className="flex space-x-4 items-center mt-2">
                    <label>Auto Sync to Odoo: </label>
                    <Switcher disabled={disabled} isOn={autoSendToOdoo} onChange={() => setAutoSendToOdoo((prev) => !prev)} />
                  </div>
                  <div className="opacity-70 text-xs mt-1">
                    If you turn on this setting, WIS will auto create or update Sales Order or Internal Transfer to Odoo once we got time and cost confirmed. If
                    you want to update this plan, you can reject this plan first.
                  </div>

                  {originalPlan.state === "HANDLED" ? (
                    <div className="mt-4">
                      <CharlesButton className="text-sm" onClick={trySyncToOdoo}>
                        Sync to Odoo
                      </CharlesButton>
                      <div className="opacity-70 text-xs mt-1">
                        This feature is enable only if this plan is handled. When you add additional plans or reduce qty from the original plan, you might need
                        to sync the updated products to the current odoo sales order or internal transfer.
                      </div>
                    </div>
                  ) : null}
                </>
              )}

              {originalPlan.odooObject ? (
                <div className="mt-4">
                  <a
                    className="flex space-x-2 items-center"
                    target="_blank"
                    href={
                      originalPlan.odooObject.objectType === "TRANSFER"
                        ? `${odooUrl}/web#id=${originalPlan.odooObject.objectId}&action=350&active_id=17&model=stock.picking&view_type=form&menu_id=222`
                        : `${odooUrl}/web#id=${originalPlan.odooObject.objectId}&menu_id=408&cids=1&action=312&model=sale.order&view_type=form`
                    }
                    rel="noreferer"
                  >
                    <img style={{ height: 16 }} src={odooIcon} alt="odoo" />
                    <span>{originalPlan.odooObject.objectName}</span>
                  </a>
                </div>
              ) : null}
            </div>
          </div>

          <div className="col-span-12">
            <div className="card">
              <h5>Production Info</h5>

              {wisCustomerId ? (
                <div>
                  <ProductionLines
                    originalPlan={originalPlan}
                    computedLines={computedLines}
                    setLines={setLines}
                    onChangeLine={onChangeLine}
                    onChangeLineProduct={onChangeLineProduct}
                    removeLine={removeLine}
                    needToRecompute={needToRecompute}
                    setNeedToRecompute={setNeedToRecompute}
                    disabled={disabled}
                    isComputing={isComputing}
                    showBom={showBom}
                    reduceQty={reduceQty}
                    shippingCost={shippingCost}
                    setShippingCost={setShippingCost}
                    orderType={orderType}
                    currency={currency}
                    wisCustomerId={wisCustomerId}
                    productCost={productCost}
                    totalCbm={totalCbm}
                    totalQty={totalQty}
                    totalCost={totalCost}
                    totalPrice={totalPrice}
                    prices={prices}
                    compute={compute}
                    isLoadingPriceList={isLoadingPriceList}
                  />

                  {hasInvalidQty ? (
                    <div className="text-red-600 mt-4">
                      You have invalid qty, each product should has a qty that could be used as a whole package. You could split the lines in Odoo SO after.
                    </div>
                  ) : null}

                  <div className="text-right opacity-70 mt-2">
                    The estimated ready date is computed base on the production capacity provided by suppliers. The actual ready date might be +/- 7 days.
                    <br />
                    Since our inventory is dynamic, the estimated ready date might change until you request to confirm.
                  </div>

                  {computeQueryRes.error ? (
                    <div className="mt-4">
                      <Errors error={computeQueryRes.error} />
                    </div>
                  ) : null}

                  {originalPlan.state === "DRAFT" ? (
                    <div className="mt-6">
                      <CharlesButton primary className="mt-2" onClick={computeHandler} loading={isComputing} disabled={isSaving || isRequestingToConfirm}>
                        {isComputing ? "Computing" : "Compute"} Ready Date
                      </CharlesButton>
                    </div>
                  ) : null}

                  {needToRecompute ? (
                    <div className="mt-3 text-sm font-semibold text-orange-600 tracking-wide">
                      You have changed products or start date for this plan, click the "Compute Ready Date" above to get an updated ready date..
                    </div>
                  ) : null}

                  {!needToRecompute && !isComputing ? (
                    <div className="mt-6 space-y-4">
                      <CharlesButtonWithArrow show={showProductions} onClick={() => setShowProductions((prev) => !prev)}>
                        Production Detail
                      </CharlesButtonWithArrow>

                      {showProductions ? <ProductionsView planId={originalPlan.id} /> : null}
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className="mt-2 opacity-70">Choose a customer above before adding products.</div>
              )}
            </div>

            {originalPlan.parent ? null : <AdditionPlansView id={originalPlan.id} tryAddAdditionalPlan={tryAddAdditionalPlan} />}

            {originalPlan.parent ? (
              <div className="card mt-8">
                <h5>Total Capacity</h5>
                <div className="mt-1">
                  This is an additional plan, capacity shows the total cbm capacity for the original plan, including other addtional plans if there is any.
                </div>
                <div className="mt-4">
                  <ContainerizationView
                    totalCbm={
                      totalCbm +
                      originalPlan.parent.totalCbm +
                      originalPlan.children.filter((i) => i.id !== originalPlan.id).reduce((res, i) => res + i.totalCbm, 0)
                    }
                  />
                </div>
              </div>
            ) : originalPlan.children.length > 0 ? (
              <div className="card mt-8">
                <CombinedPlanSummary id={originalPlan.id} />
              </div>
            ) : (
              <div className="card mt-8">
                <h5>Capacity</h5>
                <div className="mt-4">
                  <ContainerizationView totalCbm={totalCbm} />
                </div>
              </div>
            )}

            <div>
              <AboutOSView />
            </div>
          </div>
        </div>
      </Page>
    </ProductionContext.Provider>
  );
};

export default ProductionPlanForm;
