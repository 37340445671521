import { useQuery } from "@apollo/client";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { FETCH_PRODUCT_PRODUCTION_INFO } from "./graphql";
import Production from "components/Production";

const ProductProductionInfoView = ({ id }) => {
  const { loading, error, data } = useQuery(FETCH_PRODUCT_PRODUCTION_INFO, {
    variables: { id },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  if (!data.product.production)
    return (
      <div>
        <div className="card px-8 py-6 space-y-2">
          This product does not have production route. <br />
          Please choose one if you need to know the production ready date.
        </div>
      </div>
    );

  const productionRouteCategoryIds = data.product.production.descendants.map((i) => i.category.id);

  return (
    <div>
      <div className="card px-8 py-6 space-y-2">
        {data.product.considerBomInProduction ? (
          <div className="mt-2">
            <h5>BOM</h5>

            <div>
              {data.product.children.map((i) => (
                <div key={i.id} className="py-2 border-b dark:border-gray-700">
                  <div>
                    [{i.product.number}] {i.product.name} &times; {i.qty}
                  </div>
                  <div className="text-sm opacity-70 mt-1">
                    {i.product.productionCategory ? (
                      !productionRouteCategoryIds.includes(i.product.productionCategory.id) ? (
                        <span className="text-red-600 font-semibold">
                          {i.product.productionCategory.factory.name} : {i.product.productionCategory.name} not in production route.
                        </span>
                      ) : (
                        <span>
                          {i.product.productionCategory.factory.name} : {i.product.productionCategory.name}
                        </span>
                      )
                    ) : (
                      <span className="text-red-600 font-semibold text-sm">No Production Category, ignore computing.</span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : data.product.productionCategory ? (
          <div>
            <div>
              {data.product.productionCategory.factory.name} : {data.product.productionCategory.name}
            </div>
            <div className="opacity-70 text-sm">This product does not compute bom in production. It just compute the item itself in production.</div>
          </div>
        ) : (
          <div>
            <span className="text-red-600">Product does not consider BOM in production. It has no Production Category neither. Ignore computing.</span>
          </div>
        )}
      </div>

      <div className="card px-8 py-6 mt-6">
        <h5>Production Route</h5>
        <div className="mt-4">
          {data.product.production ? (
            <Production id={data.product.production.id} />
          ) : (
            <div>
              <div className="text-gray-500">This Product does not have Production data.</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductProductionInfoView;
