import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Products from "./products";
import Suppliers from "./suppliers";
import Sales from "./sales";
import SalesInc from "./salesInc";
import UpdateHistory from "./updateHistory";
import SelectExport from "./SelectExport";
import { AppContext } from "App";
import { NavigationLink } from "components/base";
import Page from "components/Page";
import Trends from "./analysis/Trends";
import Combines from "./analysis/Combines";
import Material from "./material";
import ExchangeRates from "./exchangeRates";
import PriceList from "./priceList";
import Errors from "components/Errors";
import { COST_PAGE_ALLOW_USERS, COST_VIEW_ONLY_USERS } from "pages/stock/permissions";

function PricePages() {
  const { user } = useContext(AppContext);

  if (![...COST_PAGE_ALLOW_USERS, ...COST_VIEW_ONLY_USERS].includes(user.email)) {
    return (
      <div className="p-10">
        <Errors
          error={{
            message: "Access Denied",
          }}
        />
      </div>
    );
  }

  const LeftButtonsView = ({ user }) => {
    if (COST_VIEW_ONLY_USERS.includes(user.email)) {
      return <NavigationLink to="/price/price-list" title="Price Lists" />;
    }
    return (
      <div className="flex space-x-4">
        <NavigationLink to="/price/products" title="Products" />
        <NavigationLink to="/price/suppliers" title="Suppliers" />
        <NavigationLink to="/price/price-list" title="Price Lists" />
        <NavigationLink to="/price/update-history" title="Update History" />
        <NavigationLink to="/price/select-export" title="Export" />
      </div>
    );
  };

  const routes = (user) => {
    if (COST_VIEW_ONLY_USERS.includes(user.email)) {
      return (
        <Routes>
          <Route path="price-list/*" element={<PriceList />} />
          <Route path="*" element={<Navigate to="price-list" />} />
        </Routes>
      );
    }

    return (
      <Routes>
        <Route path="products/*" element={<Products />} />
        <Route path="materials/*" element={<Material />} />
        <Route path="exchange-rates/*" element={<ExchangeRates />} />
        <Route path="suppliers/*" element={<Suppliers />} />
        <Route path="price-list/*" element={<PriceList />} />
        <Route path="sales/*" element={<Sales />} />
        <Route path="sales-inc" element={<SalesInc />} />
        <Route path="update-history" element={<UpdateHistory />} />
        <Route path="analysis">
          <Route path="trends" element={<Trends />} />
          <Route path="combines" element={<Combines />} />
        </Route>
        <Route path="select-export" element={<SelectExport />} />
        <Route path="*" element={<Navigate to="products" />} />
      </Routes>
    );
  };

  return (
    <Page
      leftButtons={<LeftButtonsView user={user} />}
      rightButtons={
        COST_VIEW_ONLY_USERS.includes(user.email) ? null : (
          <div className="flex space-x-4">
            <NavigationLink to="/price/materials" title="Raw Materials" />
            <NavigationLink to="/price/exchange-rates" title="Exchange Rates" />
            <NavigationLink to="/price/analysis/trends" title="Trends" />
            <NavigationLink to="/price/analysis/combines" title="Combines" />
          </div>
        )
      }
      className="h-screen"
    >
      {routes(user)}
    </Page>
  );
}

export default PricePages;
