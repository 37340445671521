import Page from "components/Page";

const PriceListPage = () => {
  return (
    <Page title="Retailer Store - Price List">
      <div>
        <table>
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Price</th>
              <th>Stock</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Product 1</td>
              <td>100</td>
              <td>10</td>
            </tr>
            <tr>
              <td>Product 2</td>
              <td>200</td>
              <td>20</td>
            </tr>
            <tr>
              <td>Product 3</td>
              <td>300</td>
              <td>30</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Page>
  );
};

export default PriceListPage;
