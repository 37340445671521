import { useQuery } from "@apollo/client";
import Spinner from "components/Spinner.js";
import Errors from "components/Errors.js";
import { FETCH_CHINA_STOCKS } from "./graphql";
import { Link } from "react-router-dom";
import SearchBar from "components/SearchBar";
import { useState } from "react";
import { searchByProp } from "utils/search";

function ChinaStocks() {
  const { loading, error, data } = useQuery(FETCH_CHINA_STOCKS);
  const [searchText, setSearchText] = useState("");

  if (loading) return <Spinner text="Loading data from Odoo..." />;
  if (error) return <Errors error={error} />;

  const filteredData = data.odooStocks
    .map((i) => {
      const combined = i.name + " - " + i.products.map((i) => i.number).join(", ");
      return { ...i, combined };
    })
    .filter((i) => searchByProp(i, ["combined"], searchText));

  return (
    <div className="p-6">
      <div className="card p-4 animate-fadeInDown">
        <div>
          <SearchBar placeholder="Search by name" value={searchText} onChange={setSearchText} />
        </div>
        <div className="mt-1">
          <table>
            <tbody className="divide-y dark:divide-gray-700">
              {filteredData.map((stock, index) => (
                <tr
                  key={index}
                  className=" animate-fadeInDown opacity-0"
                  style={{
                    animationDelay: `${index * 0.01}s`,
                  }}
                >
                  <td className=" whitespace-nowrap">
                    <Link to={stock.id}>{stock.name}</Link>
                  </td>
                  <td>
                    <div className="mt-1 opacity-70 text-xs">{stock.products.map((i) => i.number).join(", ")}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ChinaStocks;
