import CharlesButton from 'components/charles/base';
import moment from 'moment';
import React from 'react';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';

const TestPage = () => {
   const year = 2022;

   const months = [...Array(12).keys()].map((monthIndex) => {
      const month = moment({ year, month: monthIndex });
      const dates = [...Array(month.daysInMonth()).keys()].map((dateIndex) =>
         moment({ year, month: monthIndex, date: dateIndex + 1 })
      );
      const indentDays = month.day();
      return { month, dates, indentDays };
   });

   const weekNames = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

   const today = moment();

   return (
      <div className="bg-white rounded-2xl px-8 py-6 m-8">
         <div className="flex space-x-3">
            <CharlesButton>
               <IoChevronBack size={27} />
            </CharlesButton>
            <div className="text-3xl">{year}</div>
            <CharlesButton>
               <IoChevronForward size={27} />
            </CharlesButton>
         </div>

         <div className="grid grid-cols-4 gap-12 mt-8">
            {months.map(({ month, dates, indentDays }) => (
               <div key={month}>
                  <div className="grid grid-cols-7 text-center gap-3">
                     <div className="text-2xl text-red-500 w-6">
                        {month.format('MMMM')}
                     </div>
                     <div className="col-span-6"></div>

                     {[...Array(7).keys()].map((i) => (
                        <div className="opacity-60 w-6 h-6" key={i}>
                           {weekNames[i]}
                        </div>
                     ))}
                     {[...Array(indentDays).keys()].map((i) => (
                        <div key={i}></div>
                     ))}
                     {dates.map((i, index) => {
                        return (
                           <div
                              className={`font-semibold rounded-full w-6 h-6
                           ${
                              today.isSame(i, 'day')
                                 ? 'bg-red-500 text-white'
                                 : '1'
                           }
                        `}
                              key={index}
                           >
                              {i.format('D')}
                           </div>
                        );
                     })}
                  </div>
               </div>
            ))}
         </div>
      </div>
   );
};

export default TestPage;
