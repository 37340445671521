import { gql, useMutation, useQuery } from "@apollo/client";
import { Button } from "components/base";
import Errors from "components/Errors";
import { Input, Select, Switcher, Text } from "components/Form";
import Spinner from "components/Spinner";
import { Alert } from "components/Toast";
import { useEffect } from "react";
import { useState } from "react";
import ShippingMarkPreview from "./ShippingMarkPreview";
import CharlesButton from "components/charles/base";
import { v4 } from "uuid";

const FETCH_PRODUCT_INFO_FOR_SHIPPING_MARK = gql`
  query FETCH_PRODUCT_INFO_FOR_SHIPPING_MARK($id: ID!, $customerId: ID!) {
    product(id: $id) {
      id
      shippingMarkName
      number
      outerCartonBarcodeEan
      outerCartonBarcodeUpc
      outerCartonX
      outerCartonZ
      outerCartonY
      quantityPerCarton
      ctnNetWeight
      ctnGrossWeight
      packing
      colors
      shippingMarkNotes: computedShippingMarkNotes
      shippingMarkSettings {
        id
        customer {
          id
        }
        options
      }
    }
    customer(id: $customerId) {
      id
      name
      areas {
        id
        name
      }
    }
  }
`;

const SAVE_SHIPPING_MARK_SETTINGS = gql`
  mutation SAVE_SHIPPING_MARK_SETTINGS($productId: ID!, $customerId: ID, $options: String!) {
    saveShippingMarkSettings(productId: $productId, customerId: $customerId, options: $options) {
      shippingMarkSettings {
        id
      }
    }
  }
`;

const EU_VALUES = {
  main: [
    { key: "qty", prefix: "QTY: ", suffix: " PCS" },
    { key: "item", prefix: "ITEM: ", suffix: "" },
    { key: "barcode", prefix: "BARCODE: ", suffix: "" },
    { key: "detectedtype", prefix: "DETECTED BARCODE TYPE: ", suffix: "" },
    { key: "color", prefix: "COLOR: ", suffix: "" },
    { key: "nw", prefix: "N.W.: ", suffix: " KG" },
    { key: "gw", prefix: "G.W.: ", suffix: " KG" },
    { key: "dims", prefix: "DIMS: ", suffix: " CM" },
    { key: "ctn", prefix: "CTN NO.: ", suffix: "" },
  ],
  side: [],
};

const US_VALUES = {
  main: [
    { key: "item", prefix: "", suffix: "" },
    { key: "distribute", prefix: "", value: "DISTRIBUTED BY WABOBA INC", suffix: "" },
    { key: "dest", prefix: "DEST: ", value: "LITHIA SPRINGS, GA", suffix: "" },
    { key: "number", prefix: "ITEM # ", suffix: "" },
    { key: "barcode", prefix: "BARCODE: ", suffix: "" },
    { key: "detectedtype", prefix: "DETECTED BARCODE TYPE: ", suffix: "" },
    { key: "qty", prefix: "", suffix: " PCS" },
    { key: "dims", prefix: "CARTON DIMS: ", suffix: " CM" },
    { key: "nw", prefix: "N.W.: ", suffix: " KG" },
    { key: "gw", prefix: "G.W.: ", suffix: " KG" },
    { key: "ctn", prefix: "CARTON NO.: ", suffix: "" },
    { key: "made", prefix: "", value: "MADE IN CHINA", suffix: "" },
  ],
  side: [
    { key: "item", prefix: "", suffix: "" },
    { key: "number", prefix: "ITEM#", suffix: "" },
    { key: "barcode", prefix: "BARCODE: ", suffix: "" },
    { key: "detectedtype", prefix: "DETECTED BARCODE TYPE: ", suffix: "" },
    { key: "qty", prefix: "", suffix: " PCS" },
    { key: "gw", prefix: "G.W.: ", suffix: " KG" },
  ],
};

const templates = {
  EU: EU_VALUES,
  US: US_VALUES,
};

const GenerateShippingMark = ({ customer, row }) => {
  const { loading, error, data } = useQuery(FETCH_PRODUCT_INFO_FOR_SHIPPING_MARK, {
    variables: { id: row.product.id, customerId: customer.id },
    fetchPolicy: "network-only",
  });

  // default settings
  const [template, setTemplate] = useState("EU");
  const [newLogo, setNewLogo] = useState(false);
  const [rotate, setRotate] = useState(false);
  const [mainFontScale, setMainFontScale] = useState(100);
  const [sideFontScale, setSideFontScale] = useState(100);

  const [itemNumber, setItemNumber] = useState(row.product.number);
  const [values, setValues] = useState(US_VALUES);
  const [notes, setNotes] = useState("");
  const [detectedType, setDetectedType] = useState("");

  const [saveShippingMarkSettings, saveShippingMarkSettingsRes] = useMutation(SAVE_SHIPPING_MARK_SETTINGS, {
    variables: {
      productId: row.product.id,
      customerId: customer.id,
      options: JSON.stringify({
        template,
        newLogo,
        rotate,
        mainFontScale,
        sideFontScale,
      }),
    },
    onCompleted() {
      Alert("success", "Settings Saved.");
    },
    onError(error) {
      Alert("error", error.message);
    },
  });

  function mapValues(i, data, currentTemplate) {
    if (i.key === "qty") return { ...i, value: data.product.quantityPerCarton };
    if (i.key === "item")
      return {
        ...i,
        value: data.product.shippingMarkName ? data.product.shippingMarkName : row.packingName,
      };
    if (i.key === "number")
      return {
        ...i,
        value: data.product.number === "101C01_DS12" ? "101C01_DS" : data.product.number,
      };
    if (i.key === "barcode")
      return {
        ...i,
        prefix: "BARCODES: ",
        value: (currentTemplate === "US" ?
          (data.product.outerCartonBarcodeUpc ? data.product.outerCartonBarcodeUpc.replaceAll(" ", "") : '') :
          (data.product.outerCartonBarcodeEan ? data.product.outerCartonBarcodeEan.replaceAll(" ", "") : ''))
      };
    if (i.key === "color") return { ...i, value: data.product.colors || "" };
    if (i.key === "nw") return { ...i, value: data.product.ctnNetWeight };
    if (i.key === "gw") return { ...i, value: data.product.ctnGrossWeight };
    if (i.key === "dims") return { ...i, value: `${x} x ${z} x ${y}` };
    if (i.key === "ctn") {
      const totalCarton = row.qty / data.product.quantityPerCarton;
      return { ...i, value: `______ of ${totalCarton}` };
    }
    return i;
  }

  useEffect(() => {
    if (data) {
      const prevSettings = data.product.shippingMarkSettings.find((i) => i.customer.id === customer.id);
      const settingOptions = prevSettings ? JSON.parse(prevSettings.options) : null;

      const currentTemplate = settingOptions ? settingOptions.template : data.customer.areas.find((i) => i.name.includes("US")) ? "US" : "EU";
      setTemplate(currentTemplate);

      if (settingOptions) {
        setNewLogo(settingOptions.newLogo || false);
        setRotate(settingOptions.rotate);
        setMainFontScale(settingOptions.mainFontScale);
        setSideFontScale(settingOptions.sideFontScale);
      }

      setValues({
        main: templates[currentTemplate].main.map((i) => mapValues(i, data, currentTemplate)),
        side: templates[currentTemplate].side.map((i) => mapValues(i, data, currentTemplate)),
      });

      if (data.product.shippingMarkNotes) setNotes(data.product.shippingMarkNotes);
    }
  }, [data]);

  useEffect(() => {
    const currentBarcode = values.main.find((i) => i.key === "barcode").value;
    if (currentBarcode) {
      if (currentBarcode.length === 12) {
        setDetectedType("upc");
      } else if (currentBarcode.length === 13) {
        setDetectedType("ean13");
      } else if (currentBarcode.length === 14) {
        setDetectedType("itf");
      } else {
        setDetectedType("unknown");
      }
    }

  }, [values]);

  function onChangeTemplate(newTemplate) {
    setTemplate(newTemplate);
    setValues({
      main: templates[newTemplate].main.map((i) => mapValues(i, data, newTemplate)),
      side: templates[newTemplate].side.map((i) => mapValues(i, data, newTemplate)),
    });
  }

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const x = parseFloat(data.product.outerCartonX);
  const y = parseFloat(data.product.outerCartonY);
  const z = parseFloat(data.product.outerCartonZ);

  const item = values.main.find((i) => i.key === "item").value;
  const barcode = values.main.find((i) => i.key === "barcode").value;

  const computedValues = { ...values };
  computedValues.main = computedValues.main.filter((i) => i.key !== "detectedtype");
  computedValues.side = computedValues.side.filter((i) => i.key !== "detectedtype");
  // console.log('computedValues', computedValues);

  function addRow(face, index) {
    const newValues = { ...values };
    newValues[face].splice(index + 1, 0, { key: v4(), prefix: "", value: "", suffix: "", tmp: true });
    setValues(newValues);
  }

  function removeRow(face, index) {
    const newValues = { ...values };
    newValues[face].splice(index, 1);
    setValues(newValues);
  }

  function onChangeValue(key, value) {
    setValues((prev) => ({
      main: prev.main.map((i) => (key === i.key ? { ...i, value } : i)),
      side: prev.side.map((i) => (key === i.key ? { ...i, value } : i)),
    }));
  }

  return (
    <div>
      <div className="flex space-x-8">
        <div className="w-1/3 -mx-2">
          <table>
            <thead>
              <tr>
                <th className="w-12 text-base">Settings</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Template</td>
                <td>
                  <Select value={template} onChange={(e) => onChangeTemplate(e.target.value)}>
                    {Object.keys(templates).map((i) => (
                      <option value={i} key={i}>
                        {i}
                      </option>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td>
                  <label>Use New Logo: </label>
                </td>
                <td>
                  <Switcher isOn={newLogo} onChange={() => setNewLogo((prev) => !prev)} />
                </td>
              </tr>
              <tr>
                <td>
                  <label>Rotate: </label>
                </td>
                <td>
                  <Switcher isOn={rotate} onChange={() => setRotate((prev) => !prev)} />
                </td>
              </tr>
              <tr>
                <td>
                  <label>Main Face Scale: </label>
                </td>
                <td>
                  <Select value={`${mainFontScale}`} onChange={(e) => setMainFontScale(e.target.value)}>
                    {[...Array(300).keys()].map((i) => (
                      <option key={i} value={i + 1}>
                        {i + 1}%
                      </option>
                    ))}
                  </Select>
                </td>
              </tr>

              {values.side.length > 0 ? (
                <tr>
                  <td>
                    <label>Side Face Scale: </label>
                  </td>
                  <td>
                    <Select value={`${sideFontScale}`} onChange={(e) => setSideFontScale(e.target.value)}>
                      {[...Array(300).keys()].map((i) => (
                        <option key={i} value={i + 1}>
                          {i + 1}%
                        </option>
                      ))}
                    </Select>
                  </td>
                </tr>
              ) : null}


              <tr>
                <td colSpan={2}>
                  <div>
                    <Button
                      disabled={saveShippingMarkSettingsRes.loading}
                      loading={saveShippingMarkSettingsRes.loading}
                      title="Save Settings"
                      onClick={saveShippingMarkSettings}
                    />

                    <div className="mt-1 opacity-60">
                      When save this settings, it will auto apply to the same product and the same customer when you generate shipping mark next time.
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>

            <thead>
              <tr>
                <th></th>
              </tr>
              <tr>
                <th className="text-base">Main Face</th>
              </tr>
            </thead>
            <tbody>
              {template === "EU" ? (
                <tr>
                  <td colSpan={2}>
                    <div className="flex items-center space-x-3">
                      <label htmlFor="">ITEM NO.</label>
                      <Input value={itemNumber} onChange={(e) => setItemNumber(e.target.value)} />
                    </div>
                  </td>
                </tr>
              ) : null}

              {values.main.map((i, index) => (
                <tr key={index}>
                  <td colSpan={2}>
                    {i.key === "detectedtype" ? (
                      <div className="flex items-center space-x-3">
                        <label>{i.prefix}</label>
                        {
                          detectedType === "ean13" ? (
                            <span>EAN 13</span>
                          ) : detectedType === "upc" ? (
                            <span>UPC</span>
                          ) : detectedType === "itf" ? (
                            <span>GTIN 14</span>
                          ) : (
                            <span className="text-red-500">Invalid barcode</span>
                          )
                        }
                      </div>
                    ) : (
                      <div className="flex items-center space-x-3">
                        {i.prefix ? <label>{i.prefix}</label> : null}
                        <Input
                          className="w-full "
                          disabled={i.key === "dims"}
                          value={i.value}
                          onChange={(e) => onChangeValue(i.key, e.target.value)}
                          placeholder={i.tmp ? null : i.key}
                        />

                        {i.suffix ? <span>{i.suffix}</span> : null}

                        <CharlesButton onClick={() => addRow("main", index)}>+</CharlesButton>
                        {i.tmp ? (
                          <CharlesButton onClick={() => removeRow("main", index)} danger>
                            -
                          </CharlesButton>
                        ) : null}
                      </div>
                    )}
                    {i.key === "dims" ? (
                      <div className="text-xs opacity-70 mt-1">If you want to update the DIMS, please contact product manager to update the product data.</div>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>

            {values.side.length > 0 ? (
              <>
                <thead>
                  <tr>
                    <th></th>
                  </tr>
                  <tr>
                    <th className="text-base">Side Face</th>
                  </tr>
                </thead>
                <tbody>
                  {values.side.map((i, index) => (
                    <tr key={index}>
                      <td colSpan={2}>
                        {i.key === "detectedtype" ? (
                          <div className="flex items-center space-x-3">
                            <label>{i.prefix}</label>
                            {
                              detectedType === "ean13" ? (
                                <span>EAN 13</span>
                              ) : detectedType === "upc" ? (
                                <span>UPC</span>
                              ) : detectedType === "itf" ? (
                                <span>GTIN 14</span>
                              ) : (
                                <span className="text-red-500">Invalid barcode</span>
                              )
                            }
                          </div>
                        ) : (
                          <div className="flex items-center space-x-3">
                            {i.prefix ? <label>{i.prefix}</label> : null}
                            <Input className="w-full" value={i.value} onChange={(e) => onChangeValue(i.key, e.target.value)} />
                            {i.suffix ? <span>{i.suffix}</span> : null}

                            <CharlesButton onClick={() => addRow("side", index)}>+</CharlesButton>
                            {i.tmp ? (
                              <CharlesButton onClick={() => removeRow("main", index)} danger>
                                -
                              </CharlesButton>
                            ) : null}
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </>
            ) : null}

            <thead>
              <tr>
                <th></th>
              </tr>
              <tr>
                <th className="text-base">Notes</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td colSpan={2}>
                  <Text placeholder="Write some notes here together in the PDF." value={notes} onChange={(e) => setNotes(e.target.value)} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="flex-1 space-y-4 flex flex-col">
          <div>
            <h4>Notice</h4>
            <ul className="mt-2 leading-relaxed list-decimal list-inside">
              <li>该功能暂不支持打印内箱的唛头图稿，有内箱的产品请单独与工厂沟通唛头资料。</li>
              <li>该功能暂时不支持客制版的唛头图稿，客制版的唛头按照旧流程操作。</li>
              <li>唛头图稿出现有文字超出版面或者文字盖住条形码、logo的情况，需要调整印刷字体的百分比，然后按“save setting"保存设置。</li>
              <li>带隔卡的产品在唛头上会出现“外箱需要配套K4K材质的隔卡，请记得安排生产！”字眼，下单时需提醒九州配套隔卡生产。</li>
              <li>
                Heads glide三个产品的唛头需要做转向设置，按“Rotate"按钮把唛头转向并检查唛头是否需要做字体大小的调整，选择了合适的百分比之后按“Save
                Setting"为该产品保存转向设置和百分比设置。
              </li>
              <li>产品101C01_DS12的唛头要求印刷101C02_DS这个Item#。</li>
              <li>对于Formula sports使用欧洲唛头模板的客户，产品321C99_A48要求使用UPC条形码。</li>
              <li>下载唛头图稿之前务必检查唛头上的产品信息并确保图稿没有印刷问题再发给工厂。</li>
              <li>条码类型将根据当前输入的条码值显示对应类型，生成右侧一次性的唛头。</li>
            </ul>
          </div>

          <div className="bg-white dark:bg-gray-900 border border-opacity-50 dark:border-opacity-20 rounded-3xl flex-1 flex flex-col relative overflow-auto p-8">
            {item && (
              <ShippingMarkPreview
                variables={{
                  item,
                  x,
                  y,
                  z,
                  barcode,
                  barcodeType: detectedType,
                  newLogo,
                  template,
                  itemNumber: template === "EU" ? itemNumber : null,
                  values: computedValues,
                  notes,
                  rotate,
                  mainFontScale: parseFloat(mainFontScale) / 100,
                  sideFontScale: parseFloat(sideFontScale) / 100,
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenerateShippingMark;
