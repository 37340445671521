import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Accounts from "./accounts";
import Products from "./products/All/Products";
import ProductLines from "./products/All/ProductLines";
import { LeftButtons } from "./products/Header";
import Page from "components/Page";
import ProductBeautyshots from "./beautyshots";
import ProductForm from "./products/All/ProductForm";

function ManageIndex() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="products" element={<Products />} />
        <Route path="product-lines" element={<ProductLines />} />
        <Route path="product-beautyshots" element={<ProductBeautyshots />} />
      </Route>
      <Route path="products/:id" element={<ProductForm />} />
      <Route path="accounts" element={<Accounts />} />
      <Route path="*" element={<Navigate to="products" />} />
    </Routes>
  );
}

const Layout = () => (
  <Page leftButtons={<LeftButtons />} className="lg:h-screen">
    <Outlet />
  </Page>
);

export default ManageIndex;
