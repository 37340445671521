import Page from "components/Page";
import { Link } from "react-router-dom";

const Suppliers = ({ suppliers }) => {
  const totalQty = (supplier) => supplier.products.reduce((acc, product) => acc + product.qty, 0);
  const totalToProduces = (supplier) => supplier.products.reduce((acc, product) => acc + product.to_produce, 0);

  return (
    <Page title="Lump Sum - V2" subtitle="Summary for products in Order Scheduler `Planing` and `Waiting to Confirm` plans." backTo="./..">
      <div className="p-6 flex-1">
        <div className="p-4 card ">
          <table className="whitespace-nowrap">
            <thead>
              <tr>
                <th className="w-full">Supplier</th>
                <th className="text-right">Products</th>
                <th className="text-right">To Produce</th>
              </tr>
            </thead>
            <tbody>
              {suppliers.map((supplier, index) => (
                <tr key={index} className="border-t border-gray-100 dark:border-gray-700">
                  <td>
                    <Link to={`${supplier.id}`}>{supplier.name}</Link>
                  </td>
                  <td className="text-right">{totalQty(supplier)}</td>
                  <td className="text-right">{totalToProduces(supplier)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Page>
  );
};

export default Suppliers;
