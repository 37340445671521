import React, { useEffect, useState } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import WabobaLogo from "../WabobaLogo";
import { Input, Select } from "components/Form";
import { Button, ButtonX, RoundedButton } from "components/base";
import { Alert } from "components/Toast";

const FETCH_SUPPLIER_INFO = gql`
  query FETCH_SUPPLIER_INFO($code: String!) {
    factory: factoryFromCode(code: $code) {
      name
      productionCategories {
        id
        name
        type
        timeList {
          qty
          days
        }
      }
    }
  }
`;

const Production = () => {
  const { code } = useParams();
  const { loading, data, error } = useQuery(FETCH_SUPPLIER_INFO, {
    variables: { code },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <div className="p-0 lg:p-6 w-full max-w-5xl mx-auto relative z-10 h-full flex flex-col flex-1">
      <div className="h-16 mt-16">
        <WabobaLogo />
      </div>
      <div className="text-center py-8 space-y-4">
        <h2>{data.factory.name}</h2>
        <div>Production Time Form / 生产时间表</div>
      </div>

      <FactoryProductionForm remoteData={data.factory} code={code} />
    </div>
  );
};

const SAVE_PRODUCTION_FORM = gql`
  mutation SAVE_PRODUCTION_FORM($code: String!, $data: String!) {
    saveProductionForm(code: $code, data: $data) {
      message
    }
  }
`;

const dataAfterCompare = (localStorageData, remoteData, reset) => {
  const newData = remoteData.productionCategories;
  if (reset) return newData;

  try {
    const prevData = localStorageData ? JSON.parse(localStorageData) : null;

    if (prevData) {
      const prevStruct = prevData.map((cat) => ({
        id: cat.id,
        name: cat.name,
        type: cat.type,
        timeListQty: cat.timeList.map((i) => i.qty),
      }));
      const newStruct = newData.map((cat) => ({
        id: cat.id,
        name: cat.name,
        type: cat.type,
        timeListQty: cat.timeList.map((i) => i.qty),
      }));
      if (JSON.stringify(prevStruct) === JSON.stringify(newStruct)) return prevData;
    }
    return newData;
  } catch {
    return newData;
  }
};

const FactoryProductionForm = ({ remoteData, code }) => {
  const location = useLocation();
  const urlReset = queryString.parse(location.search).reset;

  const finalInitialData = dataAfterCompare(localStorage.getItem(code), remoteData, urlReset === "1");
  const [data, setData] = useState(finalInitialData);
  const [saveProductForm, saveProductFormRes] = useMutation(SAVE_PRODUCTION_FORM, {
    onCompleted: () => Alert("success", "Submit success."),
    onError: (error) => Alert("error", error.message),
  });

  console.log("data", data);

  useEffect(() => {
    localStorage.setItem(code, JSON.stringify(data));
  }, [data]);

  function onChange(index, field, value) {
    setData((prev) =>
      prev.map((i, prevIndex) => {
        if (prevIndex !== index) return i;
        return { ...i, [field]: value };
      })
    );
  }

  function reset() {
    localStorage.removeItem(code);
    document.location.reload();
  }

  function submit() {
    let errorMessage = "";

    const catsByDay = data.filter((i) => i.type === "BY_DAY");
    if (catsByDay.filter((cat) => !cat.qtyPerDay || parseInt(cat.qtyPerDay) <= 0).length > 0) errorMessage += "按天生产的数量必须是大于0的整数。";

    const catsByQty = data.filter((i) => i.type === "BY_QTY" && i.timeList.length > 0);
    if (catsByQty.length > 0) {
      if (
        catsByQty.filter((cat) => {
          const validTimeList = cat.timeList.filter((time) => time.qty && time.days && time.qty > 0 && time.days > 0);
          return validTimeList.length > 0;
        }).length === 0
      ) {
        errorMessage += "请输入按天数生产的合理数据，数量和天数均不能为0。";
      }
    }

    if (errorMessage) {
      Alert("error", errorMessage);
      return;
    }

    saveProductForm({
      variables: { code, data: JSON.stringify(data, "", 2) },
    });
  }

  return (
    <div className="mx-8 flex flex-col flex-1">
      <div className="space-y-8">
        {data.map((cat, catIndex) => (
          <div key={catIndex} className="card space-y-3">
            <div className="flex justify-between items-baseline">
              <h3>{cat.name}</h3>
              <div>
                <label className="pr-2"> 类型: </label>
                <Select value={cat.type} onChange={(e) => onChange(catIndex, "type", e.target.value)}>
                  <option value="BY_QTY">按数量计算</option>
                  <option value="BY_DAY">按天计算</option>
                </Select>
              </div>
            </div>
            {cat.type === "BY_DAY" ? (
              <div>
                <label className="pr-2">数量:</label>
                <Input type="number" className="ml-4 text-center" value={cat.qtyPerDay} onChange={(e) => onChange(catIndex, "qtyPerDay", e.target.value)} />
              </div>
            ) : (
              <div>
                <div className="space-y-3">
                  {cat.timeList.map((time, timeIndex) => (
                    <div className="flex items-center justify-between sm:justify-start space-x-6" key={timeIndex}>
                      <div className="sm:flex sm:flex-wrap items-center">
                        <div className="sm:flex space-x-4 items-center sm:mr-6 mb-2 sm:mb-0">
                          <label htmlFor="">数量:</label>
                          <Input
                            type="number"
                            value={time.qty}
                            placeholder="qty"
                            className="text-center"
                            onChange={(e) =>
                              onChange(
                                catIndex,
                                "timeList",
                                cat.timeList.map((prevTime, prevIndex) =>
                                  prevIndex === timeIndex
                                    ? {
                                        ...prevTime,
                                        qty: e.target.value,
                                      }
                                    : prevTime
                                )
                              )
                            }
                          />
                        </div>
                        <div className="flex space-x-4 items-center">
                          <label htmlFor="">天数:</label>
                          <Input
                            type="number"
                            value={time.days}
                            placeholder="days"
                            className="text-center"
                            onChange={(e) =>
                              onChange(
                                catIndex,
                                "timeList",
                                cat.timeList.map((prevTime, prevIndex) =>
                                  prevIndex === timeIndex
                                    ? {
                                        ...prevTime,
                                        days: e.target.value,
                                      }
                                    : prevTime
                                )
                              )
                            }
                          />
                        </div>
                      </div>
                      <div>
                        <ButtonX
                          onClick={() =>
                            onChange(
                              catIndex,
                              "timeList",
                              cat.timeList.filter((_, prevIndex) => prevIndex !== timeIndex)
                            )
                          }
                        />
                      </div>
                    </div>
                  ))}

                  <div>
                    <Button title="+ 添加新数量" onClick={() => onChange(catIndex, "timeList", [...cat.timeList, { qty: "", days: "" }])} />
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="mt-16 flex justify-center space-x-8">
        <RoundedButton className="bg-gray-200 hover:bg-gray-300 active:bg-gray-400 text-gray-700 text-xl px-12" onClick={reset}>
          重置
        </RoundedButton>
        <RoundedButton
          className="bg-blue-600 hover:bg-blue-700 active:bg-blue-800 text-white text-xl px-12"
          onClick={submit}
          loading={saveProductFormRes.loading}
          disabled={saveProductFormRes.loading}
        >
          提交
        </RoundedButton>
      </div>
    </div>
  );
};

export default Production;
