import { useContext, useState } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import Spinner from 'components/Spinner';
import Errors from 'components/Errors';
import Image from 'components/Image';
import { isStaff } from 'hooks/user.js';
import ProductVariant from './ProductVariant';
import { Button } from 'components/base';
import SearchBar from 'components/SearchBar';
import { searchByProp } from 'utils/search';
import http from 'utils/http';
import { Alert } from 'components/Toast';
import { AppContext } from 'App';
import { formatDate } from 'react-day-picker/moment';

const FETCH_PRODUCTS_FOR_SALES_INC = gql`
   query FETCH_PRODUCTS_FOR_SALES_INC {
      productsSalesInc
   }
`;

const GENERATE_DOCUMENT = gql`
   mutation GENERATE_DOCUMENT(
      $name: String!
      $data: String!
      $docType: String!
   ) {
      generateDocument(name: $name, data: $data, docType: $docType) {
         document {
            id
            name
            docType
            data
         }
      }
   }
`;

const SalesIncV2 = () => {
   const { user } = useContext(AppContext);
   const { loading, error, data } = useQuery(FETCH_PRODUCTS_FOR_SALES_INC);
   const [generateDocument] = useMutation(GENERATE_DOCUMENT, {
      onError: (error) => Alert('error', error.message),
   });

   const [searchText, setSearchText] = useState('');
   const [editMode, setEditMode] = useState(false);
   const [exportingPdf, setExportingPdf] = useState(false);

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const productLines = JSON.parse(data.productsSalesInc)
      .map((line) => {
         const stringForSearch = line.products
            .map((p) => `${p.name} ${p.number}`)
            .join(' ');
         return { ...line, stringForSearch };
      })
      .filter((i) => searchByProp(i, ['name', 'stringForSearch'], searchText));

   console.log('productLines', productLines);

   const canEdit = isStaff(user);

   const variables = {
      docType: 'cost_sales_inc',
      name: 'Cost - Sales INC',
      data: JSON.stringify({ productLines }, '', 4),
   };

   function exportPdf() {
      setExportingPdf(true);
      generateDocument({ variables })
         .then((res) => {
            return http.get(
               `${process.env.REACT_APP_SERVER_BASE_URL}generated-document/${res.data.generateDocument.document.id}/?excel=1`,
               { responseType: 'blob', withCredentials: true }
            );
         })
         .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
               'download',
               `${variables.name}_${formatDate(new Date(), 'YYYY-MM-DD')}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
            setExportingPdf(false);
         })
         .catch((e) => {
            Alert('error', e.message);
            setExportingPdf(false);
         });
   }

   return (
      <div>
         <div className="flex mx-6 mt-6">
            <div className="px-4 py-1 rounded-full text-white bg-orange-500">
               Rule: 37 % margin on all products except classic line (30%
               margin) and floor stands (0% margin).
            </div>
         </div>

         <div className="flex justify-end sticky top-0 p-6 backdrop-filter backdrop-blur-lg bg-gray-100 dark:bg-gray-900 bg-opacity-70 z-30">
            <div className="flex-1">
               <SearchBar
                  autoFocus
                  placeholder="Search by name or item number"
                  value={searchText}
                  onChange={setSearchText}
               />
            </div>
            <div className="flex space-x-4 pl-6">
               {editMode ? null : (
                  <Button
                     title="Export"
                     onClick={exportPdf}
                     loading={exportingPdf}
                     disabled={exportingPdf}
                  />
               )}

               {canEdit ? (
                  <Button
                     title={editMode ? 'Done' : 'Edit'}
                     onClick={() => setEditMode((prev) => !prev)}
                  />
               ) : null}
            </div>
         </div>

         <div className="p-6 pt-0">
            {productLines.map((line, index) => (
               <ProductLineDetail
                  key={index}
                  line={line}
                  products={line.products}
                  editMode={editMode}
                  searchText={searchText}
               />
            ))}
         </div>
      </div>
   );
};

const ProductLineDetail = ({ line, products, editMode, searchText }) => {
   return (
      <div
         key={line.id}
         className="mb-8 bg-white dark:bg-gray-800 border border-gray-100 dark:border-gray-800 rounded-xl p-2"
      >
         <div className="flex text-right items-end space-x-8 px-4 py-2 font-bold text-gray-500 whitespace-nowrap">
            <div className="w-1/2">
               <div>
                  <div className="my-1 flex items-end">
                     <h3 className="pr-4">{line.name}</h3>
                     <Image src={line.mainImage} size="w-8 h-8" />
                  </div>
                  {line.salesPriceNotes ? (
                     <div className="text-left text-red-600 whitespace-normal">
                        {line.salesPriceNotes}
                     </div>
                  ) : null}
               </div>
            </div>
            <div className="uppercase w-32 flex-grow">Qty</div>
            <div className="uppercase w-24">AB Ex-Work</div>
            <div className="uppercase w-24">INC Price</div>
            <div className="uppercase w-24">AB Margin</div>
         </div>

         <div>
            {products.map((variant, index) => (
               <ProductVariant
                  key={index}
                  variant={variant}
                  editMode={editMode}
                  found={searchByProp(variant, ['name', 'number'], searchText)}
               />
            ))}
         </div>
      </div>
   );
};

export default SalesIncV2;
