import CharlesButton from "components/charles/base";

const ConfirmOfflinePaymentView = ({ hide }) => {
  return (
    <div>
      <div>
        <p>Are you sure you want to confirm this payment?</p>
        <p>Once you confirm this payment, the order will be marked as paid.</p>
      </div>

      <hr />

      <div>
        <CharlesButton primary>Confirm Payment</CharlesButton>
      </div>
    </div>
  );
};

export default ConfirmOfflinePaymentView;
