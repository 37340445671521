import React, { useContext, useEffect, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Input } from 'components/Form';
import { Button } from 'components/base';
import { Alert } from 'components/Toast';
import version from 'utils/version';
import TV from './TV';
import { AppContext } from 'App';
import { isInternalUser, useFetchMe } from 'hooks/user';
import Spinner from 'components/Spinner';

const TOKEN_LOGIN = gql`
   mutation TOKEN_LOGIN($token: String!) {
      tokenLogin(token: $token) {
         me {
            id
            groups {
               id
               name
            }
         }
      }
   }
`;

const AuthTV = () => {
   const { loading, data } = useFetchMe();
   const { user, setUser } = useContext(AppContext);
   const [token, setToken] = useState(
      process.env.REACT_APP_WABOBA_TV_SECRET_KEY
   );

   useEffect(() => {
      if (data) setUser(data.me);
   }, [data]);

   const [login, loginRes] = useMutation(TOKEN_LOGIN, {
      variables: { token },
      onCompleted: (res) => {
         setUser(res.tokenLogin.me);
      },
      onError: (error) => Alert('error', error.message),
   });

   function submit(e) {
      e.preventDefault();
      login();
   }

   if (loading) return <Spinner />;

   if (user && isInternalUser(user)) return <TV />;

   return (
      <div
         className="min-h-screen flex items-center justify-center bg-no-repeat bg-center bg-cover"
         style={{
            backgroundImage: `url(https://cdn.waboba.com/assets/promo_images/3f8be394242d476daa949abfc7333e37-Jeff_Nass_Productions_Airlyft_Web_Res-38.jpg)`,
         }}
      >
         <form className="w-full max-w-sm" onSubmit={submit}>
            <h1
               className="text-center text-gray-100 text-5xl"
               style={{
                  textShadow: '0px 2px 15px #00000059',
               }}
            >
               Waboba TV
            </h1>
            <div
               className="text-gray-600 p-2 text-center"
               style={{ fontSize: 12 }}
            >
               v{version}
            </div>
            <div className="mt-4">
               <Input
                  className="w-full text-center"
                  placeholder="Secret Code"
                  value={token}
                  onChange={(e) => setToken(e.target.value)}
               />
            </div>
            <div className="flex justify-center mt-4">
               <Button
                  next
                  iconSize={21}
                  size="lg"
                  loading={loginRes.loading}
                  disabled={loginRes.loading}
                  title="Let me in"
               />
            </div>
         </form>
      </div>
   );
};

export default AuthTV;
