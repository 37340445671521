import { useQuery } from "@apollo/client";
import { FETCH_QC_REPORTS_LIST } from "../graphql";
import { InlineSpinner } from "components/Spinner";
import Errors from "components/Errors";
import { PAGE_SIZE } from ".";
import CharlesButton from "components/charles/base";
import QcReportListRow from "../components/QcReportListRow";
import { BsChevronLeft } from "react-icons/bs";

const ReportsListTableView = ({ limit, offset, q, status, setOffset }) => {
  const { loading, error, data } = useQuery(FETCH_QC_REPORTS_LIST, { variables: { limit, offset, q, status } });

  if (loading)
    return (
      <div className="p-20 relative">
        <InlineSpinner />
      </div>
    );

  if (error) return <Errors error={error} />;

  return (
    <div className="card">
      <div className=" divide-y divide-gray-100 dark:divide-gray-700">
        {data.qcReports.results.map((report) => (
          <QcReportListRow key={report.id} report={report} />
        ))}
      </div>

      <div className="py-3 flex justify-end items-center space-x-2 opacity-60 border-t">
        {offset > 0 ? <CharlesButton prev onClick={() => setOffset(offset - PAGE_SIZE)}></CharlesButton> : null}
        <div>
          {offset + 1}-{Math.min(PAGE_SIZE + offset, data.qcReports.total)} / {data.qcReports.total}
        </div>
        {data.qcReports.total > offset + PAGE_SIZE ? <CharlesButton next onClick={() => setOffset(PAGE_SIZE + offset)}></CharlesButton> : null}
      </div>
    </div>
  );
};

export default ReportsListTableView;
