import { MdOutlineCancel } from "react-icons/md";
import { useEffect, useState } from "react";
import { FileSelector } from "components/Form";
import QiniuUploader from "components/QiniuUploader";
import { CREATE_PACKAGE_IMAGE, DELETE_PACKAGE_IMAGE } from "./graphql";
import { useMutation } from "@apollo/client";

const PackageImage = ({ image, originalIndex, onCreated, onDeleted, isEditable }) => (
  <div>
    {image.url ? (
      <div className="relative">
        <a href={image.url} target="_blank" title="Open origin image" rel="noopener noreferrer">
          <img src={image.url} alt={image.name} />
        </a>
        {isEditable &&
          <MdOutlineCancel
            className="text-3xl absolute top-2 right-2 cursor-pointer text-red-600 hover:text-red-700 active:text-red-800"
            onClick={() => {
              onDeleted(image.id);
            }} />
        }
      </div>
    ) : (
      <QiniuUploader file={image.file} filename={image.name} onUploaded={(uploadedUrl) => onCreated(originalIndex, uploadedUrl)} />
    )}
  </div>
);

const PackageImageList = ({ packageId, packageImages, isEditable }) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    setImages(packageImages.map(image => ({ ...image, file: null })));
  }, [packageImages]);

  const [createPackageImage] = useMutation(CREATE_PACKAGE_IMAGE, {
    onCompleted: () => {
      Alert("success", "Image added successfully");
    }
  });
  const [deletePackageImage] = useMutation(DELETE_PACKAGE_IMAGE, {
    onCompleted: () => {
      Alert("success", "Image deleted successfully");
    }
  });

  const updateImage = (originalIndex, newUrl) => {
    setImages((prev) =>
      prev.map((image, index) =>
        index === originalIndex ? { ...image, url: newUrl } : image
      )
    );

    createPackageImage({
      variables: {
        name: images[originalIndex].name,
        url: newUrl,
        packageId: Number(packageId)
      }
    });
  };

  const deleteImage = (imageId) => {
    setImages((prev) =>
      prev.filter((image) => image.id !== imageId)
    );

    deletePackageImage({
      variables: {
        imageId
      }
    });
  };

  return (
    <div>
      {isEditable && (
        <div
          data-testid="package-add-image"
          className="mb-4 text-sm"
        >
          <FileSelector
            accept="image/*"
            title="+ Add Image"
            onChange={(e) => {
              const files = [...e.target.files];
              // console.log(files);
              setImages((prev) => [
                ...prev,
                ...files.map((file) => ({
                  url: null,
                  name: `packageTracking/${file.name}`,
                  file
                })),
              ]);
              e.target.value = null;  // for re-selecting the same file
            }}
          />
        </div>
      )}

      <div data-testid="package-images" className=" space-y-2">
        {images.length > 0 ? images.map((image, index) => (
          <PackageImage key={index} image={image} originalIndex={index} onCreated={updateImage} onDeleted={deleteImage} isEditable={isEditable} />
        )) :
          <p className="opacity-70 text-xs">No sample images added yet.</p>
        }
      </div>
    </div>
  );
};

export default PackageImageList;