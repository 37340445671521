import React from 'react';
import http from './http';

export default (name, content = '') => {
   http
      .post(
         '/an/',
         { name, content },
         { baseURL: process.env.REACT_APP_SERVER_BASE_URL }
      )
      .catch(() => {});
};

const parseErrorMessage = '-';

export const actions = {
   product: {
      selectAllProducts: {
         name: 'product - select all products',
      },
      showOnlySelected: {
         name: 'product - show only selected',
      },
      clear: { name: 'product - clear' },
      selectLines: {
         name: 'product - select lines',
         display: (content) => {
            try {
               let data = JSON.parse(content);
               return data.map((i) => i.name).join(',');
            } catch (e) {
               return parseErrorMessage;
            }
         },
      },
      selectAreas: {
         name: 'product - select areas',
         display: (content) => {
            if (content === '') return null;
            try {
               let data = JSON.parse(content);
               return data.map((i) => i.name).join(',');
            } catch (e) {
               return parseErrorMessage;
            }
         },
      },
      selectItemsToExport: {
         name: 'product - select items to export',
         display: (content) => {
            try {
               let data = JSON.parse(content);
               return data.map((i) => i.name).join('\n');
            } catch (e) {
               return parseErrorMessage;
            }
         },
      },
      selectFieldsToExport: {
         name: 'product - select fields to export',
      },
      export: {
         name: 'product - export',
         display: (content) => {
            try {
               let data = JSON.parse(content);
               return `${data.type} - ${data.products
                  .map((i) => i.name)
                  .join(',')}`;
            } catch (e) {
               return parseErrorMessage;
            }
         },
      },
      download: {
         name: 'product - download',
         display: (content) => {
            try {
               let data = JSON.parse(content);
               return `${data.type} - ${data.file}`;
            } catch (e) {
               return parseErrorMessage;
            }
         },
      },
      showProductDetail: {
         name: 'product - show detail',
         display: (content) => {
            try {
               let data = JSON.parse(content);
               return data.name;
            } catch (e) {
               return parseErrorMessage;
            }
         },
      },
      filterArea: {
         name: 'product - filter area',
         display: (content) => {
            try {
               let data = JSON.parse(content);
               return data.name;
            } catch (e) {
               return parseErrorMessage;
            }
         },
      },
      exportAll: { name: 'product - export all' },

      showOldImages: { name: 'product - show old images' },

      associateLifestyleImages: {
         name: 'product - associate lifestyle images',
         display: (content) => content,
      },
      associateVideos: {
         name: 'product - associate videos',
         display: (content) => content,
      },
   },

   productList: {
      createProductList: { name: 'product list - create' },
      updateProductList: { name: 'product list - update' },
      createProductFromProductList: {
         name: 'product list - create product from product list',
      },
      updateProductFromProductList: {
         name: 'product list - update product from product list',
      },
   },

   customer: {
      removeSubAccount: {
         name: 'customer - remove sub account',
      },
   },

   calculator: {
      scrollToProduct: {
         name: 'calculator - scroll to product',
      },
      save: { name: 'calculator - save' },
      saveAs: { name: 'calculator - save as' },
      export: { name: 'calculator - export' },
      clear: { name: 'calculator - clear' },
   },

   stock: {
      bulkUpdate: { name: 'stock - bulk update' },
      saveStock: { name: 'stock -save stock' },
      deleteStock: { name: 'stock - delete stock' },
      addCalculateRow: {
         name: 'stock - add calculate row',
      },
      deleteCalculateRow: {
         name: 'stock - delete calculate row',
      },
      odooStock: {
         name: 'stock - check odoo stock',
         display: (content) => {
            try {
               let data = JSON.parse(content);
               return data.name;
            } catch (e) {
               return parseErrorMessage;
            }
         },
      },
      plannerCreateOrder: {
         name: 'stock - planner create order',
         display: (content) => {
            try {
               let data = JSON.parse(content);
               return data.customer.name;
            } catch (e) {
               return parseErrorMessage;
            }
         },
      },
      plannerDeleteOrder: {
         name: 'stock - planner delete order',
         display: (content) => {
            try {
               let data = JSON.parse(content);
               return data.planOrder.customer.name;
            } catch (e) {
               return parseErrorMessage;
            }
         },
      },
      plannerAddStockProduct: {
         name: 'stock - planner add stock product',
         display: (content) => {
            try {
               let data = JSON.parse(content);
               return data.name;
            } catch (e) {
               return parseErrorMessage;
            }
         },
      },
      plannerUpdateStockProduct: {
         name: 'stock - planner update stock product',
      },
      plannerDeleteStockProduct: {
         name: 'stock - planner delete stock product',
      },
   },

   shipment: {
      filterStatus: {
         name: 'shipment - filter status',
         display: (content) => content,
      },
      createShipment: {
         name: 'shipment - create shipment',
      },
      createShipmentInvoice: {
         name: 'shipment - create shipment invoice',
      },
      deleteShipmentInvoice: {
         name: 'shipment - delete shipment invoice',
      },
      createShipmentInvoiceRow: {
         name: 'shipment - create shipment invoice row',
      },
      deleteShipmentInvoiceRow: {
         name: 'shipment - delete shipment invoice row',
      },
      changeShipmentInvoiceRowProduct: {
         name: 'shipment - change shipment invoice row product',
      },
      updateNormalProduct: {
         name: 'shipment - edit normal product',
         display: (content) => {
            try {
               let data = JSON.parse(content);
               return data.product;
            } catch (e) {
               return parseErrorMessage;
            }
         },
      },
      createSpecialProduct: {
         name: 'shipment - create special product',
         display: (content) => {
            try {
               let data = JSON.parse(content);
               return data.product;
            } catch (e) {
               return parseErrorMessage;
            }
         },
      },
      updateSpecialProduct: {
         name: 'shipment - update speicial product',
         display: (content) => {
            try {
               let data = JSON.parse(content);
               return data.product;
            } catch (e) {
               return parseErrorMessage;
            }
         },
      },
      saveShipment: {
         name: 'shipment - save shipment',
         display: (content) => {
            try {
               let data = JSON.parse(content);
               return data.invoiceNumber;
            } catch (e) {
               return parseErrorMessage;
            }
         },
      },
      addSimpleNotes: {
         name: 'shipment - add simple notes',
      },
      duplicateShipment: {
         name: 'shipment - duplicate shipment',
      },
      deleteShipment: {
         name: 'shipment - delete shipment',
      },
      notifyCustomer: {
         name: 'shipment - notify customer',
      },
      generateDoc: {
         name: 'shipment - generate doc',
         display: (content) => {
            try {
               let data = JSON.parse(content);
               return data.docType;
            } catch (e) {
               return parseErrorMessage;
            }
         },
      },
      addAttachment: {
         name: 'shipment - add attachment',
      },
      deleteAttachment: {
         name: 'shipment - delete attachment',
      },
      createShipmentStatus: {
         name: 'shipment - create shipment status',
      },
      updateShipmentStatus: {
         name: 'shipment - update shipment status',
         display: (content) => {
            try {
               let data = JSON.parse(content);
               return data.status;
            } catch (e) {
               return parseErrorMessage;
            }
         },
      },
      deleteShipmentStatus: {
         name: 'shipment - delete shipment status',
      },
      createShipmentError: {
         name: 'shipment - create shipment error',
      },
      updateShipmentError: {
         name: 'shipment - update shipment error',
      },
      deleteShipmentError: {
         name: 'shipment - delete shipment error',
      },
      notifyCustomerError: {
         name: 'shipment - notify customer error',
      },
      createCommercialInvoice: {
         name: 'shipment - create commercial invoice',
         display: (content) => {
            try {
               let data = JSON.parse(content);
               return data.invoiceNumber;
            } catch (e) {
               return parseErrorMessage;
            }
         },
      },
      updateCommercialInvoice: {
         name: 'shipment - update commercial invoice',
      },
      exportPackingList: {
         name: 'shipment - export packing list',
      },
      exportCustomForm: {
         name: 'shipment - export custom form',
      },
      exportCommercialInvoice: {
         name: 'shipment - export commercial invoice',
      },
      exportLogisticsCost: {
         name: 'shipment - export logistics cost',
      },
   },

   materials: {
      download: { name: 'material - download' },
   },

   supplyChian: {
      checkProduction: {
         name: 'supply chian - check production',
         display: (content) => content,
      },
      checkLogistics: {
         name: 'supply chian - check logistics',
         display: (content) => content,
      },
   },

   manage: {
      updateProduct: { name: 'manage - update product' },
      updateProductLine: { name: 'manage - update productline' },
   },

   search: {
      name: 'search',
      display: (content) => {
         try {
            let data = JSON.parse(content);
            return (
               <div>
                  <div>{data.text}</div>
                  <div className="text-xs text-gray-500">{data.path}</div>
               </div>
            );
         } catch (e) {
            return parseErrorMessage;
         }
      },
   },
};
