import { useParams } from "react-router-dom";
import { FETCH_CHINA_STOCK_PRODUCTS } from "./graphql";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { useQuery } from "@apollo/client";
import { NavigationBar } from "components/base";
import ChinaStocksProductTableView from "./ChinaStocksProductTableView";
import { useState } from "react";
import { Select } from "components/Form";

const ChinaStockProducts = () => {
  const { stockId } = useParams();
  const { loading, error, data } = useQuery(FETCH_CHINA_STOCK_PRODUCTS, {
    variables: { stockId },
  });

  const [filter, setFilter] = useState("RECENT");

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <div className="flex flex-col flex-1 overflow-auto">
      <NavigationBar
        title={data.odooStock.name}
        rightButtons={
          <div>
            <Select value={filter} onChange={(e) => setFilter(e.target.value)}>
              <option value="RECENT">Recent 200 Stock Records</option>
              <option value="ALL">All</option>
            </Select>
          </div>
        }
      />
      <ChinaStocksProductTableView products={data.odooStock.products} filter={filter} />
    </div>
  );
};

export default ChinaStockProducts;
