import React, { createContext, useContext } from "react";
import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import Orders from "./Orders";
import Stocks from "./stocks";
import { useAmazonStockCategories } from "hooks/useAmazon";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import Invoices from "./Invoices";
import { AppContext } from "App";
import WarehouseRoutes from "./warehouse";
import Page from "components/Page";
import { LeftButtons } from "./Header";

const ALLOWED_AMAZON_USERS = [
  "charles@waboba.com",
  "yolina@waboba.com",
  "sharon@waboba.com",
  "stella@waboba.com",
  "axel@waboba.com",
  "alex.c@waboba.com",
  "jonatan@waboba.com",
  "rasmus@waboba.com",
  "anita@waboba.com",
  "tina@waboba.com",
  "caroline@waboba.com",
];

export const AmazonContext = createContext({});

function Index() {
  const { user } = useContext(AppContext);
  const isAllowed = ALLOWED_AMAZON_USERS.indexOf(user.email) > -1;

  const { loading, error, data } = useAmazonStockCategories();

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  if (!isAllowed) return <div className="flex flex-1 items-center justify-center font-bold text-2xl h-screen text-gray-600">You should not pass!</div>;

  return (
    <AmazonContext.Provider value={{ stockCategories: data.listAmazonStockCategories }}>
      <Routes>
        <Route element={<Layout />}>
          <Route path="orders" element={<Orders />} />
          <Route path="stocks/*" element={<Stocks />} />
          <Route path="invoices" element={<Invoices />} />
          <Route path="warehouses/*" element={<WarehouseRoutes />} />
          <Route path="*" element={<Navigate to="orders" />} />
        </Route>
      </Routes>
    </AmazonContext.Provider>
  );
}

const Layout = () => (
  <Page
    className="h-screen"
    childrenClassName="flex-1 overflow-auto flex flex-col"
    leftButtons={<LeftButtons />}
    rightButtons={
      <a href={`${process.env.REACT_APP_SERVER_ADMIN_URL}amazon_vendor_center/sharestocksetting`} target="_blank" rel="noreferrer">
        Share Stocks
      </a>
    }
  >
    <Outlet />
  </Page>
);

export default Index;
