import Page from "components/Page";
import React from "react";
import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import ALCWarehouse from "./alc";
import CharlesKendall from "./charlesKendall";
import { LeftButtons } from "./Header";
import OdooStock from "./odoo";
import StockInChina from "./StockInChina";
import Warehouses from "./warehouses";
import SupplierStocks from "./supplierStocks";
import ChinsStockPages from "./china";

function StockIndex() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="china/*" element={<ChinsStockPages />} />
        <Route path="odoo/*" element={<OdooStock />} />
        <Route path="warehouses/*" element={<Warehouses />} />
        <Route path="supplier/*" element={<SupplierStocks />} />
        <Route path="forecasted" element={<StockInChina />} />
        <Route path="alc/*" element={<ALCWarehouse />} />
        <Route path="charles-kendall/*" element={<CharlesKendall />} />
        <Route path="*" element={<Navigate to="china" />} />
      </Route>
    </Routes>
  );
}

const Layout = () => (
  <Page className="h-screen" leftButtons={<LeftButtons />}>
    <Outlet />
  </Page>
);

export default StockIndex;
