const Status = ({ state }) => <div className="font-bold capitalize">{state.replace("_", " ").toLowerCase()}</div>;

export function getOrderStateColor(state) {
  if (state === "PAID") return "text-green-600";
  if (state === "AWAITING_PAYMENT") return "text-sky-600";
  if (state === "VALIDATING_PAYMENT") return "text-orange-600";
  return "";
}

export default Status;
