import { useQuery } from "@apollo/client";
import { FETCH_CHINA_PRODUCT_STOCKS } from "./graphql";
import { InlineSpinner } from "components/Spinner";
import Errors from "components/Errors";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { OdooContext } from "OdooProvider";
import CharlesButton from "components/charles/base";
import { useModals } from "ModalProvider";
import UpdateSupplierStockRecordView from "./UpdateSupplierStockRecordView";
import { MdManageAccounts } from "react-icons/md";

const ChinaStocksProductTableView = ({ products, filter }) => {
  const { odooUrl } = useContext(OdooContext);
  const productOdooIds = products.map((product) => product.odooId);
  const { loading, error, data } = useQuery(FETCH_CHINA_PRODUCT_STOCKS, {
    variables: { productOdooIds },
    fetchPolicy: process.env.NODE_ENV === "development" ? "cache-first" : "network-only",
  });
  const updateSSModal = useModals();
  const containerRef = useRef(null);

  useEffect(() => {
    // scroll to bottom after data is loaded
    if (containerRef.current) containerRef.current.scrollTop = containerRef.current.scrollHeight;
  }, [data]);

  if (loading)
    return (
      <div className="relative p-20">
        <InlineSpinner />
      </div>
    );
  if (error) return <Errors error={error} />;

  function updateSupplierStockRecord(id) {
    updateSSModal.present({
      title: "Update Supplier Stock Record",
      center: true,
      maxWidth: "max-w-3xl",
      children: <UpdateSupplierStockRecordView id={id.split("-")[1]} hide={updateSSModal.hide} />,
    });
  }

  const chinaStocks = JSON.parse(data.chinaStocks);
  const res = filter === "RECENT" ? chinaStocks.slice(-200) : chinaStocks;

  const lastRow = res[res.length - 1];

  const rowBgColor = (type) => {
    if (type === "PO") return "bg-green-50 dark:bg-green-900";
    if (type === "PLAN") return "bg-sky-50 dark:bg-sky-900";
    if (type === "SS") return "bg-orange-50 dark:bg-orange-900";
    if (type === "OTHER") return "bg-purple-50 dark:bg-purple-900";
    return "";
  };

  function cellStyle(stock, product) {
    if (stock[`${product.odooId} QTY`] === 0) return "";
    return rowBgColor(stock.type);
  }

  const getKeys = (product) => (product.useSupplierStock ? ["QTY", "ODOO Forecasted", "SS Forecasted", "Forecasted"] : ["QTY", "ODOO Forecasted"]);

  return (
    <div className="overflow-auto" ref={containerRef}>
      <table className="whitespace-nowrap bg-white dark:bg-gray-800">
        <thead>
          <tr className="sticky bg-gray-100 dark:bg-gray-700 top-0 z-20">
            <th className="border-r  dark:border-gray-700 px-4 bg-gray-100 dark:bg-gray-700 z-10 sticky left-0" rowSpan={2}>
              Date & Reference
            </th>
            {products.map((product) => (
              <Fragment key={product.odooId}>
                <th className="px-4 text-right border-r border-l-4 dark:border-gray-700 pb-0" colSpan={getKeys(product).length}>
                  <div className=" flex items-center justify-end">
                    <a
                      className=" text-purple-600"
                      href={`${odooUrl}/web#id=${product.odooId}&menu_id=222&cids=1&action=563&model=product.product&view_type=form`}
                      target="_blank"
                    >
                      {product.number}
                    </a>
                    <a href={`${process.env.REACT_APP_SERVER_ADMIN_URL}product/product/${product.id}`} className="ml-2 text-xs opacity-70" target="_blank">
                      <MdManageAccounts />
                    </a>
                  </div>
                </th>
              </Fragment>
            ))}
          </tr>
          <tr className="text-right sticky bg-gray-100 dark:bg-gray-700 top-[24px] z-10">
            {products.map((product) => (
              <Fragment key={product.odooId}>
                {getKeys(product).map((key) => (
                  <th
                    className={`px-4 pt-0 text-right font-normal z-20
                    ${key === "Forecasted" ? "border-r border-gray-100 dark:border-gray-700" : ""}
                    ${key === "QTY" ? "border-l-4 " : ""}
                    `}
                    key={key}
                  >
                    {key}
                  </th>
                ))}
              </Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {res.map((stock, index) => (
            <tr key={index} className={`hover:bg-blue-50 hover:dark:bg-blue-900 hover:bg-blend-multiply`}>
              <td
                className={`border border-gray-100 dark:border-gray-700 px-4 sticky left-0 bg-white dark:bg-gray-800 z-10
              ${rowBgColor(stock.type)}
              `}
              >
                <div className="flex items-center space-x-2">
                  <div className="w-20">{stock.date}</div>
                  <div className="flex justify-between flex-1">
                    {stock.type === "SS" ? (
                      <CharlesButton onClick={() => updateSupplierStockRecord(stock.id)}>
                        {stock.id}[{stock.type}] {stock.origin}
                      </CharlesButton>
                    ) : (
                      <span className="text-xs opacity-70">
                        [{stock.type}] {stock.origin}
                      </span>
                    )}

                    <span
                      className={`font-bold pl-4 uppercase
                  ${stock.state === "done" ? "text-green-600" : "text-orange-600"}
                  `}
                    >
                      {stock.state}
                    </span>
                  </div>
                </div>
              </td>
              {products.map((product) => (
                <Fragment key={product.odooId}>
                  {getKeys(product).map((key) => (
                    <td
                      className={`text-right border border-gray-100 dark:border-gray-700 px-4
                    ${key === "QTY" ? " border-l-4 " : ""}
                    ${cellStyle(stock, product)}
                    `}
                      key={key}
                    >
                      {stock[`${product.odooId} ${key}`]}
                    </td>
                  ))}
                </Fragment>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr className=" sticky bottom-0 bg-white dark:bg-gray-700 z-10 font-semibold text-base">
            <td className="px-4 sticky left-0">Summary</td>
            {products.map((product) => (
              <Fragment key={product.odooId}>
                <td></td>
                <td className="text-right px-4">{lastRow[`${product.odooId} ODOO Forecasted`]}</td>
                {product.useSupplierStock && (
                  <Fragment>
                    <td className="text-right px-4">{lastRow[`${product.odooId} SS Forecasted`]}</td>
                    <td className="text-right px-4">{lastRow[`${product.odooId} Forecasted`]}</td>
                  </Fragment>
                )}
              </Fragment>
            ))}
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default ChinaStocksProductTableView;
