import { Route, Routes } from "react-router-dom";
import OrderListPage from "./OrderListPage";
import OrderDetailPage from "./OrderDetailPage";

const OrderPages = () => {
  return (
    <Routes>
      <Route index element={<OrderListPage />} />
      <Route path=":id" element={<OrderDetailPage />} />
    </Routes>
  );
};

export default OrderPages;
