import { gql, useQuery } from "@apollo/client";
import { NavigationBar } from "components/base";
import CharlesButton from "components/charles/base";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { useModals } from "ModalProvider";
import { useNavigate, useParams } from "react-router-dom";
import AddProductToPriceView from "./AddProductToPriceView";
import DeletePriceListView from "./DeletePriceListView";
import DuplicatePriceListView from "./DuplicatePriceListView";
import EditPriceListView from "./EditPriceListView";
import PriceListDetail from "./PriceListDetail";
import XLSX from "xlsx";
import { byNumber } from "utils/sort";
import { useContext } from "react";
import { CurrencyContext } from "CurrencyProvider";
import moment from "moment/moment";
import SyncPriceListView from "./SyncPriceListView";
import { FileSelector } from "components/Form";
import Excel from "exceljs";
import { Alert } from "components/Toast";
import BatchUpdatePriceView from "./BatchUpdatePriceView";
import { OdooContext } from "OdooProvider";
import { AppContext } from "App";
import { COST_VIEW_ONLY_USERS } from "pages/stock/permissions";

const FETCH_PRICE_LIST = gql`
  query FETCH_PRICE_LIST($id: ID!) {
    priceList(id: $id) {
      id
      name
      currency
      odooId
      createdBy {
        id
      }
    }
    salesPrices(priceListIds: [$id], prefetchProductCost: true) {
      id
      product {
        id
        odooId
        number
        name
        isActive
        productLine {
          id
          name
          image: bizPrimaryImage(size: "300x300")
        }
      }
      useFixedMargin
      fixedMargin
      discountPercentage
      price
      finalPrice
      state
      updatedAt
    }
  }
`;

const FETCH_PRICE_LIST_PRODUCT_COST = gql`
  query FETCH_PRICE_LIST_PRODUCT_COST($priceListId: ID!) {
    salesPrices(priceListIds: [$priceListId], prefetchProductCost: true) {
      id
      product {
        id
        productCost {
          cost
          date
        }
      }
    }
  }
`;

const PriceListDetailPage = () => {
  const { id } = useParams();
  const { user } = useContext(AppContext);
  const { latestExchangeRate } = useContext(CurrencyContext);

  const viewOnly = COST_VIEW_ONLY_USERS.includes(user.email);

  const { loading, error, data } = useQuery(FETCH_PRICE_LIST, {
    variables: { id },
    fetchPolicy: process.env.NODE_ENV === "development" ? "cache-first" : "network-only",
  });
  const fetchProductCost = useQuery(FETCH_PRICE_LIST_PRODUCT_COST, {
    variables: { priceListId: id },
  });
  const modal = useModals();
  const batchUpdatePriceModal = useModals();
  const navigate = useNavigate();
  const { odooUrl } = useContext(OdooContext);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const currency = data.priceList.currency;

  const productCosts = fetchProductCost.data ? fetchProductCost.data.salesPrices : [];

  const products = data.salesPrices
    .reduce((res, i) => (res.find((p) => p.id === i.product.id) ? res : [...res, i.product]), [])
    .filter((i) => i.isActive)
    .map((product) => {
      const price = data.salesPrices.find((i) => i.product.id === product.id);
      const foundSalesPriceWithProductCost = productCosts.find((i) => i.product.id === product.id);
      let productCost = null;
      let margin = null;
      if (price) {
        const priceInUsd = currency === "EUR" ? price.finalPrice / latestExchangeRate.rateEur : price.finalPrice;
        if (foundSalesPriceWithProductCost) {
          productCost = foundSalesPriceWithProductCost.product.productCost;
          margin = price.useFixedMargin ? price.fixedMargin : ((priceInUsd - productCost.cost) * 100) / priceInUsd;
        }
      }

      return { ...product, productCost, price, margin };
    })
    .sort(byNumber);

  function editPriceList() {
    modal.present({
      title: "Edit Price List",
      center: true,
      children: (
        <EditPriceListView
          id={id}
          complete={modal.hide}
          sourcePriceListId={id}
          initialName={data.priceList.name}
          initialCurrency={data.priceList.currency}
          initialOdooId={data.priceList.odooId}
        />
      ),
    });
  }

  function addProduct() {
    modal.present({
      title: "Add Product to Price List",
      center: true,
      children: <AddProductToPriceView priceListId={id} currency={currency} onSaved={modal.hide} />,
    });
  }

  function editProduct({ product, price, discountPercentage, useFixedMargin, fixedMargin, state }) {
    modal.present({
      title: "Edit Price",
      center: true,
      children: (
        <AddProductToPriceView
          priceListId={id}
          currency={currency}
          onSaved={modal.hide}
          initialData={{ product, price, discountPercentage, useFixedMargin, fixedMargin, state }}
        />
      ),
    });
  }

  function duplicatePriceList() {
    modal.present({
      title: "Duplicate Price List",
      center: true,
      children: <DuplicatePriceListView complete={duplicateCompleteHandler} sourcePriceListId={id} initialName={`duplicated ${data.priceList.name}`} />,
    });
  }

  function duplicateCompleteHandler(newPriceList) {
    modal.hide();
    navigate(`/price/price-list/${newPriceList.id}`);
  }

  function tryDelete() {
    modal.present({
      title: "Delete Price List",
      center: true,
      children: <DeletePriceListView complete={deleteCompleteHandler} cancel={modal.hide} priceListId={id} />,
    });
  }

  function deleteCompleteHandler() {
    modal.hide();
    navigate("/price/price-list");
  }

  function trySyncToOdoo() {
    modal.present({
      title: "Sync Price List to Odoo",
      center: true,
      children: <SyncPriceListView priceList={data.priceList} hide={modal.hide} />,
    });
  }

  function exportPriceList() {
    const excelData = products.map((i) => ({
      number: i.number,
      name: i.name,
      "cost (USD)": i.productCost.cost.toFixed(2),
      costUpdatedAt: i.productCost.date,
      [`price (${currency})`]: i.price.price.toFixed(2),
      discountPercentage: i.price.discountPercentage,
      priceUpdatedAt: moment(i.price.updatedAt).format("YYYY-MM-DD"),
      margin: `${i.margin.toFixed(2)}%`,
    }));
    const sheet = XLSX.utils.json_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, sheet, "price list");
    XLSX.writeFile(wb, data.priceList.name + ".xlsx");
  }

  const xlsxReader = new FileReader();
  const wb = new Excel.Workbook();

  xlsxReader.onload = function (e) {
    wb.xlsx
      .load(e.target.result)
      .then(handleImport)
      .catch((error) => {
        Alert("error", `Fail to read xlsx file. Error: ${error}`);
      });
  };

  function handleImport(wb) {
    const ws = wb.getWorksheet("price list");
    // read original data
    const rows = [];
    for (let i = 2; i <= ws.rowCount; i++) {
      const row = ws.getRow(i);
      let number = "";
      let name = "";
      let originalPrice = 0;
      let newPrice = 0;
      let originalDiscount = 0;
      let newDiscount = 0;
      // Try to parse price as float and ignore error.
      try {
        number = row.getCell("A").text;
        name = row.getCell("B").text;
        originalPrice = parseFloat(row.getCell("E").text);
        originalDiscount = parseFloat(row.getCell("F").text);

        newPrice = parseFloat(row.getCell("N").text);

        if (isNaN(newPrice)) {
          newPrice = originalPrice;
        }

        if (row.getCell("O").text) {
          newDiscount = parseFloat(row.getCell("O").text);
        } else {
          newDiscount = originalDiscount;
        }
      } catch (error) {
        Alert("error", `Fail to get value at row ${i}. Error: ${error}, the file may be corrupted.`);
      }

      rows.push({ id, number, name, originalPrice, newPrice, originalDiscount, newDiscount });
    }
    // console.log(rows);
    batchUpdatePriceModal.present({
      title: "Import New Prices",
      subtitle: data.priceList.name,
      children: <BatchUpdatePriceView priceListId={id} rows={rows} hide={batchUpdatePriceModal.hide} />,
      maxWidth: "max-w-6xl",
      center: true,
      isBeingPresented: true,
    });
  }

  return (
    <>
      <NavigationBar
        title={"Price List: " + data.priceList.name + ` (${currency})`}
        backTo="/price/price-list"
        rightButtons={
          viewOnly ? null : (
            <div className="flex space-x-6">
              {!viewOnly ? (
                <>
                  <CharlesButton onClick={addProduct}>+ Add Product</CharlesButton>
                  <CharlesButton onClick={editPriceList}>Edit</CharlesButton>
                </>
              ) : null}
              <CharlesButton onClick={duplicatePriceList}>Duplicate</CharlesButton>
              <FileSelector
                bold
                title="Import"
                accept=".xlsx"
                onChange={async (e) => {
                  const file = e.target.files[0];
                  xlsxReader.readAsArrayBuffer(file);
                  e.target.value = null;
                }}
              />
              <CharlesButton onClick={exportPriceList}>Export</CharlesButton>
              {data.priceList.odooId ? (
                <>
                  <CharlesButton onClick={trySyncToOdoo}>Sync to Odoo</CharlesButton>
                  <a
                    className="text-purple-600"
                    href={`${odooUrl}/web#id=${data.priceList.odooId}&cids=1&menu_id=408&action=172&model=product.pricelist&view_type=form`}
                    target="_blank"
                    rel="noreferer"
                  >
                    Odoo Pirce List
                  </a>
                </>
              ) : null}
              {!viewOnly ? (
                <CharlesButton danger onClick={tryDelete}>
                  Delete
                </CharlesButton>
              ) : null}
            </div>
          )
        }
      />
      <div className="relative flex-1 overflow-auto">
        <PriceListDetail
          priceListOdooId={data.priceList.odooId}
          fetchProductCostLoading={fetchProductCost.loading}
          products={products}
          currency={currency}
          editProduct={!viewOnly ? editProduct : null}
          editPriceList={editPriceList}
        />
      </div>
    </>
  );
};

export default PriceListDetailPage;
