import moment from "moment";
import { v4 } from "uuid";

const PlanLinesTable = ({ plan }) => {
  const additionalPlans = plan.children || [];

  console.log("additionalPlans", additionalPlans);

  const lines = [...plan.lines, ...additionalPlans.flatMap((i) => i.lines)].reduce((res, i) => {
    const found = res.find((x) => x.product.id === i.product.id);
    if (found)
      return res.map((x) =>
        x.id === found.id
          ? {
              ...x,
              finalQty: x.finalQty + i.finalQty,
              totalCbm: x.totalCbm + i.totalCbm,
              totalCost: x.totalCost + i.totalCost,
              totalPrice: x.totalPrice + i.totalPrice,
              readyDate: moment.max([moment(x.readyDate), moment(i.readyDate)]),
            }
          : x
      );
    return [...res, { ...i, id: v4() }];
  }, []);

  console.log(lines.filter((i) => i.product.number === "701C01"));

  return (
    <table>
      <thead>
        <tr className=" whitespace-nowrap">
          <th>Product</th>
          <th className="text-right">QTY / Carton</th>
          <th className="text-right">QTY</th>
          <th className="text-right">CBM</th>
          <th className="text-right">Ready Date</th>
          <th className="text-right">Unit Cost</th>
          <th className="text-right">Total Cost</th>
          {plan.orderType === "FOB_ORDER" ? (
            <>
              <th className="text-right w-20">Sales Price</th>
              <th className="text-right w-20">Total Price</th>
            </>
          ) : null}
        </tr>
      </thead>
      <tbody>
        {lines.map((line, index) => (
          <tr key={index}>
            {line.product ? (
              <td>
                [{line.product.number}] {line.product.name}
              </td>
            ) : (
              <td> - </td>
            )}
            <td className="text-right">{line.product ? line.product.quantityPerCarton : " - "}</td>
            <td className="text-right">{line.finalQty}</td>
            <td className="text-right">{line.totalCbm.toFixed(3)}</td>
            <td className="text-right">{line.readyDate ? moment(line.readyDate).format("YYYY-MM-DD") : "-"}</td>
            <td className="text-right">{line.unitCost.toFixed(2)}</td>
            <td className="text-right">{line.totalCost.toFixed(2)}</td>
            {plan.orderType === "FOB_ORDER" ? (
              <>
                <td className="text-right">{line.salesPrice}</td>
                <td className="text-right">{line.totalPrice ? line.totalPrice.toFixed(2) : "0.00"}</td>
              </>
            ) : null}
          </tr>
        ))}

        {plan.shippingCost ? (
          <tr>
            <td colSpan={7} className="text-right py-2">
              <div className="flex space-x-2 justify-end items-center">
                <label>Shipping Cost: </label>
                <span className="text-base">{plan.shippingCost}</span>
              </div>
            </td>
          </tr>
        ) : null}

        <tr className="text-base whitespace-nowrap">
          <td className="border-y dark:border-gray-700" colSpan={2}>
            Total
          </td>
          <td className="border-y dark:border-gray-700 text-right">{plan.finalTotalQty}</td>
          <td className="border-y dark:border-gray-700 text-right">{plan.finalTotalCbm.toFixed(3)}</td>
          <td className="border-y dark:border-gray-700 text-right">{plan.finalReadyDate ? moment(plan.finalReadyDate).format("YYYY-MM-DD") : null}</td>
          <td className="text-right border-y dark:border-gray-700"></td>
          <td className="text-right border-y dark:border-gray-700">{plan.finalTotalCost.toFixed(2)}</td>
          {plan.orderType === "FOB_ORDER" ? (
            <>
              <td className="text-right border-y dark:border-gray-700"></td>
              <td className="text-right border-y dark:border-gray-700">{plan.finalTotalPrice.toFixed(2)}</td>
            </>
          ) : null}
        </tr>
      </tbody>
    </table>
  );
};

export default PlanLinesTable;
