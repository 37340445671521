import React, { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import Slider from "react-slick";
import Spinner from "components/Spinner";

const FETCH_ALL_PROMO_IMAGES = gql`
  query FETCH_ALL_PROMO_IMAGES {
    randomPromoImages(count: 4) {
      id
      url(size: "1680x1680")
    }
  }
`;

function TV() {
  const { loading, data } = useQuery(FETCH_ALL_PROMO_IMAGES, {
    pollInterval: process.env.NODE_ENV === "development" ? 1000 : 10 * 1000,
  });
  const [lifestyleimages, setLifestyleimages] = useState([]);
  const [allowUpdate, setAllowUpdate] = useState(false);

  useEffect(() => {
    if (data && allowUpdate) {
      setLifestyleimages(data.randomPromoImages);
    }
  }, [data]);

  const settings = {
    fade: true,
    autoplay: true,
    autoplaySpeed: process.env.NODE_ENV === "development" ? 2000 : 10000,
    infinite: true,
    pauseOnHover: process.env.NODE_ENV === "development" ? false : true,
    arrows: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    beforeChange() {
      setAllowUpdate(false);
    },
    afterChange(Index) {
      setAllowUpdate(Index <= 2 || lifestyleimages.length === 0);
    },
  };

  if (loading) return <Spinner />;

  return (
    <Slider className="h-screen w-full bg-white dark:bg-gray-800" {...settings}>
      {lifestyleimages.map((i) => (
        <div key={i.id}>
          <div className="w-full h-screen bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url(${i.url})` }}></div>
        </div>
      ))}
    </Slider>
  );
}

export default TV;
