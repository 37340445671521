import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import { useContext } from "react";
import List from "./List";
import Detail from "./Detail";
import { AppContext } from "App";
import DocPage from "./docCpcPage/DocPage";
import CpcPage from "./docCpcPage/CpcPage";

export const ALLOWED_USERS = [
  "axel@waboba.com",
  "jade@waboba.com",
  "sam@waboba.com",
  "chrissie@waboba.com",
  "cherry@waboba.com",
  "livi@waboba.com",
  "alex.c@waboba.com",
  "sharon@waboba.com",
  "yolina@waboba.com",
  "stella@waboba.com",
  "charles@waboba.com",
  "shawn@waboba.com",
];

function Index() {
  const { user } = useContext(AppContext);
  const isAllowed = ALLOWED_USERS.indexOf(user.email) > -1;

  if (!isAllowed) return <div className="flex flex-1 items-center justify-center font-bold text-2xl h-screen text-gray-600">Not Allowed.</div>;

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route index element={<List />} />
        <Route path=":id" element={<Detail />} />
        <Route path=":id/doc" element={<DocPage />} />
        <Route path=":id/cpc" element={<CpcPage />} />
      </Route>
    </Routes>
  );
}

export default Index;
