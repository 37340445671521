import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import Spinner, { InlineSpinner } from "components/Spinner";
import Errors from "components/Errors";
import { Button } from "components/base.js";
import QcStatus from "components/QcStatus.js";
import { FETCH_QC_REPORT, SAVE_REPORT_FACTORY } from "../graphql.js";
import { Alert } from "components/Toast";
import FactorySelector from "components/FactorySelector";
import Page from "components/Page";
import useQcImageMetaCategories from "hooks/useQcImageMetaCategories";
import { ProductImagesV2 } from "components/ProductImages.js";
import FinalQcReportEditForm from "./FinalQcReportEditForm.js";

function FinalQcReportPage() {
  const { id } = useParams();
  const fromUrl = new URLSearchParams(window.location.search).get("from");
  console.log("fromUrl", fromUrl);
  const metaCategoriesQuery = useQcImageMetaCategories();
  const { loading, error, data } = useQuery(FETCH_QC_REPORT, {
    variables: { id },
  });

  const [saveFactory, saveFactoryRes] = useMutation(SAVE_REPORT_FACTORY, {
    onCompleted: () => Alert("success", "Saved."),
    onError: (error) => Alert("error", error.message),
  });
  if (loading || metaCategoriesQuery.loading) return <Spinner />;
  if (error) return <Errors error={error} />;
  if (metaCategoriesQuery.error) return <Errors error={metaCategoriesQuery.error} />;

  const report = data.report;

  const productName = report.product.productLine ? report.product.productLine.name : report.product.name;

  return (
    <Page
      backTo={fromUrl ?? "/qc"}
      title={`SO#${report.invoice.number}`}
      subtitle={`${productName} ✕ ${report.qty}`}
      rightButtons={<Button title="Preview 预览" link={`/qc/final/${report.id}/preview`} />}
    >
      <div className="dark:text-gray-300 text-sm">
        <div className="m-4 lg:m-6">
          <div className="flex mb-1">
            <ProductImagesV2 images={report.product.images} />
          </div>

          <div className="font-semibold text-base">
            [{report.product.number}] {report.product.name}
          </div>

          <div className="mt-4">
            <label className="pr-3">Customer: </label>
            <span>{report.invoice.customer.name}</span>
          </div>

          <div className="py-4 flex space-x-4">
            <div className="flex items-center">
              <label className="pr-3">Factory: </label>
              <FactorySelector value={report.factory.id} onChange={(factoryId) => saveFactory({ variables: { id, factoryId } })} />
            </div>
            {saveFactoryRes.loading ? <InlineSpinner text={null} size={21} /> : null}
          </div>
          <QcStatus status={report.status} />
        </div>

        <FinalQcReportEditForm metaCategories={metaCategoriesQuery.metaCategories} report={data.report} />
      </div>
    </Page>
  );
}

export default FinalQcReportPage;
