import { gql } from "@apollo/client";

export const FETCH_SUPPLIER_PENDING_INVENTORY_UPDATE_REQUEST = gql`
  query FETCH_SUPPLIER_PENDING_INVENTORY_UPDATE_REQUEST($id: ID!) {
    supplierStockUpdateRequest(id: $id) {
      id
      stocks {
        updateType
        supplierStockRecordId
        product {
          id
          name
          number
        }
        qty
        readyDate
        remark
      }
      state
    }
  }
`;

export const CONFIRM_SUPPLIER_STOCK_UPDATE_REQUEST = gql`
  mutation CONFIRM_SUPPLIER_STOCK_UPDATE_REQUEST($id: ID!, $state: String!) {
    confirmSupplierStockUpdateRequest(id: $id, state: $state) {
      request {
        id
        state
      }
    }
  }
`;

export const FETCH_PRODUCT_SUPPLIER_STOCK = gql`
  query FETCH_PRODUCT_SUPPLIER_STOCK($id: ID!) {
    product(id: $id) {
      id
      odooId
      number
      name
      supplierStockForecast
      supplierStocks {
        id
        qty
        readyDate
        supplier {
          id
          name
        }
        usages {
          id
          plan {
            name
            wisCustomer {
              name
            }
            odooObject {
              objectName
              objectType
            }
            state
          }
          qty
        }
        remark
      }
    }
  }
`;
