import { isInternalUser } from "./hooks/user";
import { AiFillAmazonCircle, AiFillRobot } from "react-icons/ai";
import { RiBarcodeFill, RiListSettingsFill, RiMoneyDollarCircleLine } from "react-icons/ri";
import { BsCalendar3, BsCalendar4Week, BsFillPatchCheckFill, BsImages, BsTruck } from "react-icons/bs";
import { FaFileInvoiceDollar, FaStore, FaTruck } from "react-icons/fa";
import { SiProducthunt, SiSalesforce, SiShopify } from "react-icons/si";
import { IoGitMerge } from "react-icons/io5";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { FiCircle } from "react-icons/fi";
import { GoShieldCheck } from "react-icons/go";
import { PiPackageBold } from "react-icons/pi";

const CoreStaffs = [
  "axel@waboba.com",
  "stella@waboba.com",
  "charles@waboba.com",
  "sam@waboba.com",
  "chrissie@waboba.com",
  "cherry@waboba.com",
  "alex.c@waboba.com",
  "jade@waboba.com",
  "sharon@waboba.com",
  "yolina@waboba.com",
  "livi@waboba.com",
];

export function isCoreMember(user) {
  if (!user) return false;
  return user.isSuperuser || user.isStaff || CoreStaffs.includes(user.email);
}

export function isQc(user) {
  if (!user) return false;
  return user.isSuperuser || user.isStaff || inGroup(user, "qc") || inGroup(user, "acro qc");
}

export function inGroup(user, groupName) {
  if (!user) return false;
  return user.groups.findIndex((group) => group.name.toLowerCase() === groupName.toLowerCase()) > -1;
}

const officeRoutes = (user) => {
  if (isCoreMember(user))
    return [
      {
        text: "Office",
        path: [
          {
            path: "/tv",
            text: "TV",
            icon: "dashboard",
            color: "text-red-400",
            target: "_blank",
          },
        ],
      },
    ];
  return [];
};

const toolsRoutes = (user) => {
  if (isInternalUser(user))
    return [
      {
        text: "Tools",
        path: [
          {
            path: "/tools/shortlinks",
            text: "Short Links",
            icon: "link",
            color: "text-yellow-400",
          },
          {
            path: "/barcode",
            text: "Barcode",
            icon: <RiBarcodeFill size={14} />,
            color: "text-indigo-400",
            target: "_blank",
          },
          {
            path: "/tools/shopify",
            text: "Shopify",
            icon: <SiShopify size={14} />,
            color: "text-blue-400",
          },
          {
            path: "/tools/patentai",
            text: "Patent AI",
            icon: <AiFillRobot size={14} />,
            color: "text-green-400",
          },
          {
            path: "/tools/package",
            text: "Package Tracking",
            icon: <PiPackageBold size={14} />,
            color: "text-orange-400",
          },
        ],
      },
    ];
  return [];
};

const qcRoutes = (user) =>
  user
    ? [
        {
          text: "QC",
          path: [
            {
              path: "/qc",
              text: "Reports",
              icon: "paste",
              color: "text-pink-400",
            },
          ],
        },
      ]
    : [];

const csrRoutes = (user) => {
  if (isCoreMember(user))
    return [
      {
        text: "ESG",
        path: [
          {
            path: "/csr",
            text: "CSR",
            icon: "factory",
            color: "text-green-400",
          },
          {
            path: "/assessment",
            text: "Assessment",
            icon: "factory",
            color: "text-blue-400",
          },
        ],
      },
    ];
  return [];
};

const productRoutes = (user) => {
  if (isCoreMember(user))
    return [
      {
        text: "Products",
        path: [
          {
            path: "/products",
            text: "Products",
            icon: "ball",
            color: "text-pink-400",
          },
          // {
          //   path: "/products-new",
          //   text: "Products New",
          //   icon: "ball",
          //   color: "text-blue-400",
          // },
          {
            path: "/product-list",
            text: "Product List",
            icon: "view-list",
            color: "text-red-400",
          },
          {
            path: "/calculate",
            text: "Calculate",
            icon: "calculator",
            color: "text-blue-400",
          },
          {
            path: "/stock",
            text: "Stock",
            icon: "stock",
            color: "text-green-400",
          },
          {
            path: "/price",
            text: "Costs",
            icon: <RiMoneyDollarCircleLine size={14} />,
            color: "text-yellow-400",
          },
          {
            path: "/test-reports",
            text: "Test Reports",
            icon: <GoShieldCheck size={14} />,
            color: "text-teal-400",
          },
          {
            path: "/manage",
            text: "Manage",
            icon: <RiListSettingsFill size={14} />,
            color: "text-blue-400",
          },
          {
            path: "/validate",
            text: "Validate",
            icon: <BsFillPatchCheckFill size={14} />,
            color: "text-green-400",
          },
        ],
      },
    ];
  if (inGroup(user, "internal"))
    return [
      {
        text: "Products",
        path: [
          {
            path: "/products",
            text: "Products",
            icon: "ball",
            color: "text-pink-400",
          },
          {
            path: "/calculate",
            text: "Calculate",
            icon: "calculator",
            color: "text-blue-400",
          },
          {
            path: "/product-list",
            text: "Product List",
            icon: "view-list",
            color: "text-red-400",
          },
          {
            path: "/stock",
            text: "Stock",
            icon: "stock",
            color: "text-green-400",
          },
          {
            path: "/price",
            text: "Costs",
            icon: <RiMoneyDollarCircleLine size={14} />,
            color: "text-yellow-400",
          },
        ],
      },
    ];
  return [];
};

const supplyChainRoutes = (user) => {
  if (isInternalUser(user))
    return [
      {
        text: "Supply Chain",
        path: [
          {
            path: "/order-scheduler",
            text: "Order Scheduler",
            icon: <BsCalendar3 size={14} />,
            color: "text-pink-400",
          },
          {
            path: "/supplier-calendar",
            text: "Production Calendar",
            icon: <BsCalendar4Week size={14} />,
            color: "text-red-400",
          },
          {
            path: "/production",
            text: "Production",
            icon: <SiProducthunt size={14} />,
            color: "text-teal-400",
          },
          {
            path: "/sub-supplier",
            text: "Sub-Supplier",
            icon: <IoGitMerge size={14} />,
            color: "text-blue-400",
          },
        ],
      },
    ];
  return [];
};

const ordersRoute = (user) => {
  if (user.isSuperuser || user.isStaff || inGroup(user, "internal"))
    return [
      {
        text: "Orders",
        path: [
          {
            path: "/customers",
            text: "Customers",
            icon: "user",
            color: "text-green-400",
          },
          {
            path: "/shipment",
            text: "Shipments",
            icon: "ship",
            color: "text-pink-400",
          },
          {
            path: "/odoo",
            text: "Odoo",
            icon: <FiCircle size={14} />,
            color: "text-purple-400",
          },
          {
            path: "/amazon",
            text: "Amazon Vendor Center",
            icon: <AiFillAmazonCircle size={14} />,
            color: "text-orange-400",
          },
          {
            path: "/orders",
            text: "Sales Orders",
            icon: <HiOutlineShoppingBag size={14} />,
            color: "text-red-400",
          },
          {
            path: "/alc",
            text: "ALC",
            icon: <BsTruck size={14} />,
            color: "text-indigo-400",
          },
          {
            path: "/fsi",
            text: "FSI",
            icon: <FaTruck size={14} />,
            color: "text-indigo-400",
          },
          {
            path: "/inc",
            text: "INC",
            icon: <FaStore size={14} />,
            color: "text-blue-400",
          },
        ],
      },
    ];
  return [];
};

const materialsRoute = (user) => {
  if (isInternalUser(user))
    return [
      {
        text: "Materials",
        path: [
          {
            path: "/materials/beautyshots",
            text: "Beautyshots",
            icon: <BsImages />,
            color: "text-pink-400",
          },
          {
            path: "/materials/artwork-copy",
            text: "Artwork Copy",
            icon: "format-text-size",
            color: "text-blue-400",
          },
          {
            path: "/materials/lifestyle-images",
            text: " Lifestyle Images",
            icon: "beverage",
            color: "text-green-400",
          },
          {
            path: "/materials/logos",
            text: " Logos",
            icon: "color-palette",
            color: "text-green-400",
          },
          {
            path: "/materials/videos",
            text: "Videos",
            icon: "video",
            color: "text-yellow-400",
          },
        ],
      },
    ];
  return [];
};

const analyticsRoute = (user) => {
  if (isInternalUser(user))
    return [
      {
        text: "Analytics",
        path: [
          {
            path: "/analytics/sales",
            text: "Sales",
            icon: "chart",
            color: "text-indigo-400",
          },
          {
            path: "/analytics/qc",
            text: "QC",
            icon: "chart",
            color: "text-teal-400",
          },
          {
            path: "/analytics/production",
            text: "Production",
            icon: "chart",
            color: "text-teal-400",
          },
          {
            path: "/analytics/grading",
            text: "Supplier Grading",
            icon: "chart",
            color: "text-green-400",
          },
          {
            path: "/analytics/odoo",
            text: "Odoo",
            icon: "chart",
            color: "text-purple-400",
          },
        ],
      },
    ];
  return [];
};

const adminRoutes = (user) => {
  if (user.isSuperuser || user.isStaff)
    return [
      {
        text: "Manage",
        path: [
          {
            path: "/manage/accounts",
            text: "Accounts",
            icon: "user",
          },

          {
            blank: true,
            path: process.env.REACT_APP_SERVER_ADMIN_URL,
            text: "Admin",
            icon: "shield",
            color: "text-green-400",
          },
          {
            path: "/it",
            text: "IT",
            icon: "code",
            color: "text-pink-400",
          },
        ],
      },
    ];

  return [];
};

const retailerRoutes = (user) => {
  if (isInternalUser(user))
    return [
      {
        text: "[EU] Retailer Store",
        path: [
          {
            path: "/retailer-store/agents",
            text: "Agents",
            icon: "user",
            color: "text-blue-400",
          },
          {
            path: "/retailer-store/retailers",
            text: "Retailers",
            icon: "user",
            color: "text-green-400",
          },
          {
            path: "/retailer-store/orders",
            text: "Orders",
            icon: <FaFileInvoiceDollar size={14} />,
            color: "text-purple-400",
          },
        ],
      },
    ];

  return [];
};

export const getUserRoutes = (user) => {
  if (!user) return [];
  const routes = [
    ...productRoutes(user),
    ...ordersRoute(user),
    ...retailerRoutes(user),
    ...supplyChainRoutes(user),
    ...qcRoutes(user),
    ...csrRoutes(user),
    ...materialsRoute(user),
    ...officeRoutes(user),
    ...toolsRoutes(user),
    ...analyticsRoute(user),

    ...adminRoutes(user),
  ];
  return routes;
};
