import { useMutation, useQuery } from "@apollo/client";
import { parseError } from "apollo";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { Alert } from "components/Toast";
import CharlesButton from "components/charles/base";
import moment from "moment";
import { useState } from "react";
import { CONFIRM_SUPPLIER_STOCK_UPDATE_REQUEST, FETCH_SUPPLIER_PENDING_INVENTORY_UPDATE_REQUEST } from "./graphql";

const ReviewRequestView = ({ id, hide }) => {
  const { loading, data, error } = useQuery(FETCH_SUPPLIER_PENDING_INVENTORY_UPDATE_REQUEST, {
    variables: { id },
  });
  const [confirmType, setConfirmType] = useState(null);
  const [confirm, confirmRes] = useMutation(CONFIRM_SUPPLIER_STOCK_UPDATE_REQUEST, {
    onCompleted() {
      Alert("success", "Request has been accepted");
      hide();
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={parseError(error)} />;

  function confirmHandler(newState) {
    setConfirmType(newState);
    confirm({ variables: { id, state: newState } });
  }

  return (
    <div>
      <div className="-mx-2 text-sm">
        <table>
          <thead>
            <tr>
              <th>Type</th>
              <th>Product</th>
              <th className="text-right">Qty</th>
              <th className="text-right">Ready Date</th>
              <th className="text-right">Remark</th>
            </tr>
          </thead>
          <tbody>
            {data.supplierStockUpdateRequest.stocks.map((i, index) => (
              <tr key={index} className="border-b">
                <td>{i.updateType}</td>
                <td>
                  [{i.product.number}] {i.product.name}
                </td>
                <td className="text-right">{i.qty}</td>
                <td className="text-right">{moment(i.readyDate).format("YYYY-MM-DD")}</td>
                <td className="text-right">{i.remark}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {data.supplierStockUpdateRequest.state === "PENDING" ? (
        <>
          <div className="mt-6 text-sm">
            <p>You accept or reject an inventory update request.</p>
            <p className="font-semibold text-orange-600 pt-2">
              By accepting the request, you are confirming the inventory update. This will update our supplier stock and auto recompute the production plan
              which are in AWAITING TO CONRIFMR and PLANNING state. This action cannot be undone.
              <br />
              Rejecting means this request will be ignore and marked as REJECTED, nothing else will happen.
            </p>
          </div>
          <div className="flex space-x-6 mt-8">
            <CharlesButton loading={confirmRes.loading && confirmType === "ACCEPTED"} disabled={confirmRes.loading} onClick={() => confirmHandler("ACCEPTED")}>
              Accept
            </CharlesButton>
            <CharlesButton
              loading={confirmRes.loading && confirmType === "REJECTED"}
              disabled={confirmRes.loading}
              danger
              onClick={() => confirmHandler("REJECTED")}
            >
              Reject
            </CharlesButton>
          </div>
        </>
      ) : (
        <div className="mt-8 font-semibold text-teal-600">
          You have already {data.supplierStockUpdateRequest.state === "ACCEPTED" ? "accepted" : "rejected"} this request.
        </div>
      )}
    </div>
  );
};

export default ReviewRequestView;
