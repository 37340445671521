import React, { useState } from 'react';
import ShipmentTitle from './ShipmentTitle';
import { Button } from './base';
import { dateAfter, nextWeekday, getDatesEvery } from '../utils/date';

const weekdays = [
   'Sunday',
   'Monday',
   'Tuesday',
   'Wednesday',
   'Thursday',
   'Friday',
   'Saturday',
];

export const priceCalculateMethodNames = {
   PER_BILL: 'PER_BILL',
   PER_M3: 'PER_M3',
   PER_KG: 'PER_KG',
   GP20: 'GP20',
   GP40: 'GP40',
   HQ40: '40HQ',
   HQ45: '45HQ',
};

function categoryPrice(cat, weight, cbm) {
   return cat.prices.reduce((res, item) => {
      let itemTotal = 0;
      if (item.calculateMethod === priceCalculateMethodNames.PER_KG) {
         item.levels.forEach((i) => {
            if (weight > parseFloat(i.level)) {
               itemTotal = parseFloat(i.unitPrice) * weight;
            }
         });
         if (isNaN(itemTotal)) itemTotal = 0;
      } else if (item.calculateMethod === priceCalculateMethodNames.PER_M3) {
         itemTotal = parseFloat(item.unitPrice) * cbm;
      } else {
         itemTotal = parseFloat(item.unitPrice);
      }
      res = parseFloat(res) + itemTotal;
      return res.toFixed(2);
   }, 0);
}

function categoryPriceInUsd(cat, weight, cbm) {
   let total = categoryPrice(cat, weight, cbm);
   if (cat.currency !== 'USD') return (total / cat.rateToUsd).toFixed(2);
   return total;
}

export function totalPriceInUsd(forwarderRoute, weight, cbm) {
   let total = forwarderRoute.priceCategories.reduce((res, cat) => {
      res += parseFloat(categoryPriceInUsd(cat, weight, cbm));
      return res;
   }, 0);
   return total.toFixed(2);
}

export const ForwarderRoutePriceCategory = ({
   className = '',
   priceCategory,
   showTotal = false,
   weight = 0,
   cbm = 0,
}) => {
   return (
      <div className={`${className} flex flex-col justify-between`}>
         <div>
            <div className="flex justify-between">
               <div className="pb-2 font-bold">{priceCategory.name}</div>
               <div>{priceCategory.currency}</div>
            </div>
            <div>
               {priceCategory.prices.map((item, index) => (
                  <div
                     key={index}
                     className="flex items-baseline py-1 border-b border-gray-200 dark:border-gray-700"
                  >
                     <label className="w-48 mr-4">{item.name}:</label>
                     <Price {...item} weight={weight} />
                  </div>
               ))}
            </div>
         </div>
         {showTotal ? (
            <div className="flex items-center justify-between mt-4 text-sm">
               <label htmlFor="">Total: </label>
               <div className="text-right">
                  {priceCategory.currency !== 'USD' ? (
                     <div>
                        {categoryPrice(priceCategory, weight, cbm)}{' '}
                        {priceCategory.currency}
                     </div>
                  ) : null}
                  <div>
                     {priceCategory.currency === 'USD'
                        ? categoryPrice(priceCategory, weight, cbm)
                        : categoryPriceInUsd(priceCategory, weight, cbm)}{' '}
                     USD
                  </div>
               </div>
            </div>
         ) : null}
      </div>
   );
};

const Price = ({ calculateMethod, unitPrice, levels = [], weight }) => {
   switch (calculateMethod) {
      case priceCalculateMethodNames.PER_KG:
         return (
            <div>
               <div className="flex items-baseline justify-between">
                  <label className="text-xs w-16 mr-2">{calculateMethod}</label>
                  <div>
                     {levels.map((level, index) => (
                        <div key={index} className="flex items-baseline">
                           <label className="text-xs whitespace-no-wrap mr-4">
                              {level.level}+ KG
                           </label>
                           <span>{level.unitPrice}</span>
                        </div>
                     ))}
                  </div>
               </div>
               {weight ? (
                  <div className="mt-2 flex items-baseline justify-end">
                     <label className="mr-4">G.W.:</label>
                     <span>{weight} KG</span>
                  </div>
               ) : null}
            </div>
         );
      default:
         return (
            <div className="flex flex-1 items-baseline justify-between">
               <label className="text-xs w-16 mr-2">{calculateMethod}</label>
               <div className="text-right">{unitPrice}</div>
            </div>
         );
   }
};

export const scheduleString = (unit, value) => {
   switch (unit) {
      case 'weekday':
         return weekdays[value];
      default:
         return `${value} day`;
   }
};

export const calculateCutoffDate = (forwarderRoute, cargoReadyDate) => {
   if (forwarderRoute.route.scheduleUnit === 'weekday') {
      return nextWeekday(cargoReadyDate, forwarderRoute.route.scheduleValue);
   } else {
      if (forwarderRoute.route.scheduleValue > 1) {
         const sampleDate = new Date(forwarderRoute.route.scheduleSample);
         let dates = getDatesEvery(
            sampleDate,
            dateAfter(cargoReadyDate, 2),
            forwarderRoute.route.scheduleValue
         ).map((d) => d.toLocaleDateString());
         let offset = forwarderRoute.route.scheduleValue;
         if (
            dates.indexOf(new Date(cargoReadyDate).toLocaleDateString()) === -1
         ) {
            offset += 1;
         }
         return dateAfter(cargoReadyDate, offset);
      }
      return dateAfter(cargoReadyDate, 1);
   }
};

export const calculateEtd = (forwarderRoute, cargoReadyDate) => {
   return dateAfter(
      calculateCutoffDate(forwarderRoute, cargoReadyDate),
      forwarderRoute.route.etd
   );
};

export const calculateEta = (forwarderRoute, cargoReadyDate) => {
   const etd = calculateEtd(forwarderRoute, cargoReadyDate);
   return dateAfter(etd, forwarderRoute.route.deliveryTime);
};

export const ShippingCompanyRoute = ({ className = '', route }) => (
   <div className={className}>
      <span className="mr-2">[{route.transportation}]</span>
      <span className="mr-2">
         {route.shippingCompany.name}
         {route.name ? ` - ${route.name}` : null}
      </span>
      <span>{route.name}</span>
   </div>
);

const ForwarderRoute = ({
   className = '',
   forwarderRoute,
   shipment,
   cargoReadyDate,
   defaultShowPriceDetail = true,
}) => {
   const [showPriceDetail, setShowPriceDetail] = useState(
      defaultShowPriceDetail
   );
   const ports = forwarderRoute.route.nodes.map((node) => node.port.name);

   return (
      <div className={className}>
         <div className="text-gray-600 text-xs">
            Last updated at{' '}
            {new Date(forwarderRoute.createdAt).toLocaleString()}
         </div>
         <ShippingCompanyRoute
            className="text-lg font-bold mt-2"
            route={forwarderRoute.route}
         />

         {ports.length > 0 && (
            <div className="text-gray-600 text-xs mt-2">
               {ports.join('  →  ')}
            </div>
         )}

         <div className="mt-4 flex items-center ">
            <label className="mr-4">Cutoff Day:</label>
            <div>
               Every{' '}
               {scheduleString(
                  forwarderRoute.route.scheduleUnit,
                  forwarderRoute.route.scheduleValue
               )}
            </div>
            {cargoReadyDate ? (
               <div className="ml-4">
                  {calculateCutoffDate(
                     forwarderRoute,
                     cargoReadyDate
                  ).toLocaleDateString()}
               </div>
            ) : null}
         </div>

         <div className="mt-4 flex items-center">
            <label className="mr-4">ETD: </label>
            <span>
               {forwarderRoute.route.etd} day
               {forwarderRoute.route.etd > 1 ? 's' : null} after cutoff date.
            </span>
            {cargoReadyDate ? (
               <div className="ml-4">
                  {calculateEtd(
                     forwarderRoute,
                     cargoReadyDate
                  ).toLocaleDateString()}
               </div>
            ) : null}
         </div>

         <div className="mt-4 flex items-center">
            <label className="mr-4">Delivery Time: </label>
            <span>
               {forwarderRoute.route.deliveryTime} day
               {forwarderRoute.route.deliveryTime > 1 ? 's' : null}.
            </span>
         </div>

         {cargoReadyDate ? (
            <div className="flex mt-4">
               <label htmlFor="">ETA: </label>
               <div className="ml-4">
                  {calculateEta(forwarderRoute, cargoReadyDate)}
               </div>
            </div>
         ) : null}

         {shipment ? (
            <div className="mt-4">
               <label htmlFor="">Total Cost: </label>
               <span className="ml-4">
                  {totalPriceInUsd(
                     forwarderRoute,
                     shipment.totalGrossWeight,
                     shipment.totalCbm
                  )}{' '}
                  USD
               </span>
            </div>
         ) : null}

         <div>
            <Button
               title={
                  showPriceDetail ? 'Hide Price Detail' : 'Show Price Detail'
               }
               size="sm"
               onClick={() => setShowPriceDetail((prev) => !prev)}
            ></Button>
         </div>

         {showPriceDetail ? (
            <div className="flex overflow-auto">
               {forwarderRoute.priceCategories.map((cat, index) => (
                  <ForwarderRoutePriceCategory
                     className="mr-4 lg:mr-6 bg-gray-100 dark:bg-gray-900 rounded-2xl mt-2 p-4"
                     priceCategory={cat}
                     key={index}
                     showTotal={shipment !== undefined}
                     weight={shipment ? shipment.totalGrossWeight : null}
                     cbm={shipment ? shipment.totalCbm : null}
                  />
               ))}
            </div>
         ) : null}

         {forwarderRoute.submissions &&
         forwarderRoute.submissions.length > 0 ? (
            <div className="mt-8">
               <div>
                  <label htmlFor="">Submit for Shipments</label>
               </div>
               <div className="mt-4">
                  {forwarderRoute.submissions.map(({ shipment }, index) => (
                     <div
                        key={index}
                        className="border-b border-gray-100 dark:border-gray-700"
                     >
                        <ShipmentTitle
                           target="_blank"
                           link={`/shipment/shipments/${shipment.id}`}
                           className="py-2"
                           number={shipment.invoiceNumber}
                           customerName={shipment.customer.name}
                        />
                     </div>
                  ))}
               </div>
            </div>
         ) : null}
      </div>
   );
};

export const ForwarderRouteRow = ({
   className = '',
   forwarderRoute,
   shipment,
   cargoReadyDate,
   defaultShowPriceDetail = true,
   choosePlanHandler,
}) => {
   const [showPriceDetail, setShowPriceDetail] = useState(
      defaultShowPriceDetail
   );

   return (
      <tr className="whitespace-no-wrap">
         <td className="border-b border-gray-200 dark:border-gray-700">
            {forwarderRoute.forwarder.name}
         </td>
         <td className="border-b border-gray-200 dark:border-gray-700">
            <div className="text-center">
               <div>
                  [{forwarderRoute.route.transportation}]{' '}
                  {forwarderRoute.route.shippingCompany.name} -{' '}
                  {forwarderRoute.route.name}
               </div>
               <div className="text-xs text-gray-600 mt-1">
                  {forwarderRoute.route.portOfLoading.name} →{' '}
                  {forwarderRoute.route.portOfDestination.name}
               </div>
            </div>
         </td>
         <td className="border-b border-gray-200 dark:border-gray-700">
            <div className="text-center">
               <div>
                  {calculateCutoffDate(
                     forwarderRoute,
                     cargoReadyDate
                  ).toLocaleDateString()}
               </div>
               <div className="mt-1 text-xs text-gray-600">
                  Every{' '}
                  {scheduleString(
                     forwarderRoute.route.scheduleUnit,
                     forwarderRoute.route.scheduleValue
                  )}
               </div>
            </div>
         </td>
         <td className="border-b border-gray-200 dark:border-gray-700">
            <div className="text-center">
               <div className="ml-4">
                  {calculateEtd(
                     forwarderRoute,
                     cargoReadyDate
                  ).toLocaleDateString()}
               </div>
               <div className="mt-1 text-xs text-gray-600">
                  {forwarderRoute.route.etd} day
                  {forwarderRoute.route.etd > 1 ? 's' : null} after cutoff date.
               </div>
            </div>
         </td>
         <td className="border-b border-gray-200 dark:border-gray-700">
            <div className="text-center">
               <div>
                  {calculateEta(
                     forwarderRoute,
                     cargoReadyDate
                  ).toLocaleDateString()}
               </div>
               <div className="mt-1 text-xs text-gray-600">
                  {forwarderRoute.route.deliveryTime} day
                  {forwarderRoute.route.deliveryTime > 1 ? 's' : null} delivery
               </div>
            </div>
         </td>
         <td className="border-b border-gray-200 dark:border-gray-700">
            {totalPriceInUsd(
               forwarderRoute,
               shipment.totalGrossWeight,
               shipment.totalCbm
            )}{' '}
            USD
         </td>
         <td className="border-b border-gray-200 dark:border-gray-700">
            {new Date(forwarderRoute.createdAt).toLocaleDateString()}
         </td>
         <td className="border-b border-gray-200 dark:border-gray-700">
            <div className={className}>
               <div>
                  <Button
                     title={
                        showPriceDetail
                           ? 'Hide Price Detail'
                           : 'Show Price Detail'
                     }
                     size="sm"
                     onClick={() => setShowPriceDetail((prev) => !prev)}
                  ></Button>

                  {showPriceDetail ? (
                     <div className="flex overflow-auto">
                        {forwarderRoute.priceCategories.map((cat, index) => (
                           <ForwarderRoutePriceCategory
                              className="mr-4 lg:mr-6 bg-gray-100 rounded-lg mt-2 p-4"
                              priceCategory={cat}
                              key={index}
                              showTotal={shipment !== undefined}
                              weight={
                                 shipment ? shipment.totalGrossWeight : null
                              }
                              cbm={shipment ? shipment.totalCbm : null}
                           />
                        ))}
                     </div>
                  ) : null}
               </div>
               <div>
                  <Button
                     title="Choose this Plan"
                     size="sm"
                     onClick={() => choosePlanHandler(forwarderRoute)}
                  ></Button>
               </div>
            </div>
         </td>
      </tr>
   );
};

export default ForwarderRoute;
