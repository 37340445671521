import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

import Production from './Production';

const SharedSupplierRoutes = () => {
   console.log('SharedSupplierRoutes');
   return (
      <Routes>
         <Route element={<Layout />}>
            <Route path="production/:code" element={<Production />} />
         </Route>
      </Routes>
   );
};

const Layout = () => (
   <div className="bg-gray-100 dark:bg-gray-900 flex flex-col flex-1 h-full relative">
      <div className="flex-1">
         <Outlet />
      </div>

      <div className="text-xs text-gray-500 mt-20 text-center px-6">
         本页面由Waboba创建，如有问题，请与我们联系。
      </div>
   </div>
);

export default SharedSupplierRoutes;
