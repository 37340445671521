import { Route, Routes } from "react-router-dom";
import ChinaStocks from "./ChinaStocks";
import ChinaStockProducts from "./ChinaStockProducts";

function ChinsStockPages() {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<ChinaStocks />} />
        <Route path=":stockId" element={<ChinaStockProducts />} />
      </Route>
    </Routes>
  );
}

export default ChinsStockPages;
