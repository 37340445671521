import { useContext } from "react";
import { Field } from "components/Form";
import { CubeSizeInput } from "components/ItemSizeInput";
import { ProductContext } from "./ProductForm";
import { CharlesButtonWithArrow } from "components/charles/base";

const PackingShipmentView = () => {
  const { showPackingShipment, setShowPackingShipment, simpleFields, setSimpleFields, onChangeSimpleFields, outerCartonCbm } = useContext(ProductContext);

  console.log("simpleFields", simpleFields);

  const barcodes = {
    innerBoxBarcodeEan: "Inner Box Bar Code(EAN)",
    innerBoxBarcodeUpc: "Inner Box Bar Code(UPC)",
    outerCartonBarcodeEan: "Outer Carton Bar Code(EAN/GTIN14)",
    outerCartonBarcodeUpc: "Outer Carton Bar Code(UPC/GTIN14)",
    innerCartonBarcodeEan: "Inner Carton Bar Code(EAN/GTIN14)",
    innerCartonBarcodeUpc: "Inner Carton Bar Code(UPC/GTIN14)",
  };

  return (
    <section>
      <CharlesButtonWithArrow className="text-xl" show={showPackingShipment} onClick={() => setShowPackingShipment((prev) => !prev)}>
        Packing and Shipment
      </CharlesButtonWithArrow>

      {showPackingShipment ? (
        <>
          <div className="mt-4 space-y-4">
            <Field
              label="Packing"
              labelWidth="w-24"
              value={simpleFields.packing}
              onChange={(value) => onChangeSimpleFields("packing", value)}
              tips="Packing describe how the product is packed, for example: 2-tier, 1-tier, Bulk, etc."
            />
            <Field
              label="Packing Name"
              labelWidth="w-24"
              value={simpleFields.packingName}
              onChange={(value) => onChangeSimpleFields("packingName", value)}
              tips="Packing Name is used in shipments right now."
            />

            <Field label="Shipping Mark Name" value={simpleFields.shippingMarkName} onChange={(value) => onChangeSimpleFields("shippingMarkName", value)} />
            <Field label="Colors" value={simpleFields.colors} onChange={(value) => onChangeSimpleFields("colors", value)} />

            <div className="grid grid-cols-2 gap-4">
              {Object.keys(barcodes).map((key) => (
                <Field key={key} label={barcodes[key]} value={simpleFields[key]} onChange={(value) => onChangeSimpleFields(key, value)} />
              ))}
            </div>

            <div className="flex items-center space-x-4">
              <div className="w-44">
                <label>Inner Box Size: </label>
                <div className="text-xs text-gray-600">Size 长(x) x 宽(z) x 高(y):</div>
              </div>

              <CubeSizeInput
                x={simpleFields.innerBoxX}
                y={simpleFields.innerBoxY}
                z={simpleFields.innerBoxZ}
                onChange={({ x, y, z }) => setSimpleFields((prev) => ({ ...prev, innerBoxX: x, innerBoxY: y, innerBoxZ: z }))}
              />
            </div>

            <div className="flex items-center space-x-4">
              <div className="w-44">
                <label>Display Box Size: </label>
                <div className="text-xs text-gray-600">Size 长(x) x 宽(z) x 高(y):</div>
              </div>
              <CubeSizeInput
                x={simpleFields.displayBoxX}
                y={simpleFields.displayBoxY}
                z={simpleFields.displayBoxZ}
                unit="mm"
                onChange={({ x, y, z }) => setSimpleFields((prev) => ({ ...prev, displayBoxX: x, displayBoxY: y, displayBoxZ: z }))}
              />
            </div>

            <div className="flex items-center space-x-4">
              <div className="w-44">
                <label>Inner Carton Size: </label>
                <div className="text-xs text-gray-600">Size 长(x) x 宽(z) x 高(y):</div>
              </div>
              <CubeSizeInput
                x={simpleFields.innerCartonX}
                y={simpleFields.innerCartonY}
                z={simpleFields.innerCartonZ}
                unit="cm"
                onChange={({ x, y, z }) => setSimpleFields((prev) => ({ ...prev, innerCartonX: x, innerCartonY: y, innerCartonZ: z }))}
              />
            </div>

            <div className="flex items-center space-x-4">
              <div className="w-44">
                <label>Outer Carton Size: </label>
                <div className="text-xs text-gray-600">Size 长(x) x 宽(z) x 高(y):</div>
              </div>
              <CubeSizeInput
                x={simpleFields.outerCartonX}
                y={simpleFields.outerCartonY}
                z={simpleFields.outerCartonZ}
                unit="cm"
                onChange={({ x, y, z }) => setSimpleFields((prev) => ({ ...prev, outerCartonX: x, outerCartonY: y, outerCartonZ: z }))}
              />

              <div>
                <div>
                  <label>CBM: </label> {outerCartonCbm(simpleFields) ? outerCartonCbm(simpleFields).toFixed(4) : "0"} m³
                </div>
                <div className="text-xs text-gray-600">((x + 0.5) * (y + 0.5) * (z + 0.5)) / (100 * 100 * 100)</div>
              </div>
            </div>

            <div className="flex space-x-8">
              <Field
                type="number"
                label="Qty / Display Box"
                value={simpleFields.quantityPerDisplayBox}
                onChange={(value) => onChangeSimpleFields("quantityPerDisplayBox", value)}
              />

              <Field
                type="number"
                label="Qty / Carton"
                value={simpleFields.quantityPerCarton}
                onChange={(value) => onChangeSimpleFields("quantityPerCarton", value)}
              />
            </div>

            <div className="flex space-x-8">
              <Field
                type="number"
                label="CTN Net Weight"
                value={simpleFields.ctnNetWeight}
                onChange={(value) => onChangeSimpleFields("ctnNetWeight", value)}
                suffix="kg"
              />

              <Field
                type="number"
                label="CTN Gross Weight"
                value={simpleFields.ctnGrossWeight}
                onChange={(value) => onChangeSimpleFields("ctnGrossWeight", value)}
                suffix="kg"
              />
            </div>
          </div>
        </>
      ) : null}
    </section>
  );
};

export default PackingShipmentView;
