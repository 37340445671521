import { Route, Routes } from "react-router-dom";
import AgentList from "./AgentList";
import AgentDetailPage from "./AgentDetailPage";

const AgentPages = () => {
  return (
    <Routes>
      <Route index element={<AgentList />} />
      <Route path=":id" element={<AgentDetailPage />} />
    </Routes>
  );
};

export default AgentPages;
