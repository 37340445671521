import Page from "components/Page";
import SearchBar from "components/SearchBar";
import { useQuery } from "@apollo/client";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { Link } from "react-router-dom";
import { FETCH_RETAILER_ORDERS } from "./graphql";
import Status, { getOrderStateColor } from "./Status";

const OrderListPage = () => {
  const { loading, error, data } = useQuery(FETCH_RETAILER_ORDERS);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <Page title="Retailer Orders">
      <div className="card m-6 p-6 text-sm">
        <div className="flex items-center justify-between space-x-4">
          <div className="flex-1">
            <SearchBar placeholder="Search by retailer email or order number." />
          </div>
        </div>

        <div className="mt-4">
          <table>
            <thead>
              <tr>
                <th>Order</th>
                <th>Retailer</th>
                <th className="text-right">Total</th>
                <th className="text-right">Status</th>
              </tr>
            </thead>
            <tbody>
              {data.retailerOrders.map((order) => (
                <tr key={order.id} className="border-y border-gray-100 dark:border-gray-700">
                  <td>
                    <Link to={order.id}>{order.id}</Link>
                  </td>
                  <td>{order.user.retailer.name}</td>
                  <td className="text-right">{order.total}</td>
                  <td className={getOrderStateColor(order.state)}>
                    <div className="flex justify-end">
                      <Status state={order.state} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Page>
  );
};

export default OrderListPage;
