import SearchBar from "components/SearchBar";
import { useDebouncedCallback } from "use-debounce";
import ReportsListTableView from "./ReportsListTableView";
import { useSearchParams } from "react-router-dom";
import { Select } from "components/Form";

export const PAGE_SIZE = 30;

function FinalQcReportListPage() {
  const [searchParams, setSearchParams] = useSearchParams({
    limit: PAGE_SIZE,
    offset: 0,
    q: "",
    status: "ALL",
  });
  const limit = searchParams.get("limit");
  const offset = searchParams.get("offset");
  const q = searchParams.get("q");
  const status = searchParams.get("status");

  let params = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  const debounceSearch = useDebouncedCallback((q) => setSearchParams({ ...params, q }), 500);

  console.log("searchParams", params);

  return (
    <div className="m-4 sm:mt-4 lg:m-6 flex flex-col flex-1">
      <div className="flex space-x-6 items-center">
        <div className="flex-1">
          <SearchBar placeholder="Search by product name, number, order no or factory." initialQuery={q} onChange={debounceSearch} />
        </div>
        <Select value={status} onChange={(e) => setSearchParams({ ...params, status: e.target.value })}>
          <option value="ALL">All Status</option>
          <option value="PENDING">Pending</option>
          <option value="PASS">Pass</option>
          <option value="FAIL">Fail</option>
        </Select>
      </div>

      <div className="mt-4 relative flex-1">
        <ReportsListTableView
          limit={limit}
          offset={parseInt(offset)}
          q={q}
          status={status === "ALL" ? null : status}
          setOffset={(offset) => setSearchParams({ ...params, offset })}
        />
      </div>
    </div>
  );
}

export default FinalQcReportListPage;
