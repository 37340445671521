import { gql, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { createContext, useEffect, useState } from "react";
import SearchBar from "components/SearchBar";
import ProductForm from "./ProductForm";
import moment from "moment";
import { Button } from "components/base";
import odooIcon from "assets/odoo-icon.svg";
import { Switcher } from "components/Form";
import { useModals } from "ModalProvider";
import NewProducts from "./NewProducts";
import { useSearchParams } from "react-router-dom";

export const FETCH_WIS_PRODUCTS = gql`
  query FETCH_WIS_PRODUCTS($offset: Int, $limit: Int, $isActiveOnly: Boolean, $q: String) {
    products(productType: "normal", offset: $offset, limit: $limit, isActiveOnly: $isActiveOnly, q: $q) {
      total
      results {
        id
        name
        number
        odooId
        isActive
        createdAt
        images: computedImages {
          id
          url(size: "300x300")
        }
      }
    }
  }
`;

export const AllProductsContext = createContext({});

const PAGE_SIZE = 50;

const AllProducts = () => {
  const [searchParams, setSearchParams] = useSearchParams({ offset: 0, q: "" });
  const id = searchParams.get("id");
  const q = searchParams.get("q");
  const offset = parseInt(searchParams.get("offset")) || 0;
  const [isActiveOnly, setIsActiveOnly] = useState(true);

  const { loading, error, data } = useQuery(FETCH_WIS_PRODUCTS, {
    variables: { offset, limit: PAGE_SIZE, isActiveOnly, q },
    // fetchPolicy: "network-only",
  });

  const newModal = useModals();
  const editModal = useModals();

  useEffect(() => {
    if (data) {
      if (id && id !== "null") {
        editProduct({ id });
      }
    }
  }, [data]);

  function onCreated({ id }) {
    newModal.hide();
    editModal.present({
      title: "Edit Product",
      fullscreen: true,
      BeingPresented: true,
      children: (
        <ProductForm
          id={id}
          hide={editModal.hide}
          duplicate={(data) => {
            editModal.hide();
            newModal.present({
              title: "Duplicate Product",
              fullscreen: true,
              BeingPresented: true,
              children: <ProductForm duplicatingProduct={data} hide={newModal.hide} onSave={onCreated} />,
            });
          }}
        />
      ),
    });
    setSearchParams({ id, offset, q });
  }

  function editProduct(product) {
    setSearchParams({ id: product.id, offset, q });
    editModal.present({
      title: `Edit Product #${product.id}`,
      fullscreen: true,
      isBeingPresented: true,
      onDismiss: () => {
        setSearchParams({ offset, q });
      },
      children: (
        <ProductForm
          id={product.id}
          duplicate={(data) => {
            editModal.hide();
            duplicateProduct(data);
          }}
          onSaveAndClose={() => {
            editModal.hide();
            setSearchParams({ offset, q });
          }}
          hide={editModal.hide}
        />
      ),
    });
  }

  function duplicateProduct(sourceProduct) {
    setSearchParams({ q });
    newModal.present({
      title: "Duplicate Product",
      fullscreen: true,
      BeingPresented: true,
      children: <ProductForm duplicatingProduct={sourceProduct} hide={newModal.hide} onSave={onCreated} onSaveAndClose={newModal.hide} />,
    });
  }

  return (
    <div className="flex space-x-8 h-full p-6">
      <div className="flex-1 overflow-auto flex flex-col card p-0 rounded-2xl relative">
        <div className="flex items-center sticky top-0 p-6 backdrop-blur-lg space-x-8 z-10">
          <div className="flex-1">
            <SearchBar
              autoFocus
              initialQuery={q}
              className="p-2 flex-1 bg-gray-100 dark:bg-gray-700 dark:bg-opacity-30"
              onChange={(q) => setSearchParams({ offset: 0, q })}
              placeholder="Search produc by name, item number or odoo id."
            />
          </div>

          <Button
            title="+ Create Product"
            onClick={() => {
              newModal.present({
                title: "Create Product",
                fullscreen: true,
                BeingPresented: true,
                children: <ProductForm hide={newModal.hide} onSave={onCreated} onSaveAndClose={newModal.hide} />,
              });
            }}
          />

          <div className="flex items-center space-x-2">
            <label htmlFor="">Active Only: </label>
            <Switcher isOn={isActiveOnly} onChange={() => setIsActiveOnly((prev) => !prev)} />
          </div>
        </div>

        <ProductsResult loading={loading} error={error} data={data} offset={offset} setSearchParams={setSearchParams} editProduct={editProduct} q={q} />
      </div>

      <NewProducts editProduct={editProduct} />
    </div>
  );
};

const ProductsResult = ({ loading, error, data, offset, q, setSearchParams, editProduct }) => {
  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const { results, total } = data.products;

  const products = results.map((i) => ({
    ...i,
    isNew: moment.duration(moment().diff(moment(i.createdAt))).asDays() < 1,
  }));

  return (
    <div className="h-full overflow-auto flex flex-col">
      <div className="px-8 flex-1">
        {products.map((i) => (
          <ProductRow product={i} key={i.id} editProduct={() => editProduct(i)} />
        ))}
      </div>

      <div className="p-6 flex justify-end items-center space-x-2 opacity-60">
        {offset > 0 ? <Button prev onClick={() => setSearchParams({ offset: offset - PAGE_SIZE, q })} /> : null}
        <div>
          {offset + 1}-{Math.min(PAGE_SIZE + offset, total)} / {total}
        </div>
        {total > offset + PAGE_SIZE ? <Button next onClick={() => setSearchParams({ offset: PAGE_SIZE + offset, q })} /> : null}
      </div>
    </div>
  );
};

export const ProductRow = ({ product, editProduct }) => {
  return (
    <div
      className={`border-b border-gray-100 dark:border-gray-700 py-2 cursor-pointer hover:bg-gray-100 active:bg-gray-200 dark:hover:bg-gray-800 dark:first-line:active:bg-gray-900 flex items-center space-x-2
         ${product.isActive ? "" : "opacity-30"}
   `}
      onClick={editProduct}
    >
      {product.isNew ? <div className="rounded-full text-white bg-green-500 dark:bg-green-600 text-xs px-2 py-1">new</div> : null}

      {product.odooId ? <img className="mr-2" style={{ height: 16 }} src={odooIcon} alt="odoo product" /> : null}
      <div>
        [ {product.number} ] {product.name}
      </div>
      <div className="flex items-center space-x-2">{product.images ? product.images.map((x) => <img className="h-4" key={x.id} src={x.url} />) : null}</div>
    </div>
  );
};

export default AllProducts;
