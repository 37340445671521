import React, { useState, useRef, useContext, useEffect } from "react";
import queryString from "query-string";
import { useQuery, gql } from "@apollo/client";
import { useDebouncedCallback } from "use-debounce";
import { Button, Tag } from "components/base";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import Image from "components/Image";
import SearchBar from "components/SearchBar";
import http from "utils/http";
import track, { actions } from "utils/track";
import { searchByProp } from "utils/search";
import ProductTable from "./ProductTable";
import productProps from "./productProps";
import { AppContext } from "App";
import { useModals } from "ModalProvider";
import ProductBarcode from "./ProductBarcode";
import { useLocation, useNavigate } from "react-router-dom";

const FETCH_DATA_FOR_PRODUCT_PAGE = gql`
  query FETCH_DATA_FOR_PRODUCT_PAGE {
    areas: allProductAreas {
      id
      name
    }
    productLines(prefetchForProductsPage: true, hasActiveProducts: true) {
      results {
        id
        name
        productsSearchString
        age
        hsCode
        hsCodeForEu
        hsCodeForUs
        pantone
        mainImage(size: "300x300")
        materials {
          id
          material {
            id
            name
          }
        }
        warnings {
          id
          name
          content
        }
        labTestStandards {
          id
          name
        }
        children {
          id
          name
          age
          shape
          hsCode
          hsCodeForEu
          hsCodeForUs
          pantone
          materials {
            id
            material {
              id
              name
            }
          }
          warnings {
            id
            name
            content
          }
          labTestStandards {
            id
            name
          }
        }
      }
    }
  }
`;

function Products() {
  const { loading, error, data } = useQuery(FETCH_DATA_FOR_PRODUCT_PAGE);
  const location = useLocation();
  const navigate = useNavigate();

  const {
    settings: { metric },
  } = useContext(AppContext);

  const productLinesContaineRef = useRef();
  const [scrollingProductLinesContainer, setScrollingProductLinesContainer] = useState(false);

  const [searchText, setSearchText] = useState("");
  const debouncedTrack = useDebouncedCallback(track, 1000);
  const [showOnlySelected, setShowOnlySelected] = useState(false);

  const q = queryString.parse(location.search);
  const intialLines = q.lines ? q.lines.split(",") : [];
  const intialAreas = q.areas ? q.areas.split(",") : [];
  const [lines, setLines] = useState(intialLines);
  const [areas, setAreas] = useState(intialAreas);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [isExportingPDF, setIsExportingPDF] = useState(false);
  const [isExportingExcel, setIsExportingExcel] = useState(false);

  const barcodeModal = useModals();

  useEffect(() => {
    const serachParams = new URLSearchParams();
    if (lines.length > 0) serachParams.append("lines", lines.join(","));
    if (areas.length > 0) serachParams.append("areas", areas.join(","));
    const search = serachParams.toString();
    navigate({ search });
  }, [lines, areas]);

  function previewBarcode(product) {
    barcodeModal.present({
      title: "Generate Barcode",
      center: true,
      children: <ProductBarcode product={product} />,
    });
  }

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const hasSelectedProducts = selectedProducts.length > 0 || selectedFields.length > 0;

  function selectProducts(products) {
    if (products.length === 1) {
      const product = products[0];
      if (selectedProducts.find((p) => p.id === product.id)) {
        setSelectedProducts(selectedProducts.filter((p) => p.id !== product.id));
      } else {
        setSelectedProducts([...selectedProducts, product]);
      }
    } else {
      if (selectedProducts.length > 0) {
        setSelectedProducts([]);
      } else {
        setSelectedProducts(products);
      }
    }
    debouncedTrack(
      actions.product.selectItemsToExport.name,
      products.map(({ id, name }) => ({ id, name }))
    );
  }

  function selectField(field) {
    if (selectedFields.find((f) => f.field === field.field)) {
      setSelectedFields(selectedFields.filter((f) => f.field !== field.field));
    } else {
      setSelectedFields([...selectedFields, field]);
    }
    debouncedTrack(
      actions.product.selectFieldsToExport.name,
      selectedFields.map((i) => i.displayName)
    );
  }

  function exportPDF() {
    const products = selectedProducts;
    console.log("products", products);
    const fields = selectedFields.length > 0 ? selectedFields : productProps;
    const url = `/export/product/pdf/`;
    let data = [];
    let i,
      j,
      chunk = 3;
    for (i = 0, j = products.length; i < j; i += chunk) {
      const productsForThisTable = products.slice(i, i + chunk);
      data.push({
        headerRow: ["Products", ...productsForThisTable.map((i) => i.name)],
        bodyRows: fields.map((f) => {
          let property = productProps.find((p) => p.field === f.field);
          return [
            property.displayName,
            ...productsForThisTable.map((product) => {
              switch (property.field) {
                case "name":
                  return product.name;
                // case 'images':
                //    return product.images.map((i) => i.thumb);
                default:
                  return property.value ? property.value(product, metric) : product[property.field];
              }
            }),
          ];
        }),
      });
    }
    setIsExportingPDF(true);
    http
      .post(
        url,
        { data, fileType: "pdf" },
        {
          baseURL: process.env.REACT_APP_SERVER_BASE_URL,
          timeout: 60000,
          responseType: "blob",
          withCredentials: true,
        }
      )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Waboba-Products-Data.pdf");
        document.body.appendChild(link);
        link.click();
        setIsExportingPDF(false);
      })
      .catch((error) => {
        alert(`Oops! ${error}`);
        setIsExportingPDF(false);
      });
    track(actions.product.export.name, {
      type: "pdf",
      products: products.map(({ id, name }) => ({ id, name })),
      fields: fields.map((i) => i.displayName),
    });
  }

  function exportExcel() {
    const products = selectedProducts;
    const fields = selectedFields.length > 0 ? selectedFields : productProps;
    const data = {
      headerRow: ["Products", ...products.map((i) => i.name)],
      bodyRows: fields.map((f) => {
        let property = productProps.find((p) => p.field === f.field);
        return [
          property.displayName,
          ...products.map((product) => {
            switch (property.field) {
              case "name":
                return product.name;
              // case 'images':
              //    return product.images.map((i) => i.thumb);
              default:
                return property.value ? property.value(product, metric) : product[property.field];
            }
          }),
        ];
      }),
    };
    const url = `/export/product/xlsx/`;
    setIsExportingExcel(true);
    http
      .post(
        url,
        { data, fileType: "excel" },
        {
          baseURL: process.env.REACT_APP_SERVER_BASE_URL,
          timeout: 60000,
          responseType: "blob",
          withCredentials: true,
        }
      )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Waboba-Products-Data.xlsx");
        document.body.appendChild(link);
        link.click();
        setIsExportingExcel(false);
      })
      .catch((error) => {
        alert(`Oops! ${error}`);
        setIsExportingExcel(false);
      });
    track(actions.product.export.name, {
      type: "excel",
      products: products.map(({ id, name }) => ({ id, name })),
      fields: fields.map((i) => i.displayName),
    });
  }

  console.log("data.productLines", data.productLines);

  const productLines = data.productLines.results
    .filter((i) => (showOnlySelected ? lines.includes(i.id) : true))
    .filter((i) => lines.includes(i.id) || searchByProp(i, ["name", "productsSearchString"], searchText))
    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

  const productAreas = data.areas.filter((i) => areas.includes(i.id) || searchByProp(i, ["name"], searchText));

  return (
    <div className="flex flex-col flex-1 h-full">
      <header className="mb-4">
        <div className="flex items-center justify-between my-2">
          <div className="w-full mt-2 lg:w-1/2 mx-4 hidden lg:block">
            <SearchBar autoFocus className="py-1" placeholder="Search Product Name, Product Line Number, Product groups" onChange={setSearchText} />
          </div>
          <div className="flex flex-end mx-4 mt-2 lg:mt-0">
            <Button
              size="sm"
              title="Select All"
              onClick={(_) => {
                setLines(productLines.map((i) => i.id));
                track(actions.product.selectAllProducts.name);
              }}
            />

            {lines.length > 0 && (
              <Button
                className="ml-4"
                size="sm"
                title={showOnlySelected ? "Show All Products" : "Show Only Selected Products"}
                onClick={(_) => {
                  setShowOnlySelected((prev) => !prev);
                  track(actions.product.showOnlySelected.name);
                }}
              />
            )}
            {lines.length > 0 || areas.length > 0 ? (
              <Button
                className="ml-4"
                size="sm"
                title="Clear"
                onClick={(_) => {
                  setLines([]);
                  setAreas([]);
                  setShowOnlySelected(false);
                  track(actions.product.clear.name);
                }}
              />
            ) : null}
            {hasSelectedProducts && (
              <div className="flex ml-4 mb-0">
                <Button
                  size="sm"
                  title={isExportingPDF ? `Exporting...` : `Export PDF`}
                  loading={isExportingPDF}
                  disabled={isExportingPDF}
                  onClick={exportPDF}
                />
                <Button
                  className="ml-4"
                  size="sm"
                  title={isExportingExcel ? `Exporting...` : `Export Excel`}
                  loading={isExportingExcel}
                  disabled={isExportingExcel}
                  onClick={exportExcel}
                />
              </div>
            )}
          </div>
        </div>

        <div
          ref={productLinesContaineRef}
          className="flex overflow-auto scrolling-touch animate-fadeInDown opacity-0"
          onScroll={() => {
            setScrollingProductLinesContainer(true);
          }}
          onMouseLeave={() => setScrollingProductLinesContainer(false)}
        >
          <div
            className={`flex lg:flex-wrap animate-ease-5
               ${productLines.length < 20 || searchText.trim() !== "" ? "" : "lg:h-24"}`}
            style={{
              height: scrollingProductLinesContainer ? productLinesContaineRef.current.scrollHeight : null,
            }}
          >
            {productLines.map((i, index) => {
              const selected = lines.includes(i.id);
              return (
                <div
                  className={`ml-4 mt-2
                     ${productLines.length === index + 1 ? "mr-4" : ""}`}
                  key={i.id}
                >
                  <Tag
                    className="text-xs"
                    selected={selected}
                    onClick={(_) => {
                      let newLines = [];
                      if (selected) {
                        newLines = lines.filter((id) => id !== i.id);
                        setSelectedProducts([]);
                        setSelectedFields([]);
                      } else {
                        newLines = [...lines, i.id];
                      }
                      setLines(newLines);
                      if (newLines.length === 0) {
                        setShowOnlySelected(false);
                      } else {
                        debouncedTrack(
                          actions.product.selectLines.name,
                          productLines
                            .filter((i) => newLines.indexOf(i.id) > -1)
                            .map(({ id, name }) => ({
                              id,
                              name,
                            }))
                        );
                      }
                    }}
                    title={i.name}
                    leftView={i.mainImage ? <Image className="mr-2" size="w-4 h-4" src={i.mainImage} /> : null}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex overflow-auto scrolling-touch mt-2 animate-fadeInDown opacity-0 [animation-delay:0.15s]">
          <div className="flex">
            {productAreas.map((i, index) => (
              <div
                className={`ml-4
                           ${productAreas.length === index + 1 ? "mr-4" : ""}
                           `}
                key={i.id}
              >
                <Tag
                  className="text-xs"
                  key={i.id}
                  selected={areas.includes(i.id)}
                  onClick={(_) => {
                    let newAreas = [];
                    if (areas.includes(i.id)) {
                      newAreas = areas.filter((id) => id !== i.id);
                    } else {
                      newAreas = [...areas, i.id];
                    }
                    setAreas(newAreas);
                    if (newAreas.length > 0)
                      debouncedTrack(
                        actions.product.selectAreas.name,
                        productAreas
                          .filter((i) => newAreas.indexOf(i.id) > -1)
                          .map(({ id, name }) => ({
                            id,
                            name,
                          }))
                      );
                  }}
                  title={i.name}
                />
              </div>
            ))}
          </div>
        </div>
      </header>

      <section className="flex flex-1 relative overflow-auto text-sm border-t border-gray-200 dark:border-gray-700">
        {areas.length > 0 || lines.length > 0 ? (
          <ProductTable
            selectedProducts={selectedProducts}
            selectedFields={selectedFields}
            selectProducts={selectProducts}
            selectField={selectField}
            productAreas={areas}
            productLines={lines}
            previewBarcode={previewBarcode}
          />
        ) : null}
      </section>
    </div>
  );
}

export default Products;
