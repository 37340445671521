import React from 'react';
import { Button } from './base';

function ErrorView() {
   return (
      <div className="text-center h-screen justify-center flex flex-1 flex-col bg-gray-100">
         <div>
            <h1 className="mt-4">
               WIS Crashed!{' '}
               <span role="img" aria-label="aria-label">
                  😫
               </span>
            </h1>
            <div className="mt-4">
               We will receive notification about this crash. Try to reload this
               page.
            </div>
            <div className="flex justify-center mt-4">
               <Button
                  bold={true}
                  size="lg"
                  title="Reload"
                  onClick={_ => window.location.reload()}
               ></Button>
            </div>
         </div>
      </div>
   );
}

export default ErrorView;
