import { useQuery } from "@apollo/client";
import Page from "components/Page";
import { useParams } from "react-router-dom";
import { FETCH_PRODUCT_LIST_FOR_DOC_CPC } from "./graphql";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import moment from "moment";
import { PLACE_OF_TESTING, generateDocumentNumber } from "./documentNo";
import CPCForm from "./CPCForm";
import { STANDARD_17, STANDARD_23 } from "./CPCStandards";

const CpcPage = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FETCH_PRODUCT_LIST_FOR_DOC_CPC, {
    variables: { id },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  let refNo = "";
  let dateOfTesting = "";
  let placeOfTesting = "";

  const foundRASTM = data.productList.testReports.find((report) => report.standard.name === "ASTM-F963/CPSIA/California P65");
  if (foundRASTM) {
    if (foundRASTM.fileList.length === 0) return null;

    const url = foundRASTM.fileList[0];
    const { notifyBody, documentNo } = generateDocumentNumber(url);

    refNo = documentNo;
    dateOfTesting = moment(foundRASTM.createdAt).format("YYYY-MM-DD");
    placeOfTesting = PLACE_OF_TESTING[notifyBody];
  }

  let productList = {
    id,
    name: data.productList.name,
    number: data.productList.number,
    placeOfManufacture: "",
    countryOfOrigin: "CHINA",
    placeOfTesting,
    dateOfTesting,
    refNo,
    standard: "17", // 17 or 23
    standards: STANDARD_17,
  };

  if (data.productList.cpcData) {
    const cpcData = JSON.parse(data.productList.cpcData);
    if (cpcData.status === "CONFIRMED") productList = { ...productList, ...cpcData };
  }

  return (
    <Page
      title={
        <div className="flex items-center">
          <div>CPC Report</div>
          <div className=" font-normal pl-4 text-base">[beta]</div>
        </div>
      }
      backTo="./.."
    >
      <CPCForm originalValues={productList} />
      <div className="px-6 pb-6 opacity-70">
        <p>
          We use Product List data to generate a CPC Report. It is quite simple: we check the uploaded files for test report ASTM-F963/CPSIA/California P65. If
          we find it, we extract the number as the reference number, and the date as the `Date of Testing`. Depends on the testing place(InterTek or Bureau
          Veritas), we show different `Place of Testing`. We have standard 17 and 23 for different content.
        </p>
        <p>Stella will complete, confirm or cancel the CPC Report depends on the data.</p>
      </div>
    </Page>
  );
};

export default CpcPage;
