import { gql } from "@apollo/client";

export const FETCH_CHINA_STOCKS = gql`
  query FETCH_CHINA_STOCKS {
    odooStocks {
      id
      name
      products: wisProducts {
        id
        name
        number
        odooId
      }
    }
  }
`;

export const FETCH_CHINA_STOCK_PRODUCTS = gql`
  query FETCH_CHINA_STOCK_PRODUCTS($stockId: ID!) {
    odooStock(id: $stockId) {
      id
      name
      products: wisProducts {
        id
        name
        number
        odooId
        useSupplierStock
      }
    }
  }
`;

export const FETCH_CHINA_PRODUCT_STOCKS = gql`
  query FETCH_CHINA_PRODUCT_STOCKS($productOdooIds: [Int]) {
    chinaStocks(productOdooIds: $productOdooIds)
  }
`;

export const FETCH_SUPPLIER_STOCK_RECORD = gql`
  query FETCH_SUPPLIER_STOCK_RECORD($id: ID!) {
    supplierStockRecord(id: $id) {
      id
      supplier {
        id
        name
      }
      product {
        id
        number
        name
      }
      qty
      remark
    }
  }
`;

export const UPDATE_SUPPLIER_STOCK_RECORD = gql`
  mutation UPDATE_SUPPLIER_STOCK_RECORD($id: ID!, $qty: Int) {
    updateSupplierStockRecord(id: $id, qty: $qty) {
      supplierStockRecord {
        id
        qty
      }
    }
  }
`;
