import QcStatus from "components/QcStatus";
import CharlesButton from "components/charles/base";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { Link } from "react-router-dom";
import { timeSince } from "utils/date";
import { DELETE_QC_REPORT } from "../graphql";
import { useMutation } from "@apollo/client";
import { Alert } from "components/Toast";
import { parseError } from "apollo";

const QcReportListRow = ({ report }) => {
  const [deleteReport, { loading }] = useMutation(DELETE_QC_REPORT, {
    onCompleted() {
      Alert("success", "Report Deleted.");
    },
    onError: (error) => {
      Alert("error", parseError(error));
    },
    awaitRefetchQueries: true,
    refetchQueries: ["FETCH_QC_REPORTS_LIST"],
  });

  return (
    <div className="py-2 lg:py-4 border-gray-100 dark:border-gray-700 lg:flex justify-between items-start">
      <div>
        <div className="flex mb-1">
          {report.productImages.map((src, index) => (
            <div key={index} className="product-image bg-contain bg-no-repeat bg-center w-12 h-12" style={{ backgroundImage: `url(${src})` }} />
          ))}
        </div>
        <div>
          <div className="flex items-center">
            <h4>{report.product.productLine?.name}</h4>
            <span className="opacity-70 pl-4">✕ {report.qty}</span>
          </div>
          <div>{report.invoice?.number}</div>
          <div className="opacity-70">
            [{report.product.number}] {report.product.name}
          </div>
        </div>
        <div className="text-gray-500 my-1">{report.factory.name}</div>
        <QcStatus status={report.status} />

        {report.sharedWithCustomerAt && (
          <div className="mt-2 text-purple-600 flex items-center space-x-2">
            <label>Shared with Customer</label>
            <IoMdCheckmarkCircle size={21} />
          </div>
        )}
      </div>

      <div>
        <div className="flex mt-2 lg:mt-0 whitespace-no-wrap justify-end space-x-2">
          <Link to={`/qc/final/${report.id}?from=${encodeURIComponent(window.location.pathname)}`}>Edit 编辑</Link>
          <Link to={`/qc/final/${report.id}/preview`}>preview 预览</Link>
        </div>

        <div className="mt-1">
          <div className="text-right text-gray-600 text-xs">{report.user.email}</div>
          <div className="text-right text-gray-600 text-xs">{timeSince(new Date(report.createdAt))} ago</div>

          {report.deletedAt === null && (
            <div className="flex justify-end">
              <CharlesButton
                danger
                loading={loading}
                onClick={(_) => {
                  if (window.confirm("Are you sure to delete this report?")) deleteReport({ variables: { id: report.id } });
                }}
              >
                Delete 删除
              </CharlesButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QcReportListRow;
