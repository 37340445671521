import { Route, Routes } from "react-router-dom";
import OdooMarginPage from "./OdooMarginPage";

const OdooAnalyticsPages = () => (
  <Routes>
    <Route>
      <Route index element={<OdooMarginPage />} />
    </Route>
  </Routes>
);

export default OdooAnalyticsPages;
