const ProductionDatesView = ({ production }) => {
  const production_dates_with_qty = production.production_dates_with_qty;
  return (
    <div className="-mx-2">
      <table>
        <tbody>
          {Object.entries(production_dates_with_qty).map(([date, qty]) => {
            return (
              <tr key={date} className="border-b dark:border-gray-700">
                <td>{date}</td>
                <td className="text-right">{qty}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ProductionDatesView;
