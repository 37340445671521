import React from "react";
import { Routes, Route } from "react-router-dom";
import BizAnalyticsIndex from "./biz";
import SalesAnalytic from "./SalesAnalytic";
import QCAnalytics from "./qc";
import ProductionAnalytics from "./production";
import GradingAnalytics from "./grading";
import OdooAnalyticsPages from "./odoo";

function AnalyticsIndex() {
  return (
    <Routes>
      <Route path="sales" element={<SalesAnalytic />} />
      <Route path="qc" element={<QCAnalytics />} />
      <Route path="production/*" element={<ProductionAnalytics />} />
      <Route path="biz/*" element={<BizAnalyticsIndex />} />
      <Route path="grading/*" element={<GradingAnalytics />} />
      <Route path="odoo/*" element={<OdooAnalyticsPages />} />
    </Routes>
  );
}

export default AnalyticsIndex;
