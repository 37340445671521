export const ALLOWED_PLANNER_USERS = ["stella@waboba.com", "sharon@waboba.com", "charles@waboba.com", "yolina@waboba.com"];

export const COST_VIEW_ONLY_USERS = ["aubrey@waboba.com", "barry@waboba.com"];

export const COST_PAGE_ALLOW_USERS = [
  "axel@waboba.com",
  "charles@waboba.com",
  "alex.c@waboba.com",
  "jade@waboba.com",
  "stella@waboba.com",
  "sharon@waboba.com",
  "yolina@waboba.com",
  "livi@waboba.com",
  "jonatan@waboba.com",
  "tina@waboba.com",
  "rasmus@waboba.com",
  "pete@waboba.com",
  "chrissie@waboba.com",
  "shawn@waboba.com",
  "cherry@waboba.com",
  "anita@waboba.com",
];
