import { useMutation, useQuery } from "@apollo/client";
import { AppContext } from "App";
import { isInternalUser } from "hooks/user";
import { FETCH_QC_PREVIEW, SAVE_SIMPLE_REPORT } from "pages/qc/graphql";
import { useContext } from "react";
import Errors from "./Errors";
import { Switcher } from "./Form";
import Spinner from "./Spinner";
import { Alert } from "./Toast";

const PreviewQCReport = ({ id }) => {
  const { user } = useContext(AppContext);
  const { loading, error, data } = useQuery(FETCH_QC_PREVIEW, {
    variables: { id },
  });
  const [saveReport, saveReportRes] = useMutation(SAVE_SIMPLE_REPORT, {
    onCompleted(res) {
      Alert("success", res.saveSimpleQcReport.qcReport.sharedWithCustomerAt ? "Shared with Customer." : "Disabled shared with customer.");
    },
    onError(error) {
      Alert("error", error.message);
    },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const canShareWithCustomer = isInternalUser(user);

  return (
    <div className="flex-1 flex flex-col">
      {canShareWithCustomer ? (
        <div className="px-6 py-6 md:px-10 flex items-center space-x-3">
          <label>Share with Customer({data.report.invoice.customer.name}): </label>
          <Switcher
            isOn={data.report.sharedWithCustomerAt !== null}
            disabled={saveReportRes.loading}
            loading={saveReportRes.loading}
            onChange={() =>
              saveReport({
                variables: {
                  id,
                  shareWithCustomer: data.report.sharedWithCustomerAt === null,
                  sendNotification: false,
                },
              })
            }
          />
        </div>
      ) : null}
      <iframe title="QC Report" className="flex-1 w-full bg-white rounded shadow-sm" srcDoc={data.report.html} />
    </div>
  );
};

export default PreviewQCReport;
